import i18n from "config/i18n";
import { DATE_FORMAT } from "constants/date";
import {
  ManualQRAccessFormTextInputRangeProps,
  ManualQRAccessFormTextInputTextProps,
} from "./models";
import { messageAtom } from "components/atoms/MessageAtom";

export const getInitialQrCode = (): ManualQRAccessFormTextInputTextProps => ({
  errorMessage: '',
  label: i18n.t('_ACCESS_MANUAL_QR_ACCESS_ITEM_LABEL_QR_CODE'),
  placeholder: i18n.t('_ACCESS_MANUAL_QR_ACCESS_ITEM_PLACEHOLDER_QR_CODE'),
  value: '',
  onChangeValue: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
});

export const getInitialRange = (): ManualQRAccessFormTextInputRangeProps => ({
  errorMessage: '',
  formatDate: DATE_FORMAT,
  label: i18n.t('_ACCESS_MANUAL_QR_ACCESS_ITEM_LABEL_RANGE'),
  placeholder: '',
  values: [],
  onChangeRange: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
});

export const INITIAL_QR_CODE = getInitialQrCode();
export const INITIAL_RANGE = getInitialRange();

const FORBIDDEN_CODE = 401;
const WITHOUT_QR_CODE = 406;

export const sendMessageError = (code: number) => {
  if (code === FORBIDDEN_CODE) {
    return messageAtom.error(i18n.t('_ACCESS_MANUAL_QR_ACCESS_REQUEST_FORBIDDEN_CODE_ERROR') as string, 5);
  }
  if (code === WITHOUT_QR_CODE) {
    return
  }
  messageAtom.error(i18n.t('_ACCESS_MANUAL_QR_ACCESS_REQUEST_ERROR') as string, 5);
};

const getQrCharacterCounterLiteral = (count: number): string => {
  return count === 1
    ? i18n.t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_QR_CHARACTER_COUNTER_SINGULAR', { count })
    : i18n.t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_QR_CHARACTER_COUNTER_PLURAL', { count });
};

export const getQrCharacterCounterText = (count: number): string => {
  return count ? getQrCharacterCounterLiteral(count) : '';
};
