import { FC } from 'react';
import SelectAtom, { SelectAtomProps } from 'components/atoms/SelectAtom';
import './styles.scss';

export interface SelectorSorterModel {
  value: string;
  label: string;
}

export interface TableSorterProps extends SelectAtomProps {
  valueOptions: SelectorSorterModel[];
  label: string;
}

export const TableSorter: FC<TableSorterProps> = ({
  value,
  valueOptions,
  label,
  onChange,
  disabled,
  loading,
}) => {
  return (
    <div>
      <span className={'TableSorter__label'}>{label}:</span>
      <SelectAtom
        className={'TableSorter__selector'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        loading={loading}
      >
        {valueOptions.map(({ value, label }) => (
          <SelectAtom.Option key={value} value={value}>{label}</SelectAtom.Option>
        ))}
      </SelectAtom>
    </div>

  );
}