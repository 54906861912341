import { ReactNode, FC } from 'react';
import Paper from 'components/atoms/Paper';
import SubTabsTitles from './Subtabs/SubtabsTitles';
import { ProjectServiceSubTabs } from './Subtabs/constants';

interface TabServicesProps {
  subTab: string;
  onSubTab: (subTab: ProjectServiceSubTabs) => void;
  children: { key: string; render: ReactNode }[];
}

const TabServices: FC<TabServicesProps> = ({ subTab, onSubTab, children }) => {
  const handleOnChange = (activeSubTab: ProjectServiceSubTabs): void => {
    onSubTab(activeSubTab);
  };

  return (
    <Paper className={'TabServices__container'}>
      <SubTabsTitles
        defaultActiveKey={subTab}
        onChange={(activeSubTab: string) => handleOnChange(activeSubTab as ProjectServiceSubTabs)}
      />
      {children.map((element) => {
        return subTab === element.key && element.render;
      })}
    </Paper>
  );
};

export default TabServices;
