import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef, TableColumnsType } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import i18next from 'i18next';
import { getAsset } from 'services/api/assets';
import { DeviceModel, DeviceSensorModel, DeviceSorterValueType } from 'core/domain/devices/models';
import { SorterOrderType } from 'constants/sort';
import { CustomDeviceModel } from 'utils/globalModels';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import {
  ExpandedPeripheralDevicesColumns,
  ExpandedPeripheralDevicesTranslations,
} from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import {
  ColumnsPeripheralDevices,
  ColumnsPeripheralDevicesOptionsModel,
} from 'components/organisms/PeripheralDevices/ColumnsPeripheralDevices';
import { DATE_TIME_FORMAT_REVERSE_SLASH } from 'constants/date';
import { Device, DeviceSensor } from 'models/devices.model';
import TagError from 'components/atoms/tags/TagError';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagDefault from 'components/atoms/tags/TagDefault';
import { useDeleteDeviceWithoutGateway } from './useDeleteDeviceWithoutGateway';
import { useCreateDeviceWithoutGateway } from './useCreateDeviceWithoutGateway';
import { useUpdateDeviceWithoutGateway } from './useUpdateDeviceWithoutGateway';
import { EnableDevice } from 'core/domain/device/repository/enabledDevice';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { getDevicesListSorterOptions } from 'utils/devices';
import { SelectorSorterModel } from 'components/molecules/TableSorter';
import { getAssetWithParamsPathById } from 'components/pages/App/routes/assets/config';
import { checkFilterOptionError, parseFiltersConditions, parseFiltersToBeSent } from 'utils/filter';
import { TableFilterModel } from 'components/organisms/TableFilter/models';
import { DeviceConnectivityType } from 'constants/devices';
import { connectivityLiteralsManager } from 'utils/devices';
import { getFilterOptions, getInitialFilters } from './utils';

const getInitialSorterValue = (): SelectorSorterModel => {
  return {
    value: `${DeviceSorterValueType.GATEWAY_ID}.${SorterOrderType.ASC}`,
    label: i18next.t('_DEVICES_LIST_SORTER_LABEL_GATEWAY_ID_ASC'),
  };
};

export const useTabDevices = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const { assetId, tab } = useParams<{ assetId: string; tab: string }>();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const sort = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SORT);
  const filters = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.FILTERS);
  const [loading, setLoading] = useState(true);
  const [gatewayId, setGatewayId] = useState<string>('');
  const [devices, setDevices] = useState<CustomDeviceModel[]>([]);
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentSize, setCurrentSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [currentSortValue, setCurrentSortValue] = useState<SelectorSorterModel>(getInitialSorterValue());
  const [currentFilterValue, setCurrentFilterValue] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [maintenanceDevice, setMaintenanceDevice] = useState<Device>();
  const [isMaintenanceActionsDrawerVisible, setIsMaintenanceActionsDrawerVisible] = useState<boolean>(false);
  const [initialFilters, setInitialFilters] = useState<TableFilterModel[]>(
    getInitialFilters({ currentFilters: '', connectivityLiteralsManager })
  );
  const { createDeviceOptions, successCreatingDevice } = useCreateDeviceWithoutGateway();
  const { updateDeviceOptions, successUpdatingDevice } = useUpdateDeviceWithoutGateway();
  const { deleteDeviceOptions, successDeletingDevice } = useDeleteDeviceWithoutGateway();

  const searchInput = useRef<InputRef>(null);

  const peripheralsTitle: string = t('peripheral');

  const sorterLabel: string = t('_SORTER_LABEL');

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const connectivityTagsManager = {
    [DeviceConnectivityType.OFFLINE]: TagError({ text: t('offline') }),
    [DeviceConnectivityType.ONLINE]: TagSuccess({ text: t('online') }),
    [DeviceConnectivityType.UNKNOWN]: TagDefault({ text: t('unknown') }),
  };

  const transformDevices = (devices: DeviceModel[]): CustomDeviceModel[] => {
    return devices.map((device: DeviceModel) => {
      const connectivity = !!device.connectivity ? device.connectivity : DeviceConnectivityType.UNKNOWN;
      const updatedAtLiteral = !!device.updatedAt
        ? moment.unix(device.updatedAt).format(DATE_TIME_FORMAT_REVERSE_SLASH)
        : t('not_reported');

      return { ...device, connectivity, updatedAtLiteral };
    });
  };

  const onOpenMaintenanceActionsDrawer = (selectedDevice: CustomDeviceModel) => {
    setIsMaintenanceActionsDrawerVisible(true);
    const newMaintenanceDevice = transformDeviceToMaintenanceDevice(selectedDevice);
    setMaintenanceDevice(newMaintenanceDevice);
  };

  const enableDevice = async ({ deviceId, enabled }: { deviceId: string; enabled: boolean }) => {
    setLoading(true);
    try {
      await EnableDevice({ host, token, deviceId, enabled });
      const description = enabled ? t('_ENABLED_DEVICE_SUCCESS_MESSAGE') : t('_DISABLED_DEVICE_SUCCESS_MESSAGE');
      setMessageSuccess({ description });
      getData({
        currentPageNumber: currentPage,
        currentPageSize: currentSize,
        currentSort: currentSortValue.value,
        currentFilter: currentFilterValue,
      });
    } catch (error) {
      const description = enabled ? t('_ENABLED_DEVICE_ERROR_MESSAGE') : t('_DISABLED_DEVICE_ERROR_MESSAGE');
      setMessageError({ description });
      setLoading(false);
    }
  };

  const onDisableDeviceData = (deviceId: string) => {
    enableDevice({ deviceId, enabled: false });
  };

  const onEnableDeviceData = (deviceId: string) => {
    enableDevice({ deviceId, enabled: true });
  };

  const columnsOptions: ColumnsPeripheralDevicesOptionsModel = {
    columnSearchTranslations,
    searchInput,
    connectivityTagsManager,
    onSearch,
    onReset,
    onOpenMaintenanceActionsDrawer,
    onOpenUpdateDeviceDrawer: (device: CustomDeviceModel) => updateDeviceOptions.onOpenUpdateDeviceDrawer(device),
    onOpenDeleteDeviceModal: (device: CustomDeviceModel) => deleteDeviceOptions.onOpenDeleteDeviceModal(device),
    onDisableDeviceData,
    onEnableDeviceData,
  };

  const columns = ColumnsPeripheralDevices({ options: columnsOptions });

  const expandedColumns: TableColumnsType<DeviceSensorModel> = ExpandedPeripheralDevicesColumns(peripheralExpandedColumnsTranslations);

  const parseSortValues = (currentSort: string) => {
    const [field, order] = currentSort.split('.') as [DeviceSorterValueType, SorterOrderType];
    return [{ field, order }];
  };

  const getData = async ({
    currentPageNumber,
    currentPageSize,
    currentSort,
    currentFilter,
  }: {
    currentPageNumber: number;
    currentPageSize: number;
    currentSort: string;
    currentFilter: string;
  }) => {
    setLoading(true);
    try {
      const sort = parseSortValues(currentSort);
      const filters = parseFiltersToBeSent(currentFilter);
      const { data } = await getAsset(assetId);
      !!data.gateways_qrs.length && setGatewayId(data.gateways_qrs[0].gateway_id);
      const devicesData = await GetDevicesLists({ host, token, assetId, page: currentPageNumber, size: currentPageSize, sort, filters });
      const transformedDevices = transformDevices(devicesData.data);
      setTotalPages(devicesData.meta.totalPages);
      setDevices(transformedDevices);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const transformSensorToMaintenanceSensor = ({ sensorId, sensorType, name, room, usage }: DeviceSensorModel): DeviceSensor => {
    return {
      item_name: '',
      item_type: '',
      sensor_id: sensorId,
      sensor_type: sensorType,
      name,
      room,
      usage: usage ?? undefined,
    };
  };

  const transformDeviceToMaintenanceDevice = ({
    availableActions,
    batteryLevel,
    deviceId,
    deviceName,
    deviceType,
    enabled,
    parameters,
    sensors,
  }: DeviceModel): Device => {
    const transformedSensors = sensors.map((sensor) => transformSensorToMaintenanceSensor(sensor)) ?? [];
    return {
      available_actions: availableActions,
      battery_level: batteryLevel,
      device_id: deviceId,
      device_name: deviceName,
      device_type: deviceType,
      enabled,
      parameters,
      password: '',
      thing_id: '',
      sensors: transformedSensors,
    };
  };

  const onCloseMaintenanceActionsDrawer = () => {
    setIsMaintenanceActionsDrawerVisible(false);
    setMaintenanceDevice(undefined);
  };

  const onChangePage = (page: number, pageSize?: number) => {
    history.push(
      getAssetWithParamsPathById({
        assetId,
        tab,
        page: String(page),
        size: String(pageSize!),
        sort: currentSortValue.value,
        filters: currentFilterValue,
      })
    );
  };

  const maintenanceActionsDrawerOptions = {
    gatewayId,
    maintenanceDevice,
    onCloseMaintenanceActionsDrawer,
    isMaintenanceActionsDrawerVisible,
  };

  const onChangeSorter = (value: string) => {
    history.push(
      getAssetWithParamsPathById({
        assetId,
        tab,
        page: String(currentPage),
        size: String(currentSize),
        sort: value,
        filters: currentFilterValue,
      })
    );
  };

  const sorterOptions = {
    label: sorterLabel,
    value: currentSortValue,
    valueOptions: getDevicesListSorterOptions(),
    onChangeSorter,
    disabled: loading,
    loading: loading,
  };

  const getSortValues = (sortValue: string | null): SelectorSorterModel | null => {
    return getDevicesListSorterOptions().find((sort) => sort.value === sortValue) || null;
  };

  const getFilterValues = (filterValue: string | null): string => {
    if (!filterValue) return '';

    const filterValueOptions = filterValue.split(',');
    const defaultFilterOptions = getFilterOptions();
    const filteredFilterValueOptions = filterValueOptions.filter((item, index) => filterValueOptions.indexOf(item) === index);

    const filters = filteredFilterValueOptions
      .filter((option) => {
        const isFilterOptionError = checkFilterOptionError(option, initialFilters);

        if (isFilterOptionError) return undefined;

        const [{ field }] = parseFiltersConditions([option]);

        const selectedDefaultFilterValue = defaultFilterOptions.find(({ key, operator, value, exactValue }) => {
          const parsedDefaultOption = `${key}${operator}${exactValue ? value : ''}`;
          const parsedOption = field === key && exactValue ? option : `${field}${operator}`;

          return parsedDefaultOption === parsedOption;
        });

        return !!selectedDefaultFilterValue ? option : undefined;
      })
      .join(',');

    return filters;
  };

  const navigateToDefaultValues = (newPage: string, newSize: string, newSort: SelectorSorterModel, newFilters: string) => {
    const defaultRoute = getAssetWithParamsPathById({
      assetId,
      tab,
      page: newPage,
      size: newSize,
      sort: newSort.value,
      filters: newFilters,
    });
    history.replace(defaultRoute);
  };

  const updateParamsValues = (newPage: string, newSize: string, newSort: SelectorSorterModel, newFilters: string) => {
    setCurrentPage(Number(newPage));
    setCurrentSize(Number(newSize));
    setCurrentSortValue(newSort);
    setCurrentFilterValue(newFilters);
  };

  const onApplyFilters = (url: string) => {
    history.push(
      getAssetWithParamsPathById({
        assetId,
        tab,
        page: String(currentPage),
        size: String(currentSize),
        sort: currentSortValue.value,
        filters: url,
      })
    );
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    const newSort = getSortValues(sort);
    const parsedSort = newSort || getInitialSorterValue();
    const newFilters = getFilterValues(filters);

    (!page || !size || !newSort) && navigateToDefaultValues(newPage, newSize, parsedSort, newFilters);

    if (!!page && !!size && !!newSort) {
      getData({ currentPageNumber: Number(page), currentPageSize: Number(size), currentSort: parsedSort.value, currentFilter: newFilters });
      updateParamsValues(newPage, newSize, parsedSort, newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, sort, filters]);

  useEffect(() => {
    (!!successCreatingDevice || !!successUpdatingDevice || !!successDeletingDevice) &&
      getData({
        currentPageNumber: Number(TABLE_PAGINATION_PAGE_DEFAULT),
        currentPageSize: Number(TABLE_PAGINATION_SIZE_DEFAULT),
        currentSort: getInitialSorterValue().value,
        currentFilter: '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreatingDevice, successUpdatingDevice, successDeletingDevice]);

  useEffect(() => {
    if (!!currentFilterValue) {
      const filters = getInitialFilters({ currentFilters: currentFilterValue, connectivityLiteralsManager });
      setInitialFilters(filters);
    }
  }, [currentFilterValue]);

  return {
    devices,
    totalPages,
    currentPage,
    currentSize,
    peripheralsTitle,
    searchedColumn,
    columns,
    expandedColumns,
    createDeviceOptions,
    updateDeviceOptions,
    deleteDeviceOptions,
    gatewayId,
    maintenanceActionsDrawerOptions,
    sorterOptions,
    initialFilters,
    onChangePage,
    onApplyFilters,
    loading,
  };
};
