import { FC } from 'react';
import { Card } from 'antd';
import DividerAtom from 'components/atoms/DividerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { TableFilterModel, TableFilterOptionModel } from '../../models';
import { TableFilterComponentManager } from '../ComponentsManager';
import './styles.scss';

interface Props {
  initialFilters: TableFilterModel[];
  filters: TableFilterOptionModel[];
  literals: Record<string, string>;
  onChangeFilter: (option: TableFilterOptionModel) => void
  onCloseFilters: () => void;
  onResetFilters: () => void;
  onApplyFilters: () => void;
  resetButtonDisabled: boolean;
  applyButtonDisabled: boolean;
}

export const DropdownContent: FC<Props> = ({
  initialFilters,
  filters,
  literals,
  onChangeFilter,
  onCloseFilters,
  onResetFilters,
  onApplyFilters,
  resetButtonDisabled,
  applyButtonDisabled,
}) => {
  return (
    <Card className={'DropdownContent__wrapper'}>
      <div className={'DropdownContent__wrapper-content'}>
        {initialFilters.map(({ key, label, type }) => (
          <div key={key} className={'DropdownContent__wrapper-body'}>
            <p className={'DropdownContent__subtitle'}>{label}</p>
            {filters
              .filter((filterOption) => filterOption.key.split(':')[0] === key)
              .map((option) => TableFilterComponentManager({ type, onChangeFilter, option }))}
          </div>
        ))}
      </div>
      <DividerAtom className={'DropdownContent__divider'} />
      <footer key={'footer'} className={'DropdownContent__footer'}>
        <DefaultButton onClick={onCloseFilters} title={literals.footerCloseButtonText} />
        <div>
          <TextButton onClick={onResetFilters} title={literals.footerSecondaryButtonText} disabled={resetButtonDisabled} />
          <PrimaryButton onClick={onApplyFilters} title={literals.footerMainButtonText} disabled={applyButtonDisabled} />
        </div>
      </footer>
    </Card>
  )
}
