export const TABLE_PAGINATION_PAGE_DEFAULT = '1';
export const TABLE_PAGINATION_SIZE_DEFAULT = '50';
export const TABLE_PAGE_SIZE_DEFAULT_OPTIONS = ['50', '75', '100'];

export enum TablePaginationSearchValue {
  PAGE = 'page',
  SIZE = 'size',
  SORT = 'sort',
  FILTERS = 'filter',
}
