import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { payloadToEventsAndNews } from '../mappers/payloadToEventsAndNews';
import { getErrorFormat } from 'core/utils/errors';
import { PayloadEventsAndNewsModel } from '../models/payloadEventsAndNews';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

const getUrl = ({ host, eventsAndNewsId }: { host: string; eventsAndNewsId: string }) => {
  return `${host}/events/${eventsAndNewsId}`;
};

export const GetEventsAndNewsById = async ({
  host,
  token,
  eventsAndNewsId,
}: {
  host: string;
  token: string;
  eventsAndNewsId: string;
}): Promise<EventsAndNewsModel> => {
  try {
    const url = getUrl({ host, eventsAndNewsId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<{ data: PayloadEventsAndNewsModel }>;
    return payloadToEventsAndNews(data.data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
