import { Menu, TableColumnsType } from 'antd';
import { DeviceSensorModel } from 'core/domain/devices/models';
import ContextualMenu from 'components/atoms/ContextualMenu';
import './styles.scss';

export interface PeripheralDevicesExpandedColumnsWithActionsTranslations {
  nameText: string;
  roomText: string;
  usageText: string;
  typeText: string;
  actionsTitle: string;
  shareSensorText: string;
  notReportedText: string;
}

export const PeripheralDevicesExpandedColumnsWithActions = ({
  translations,
  onOpenShareSensorModal
}: {
  translations: PeripheralDevicesExpandedColumnsWithActionsTranslations,
  onOpenShareSensorModal: (sensorUuid: string) => void,
}): TableColumnsType<DeviceSensorModel> => {

  const {
    nameText,
    roomText,
    usageText,
    typeText,
    actionsTitle,
    shareSensorText,
    notReportedText,
  } = translations;

  return [
    {
      title: 'Id',
      key: 'sensorId',
      dataIndex: 'sensorId',
    },
    {
      title: nameText,
      key: 'name',
      render: (text, { name }) => !!name ? <span>{name}</span> : <span className={'ExpandedWithActionsPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: roomText,
      key: 'room',
      render: (text, { room }) => !!room ? <span>{room}</span> : <span className={'ExpandedWithActionsPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: usageText,
      key: 'usage',
      render: (text, { usage }) => !!usage ? <span>{usage}</span> : <span className={'ExpandedWithActionsPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: typeText,
      key: 'sensorType',
      render: (text, { sensorType }) => !!sensorType ? <span>{sensorType}</span> : <span className={'ExpandedWithActionsPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: actionsTitle,
      key: 'actions',
      render: (text, { sensorUuid }) => {
        return (<ContextualMenu menu={
          <Menu>
            <Menu.Item
              key={'confirmAssignment'}
              onClick={() => onOpenShareSensorModal(sensorUuid)}
            >
              {shareSensorText}
            </Menu.Item>
          </Menu>
        } />)
      },
    },
  ];
}
