import { ColumnType } from 'antd/lib/table/interface';
import { CustomAssetData } from '../../../../../../hooks/useShareSensor';

export const ColumnsShareSensor = (addressTitle: string): ColumnType<CustomAssetData>[] => {
  return [
    {
      title: 'Id',
      key: 'assetId',
      width: '20%',
      dataIndex: 'assetId',
    },
    {
      title: addressTitle,
      key: 'address',
      width: '55%',
      dataIndex: 'address',
    },
    {
      title: `Alias`,
      key: 'alias',
      width: '25%',
      dataIndex: 'alias',
    },
  ];
};
