import i18next from 'i18next';
import {
  DeviceTypeWithoutGatewayDataConfigParameterModel,
  DeviceTypeWithoutGatewayDataExtraInformationModel,
  DeviceTypeWithoutGatewayDataModel,
  DeviceTypeWithoutGatewayDataSensorModel,
  DeviceWithoutGatewayExtraInformationModel,
  DeviceWithoutGatewayModel,
  DeviceWithoutGatewayParameterModel,
  DeviceWithoutGatewaySensorModel,
} from 'core/domain/devices/models';
import {
  DEVICE_WITHOUT_GATEWAY_FORM_CONFIG_PARAMETERS_KEY,
  DEVICE_WITHOUT_GATEWAY_FORM_EXTRA_INFORMATION_KEY,
  DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_KEY,
  DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_ROOM_KEY,
  DeviceWithoutGatewayFormDataModel,
  DeviceWithoutGatewayGeneralFormValuesNames,
  DeviceWithoutGatewayGeneralFormValuesSetModel,
  getInitialFormValues,
  ONLY_NUMERIC_VALUES_REGEX,
} from '../utils';

export const getFormValueRules = ({ required, valueType }: { required: boolean; valueType: string }) => {
  const rules = [];
  required && rules.push({ required, message: i18next.t('required_field') });
  valueType === 'number' &&
    rules.push({ pattern: ONLY_NUMERIC_VALUES_REGEX, message: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_RULE_NUMERIC_VALUE') });

  return rules;
};

const setBasicFormValue = (formValue: DeviceWithoutGatewayFormDataModel, currentValue?: string): DeviceWithoutGatewayFormDataModel => {
  const formGroupValue = !!currentValue ? formValue.formGroupValue - 1 : formValue.formGroupValue;

  return { ...formValue, value: currentValue || '', formGroupValue };
};

const setFormValuesManager = {
  [DeviceWithoutGatewayGeneralFormValuesNames.NAME]: ({ formValue, data }: DeviceWithoutGatewayGeneralFormValuesSetModel) =>
    setBasicFormValue(formValue, data?.name),
  [DeviceWithoutGatewayGeneralFormValuesNames.ROOM]: ({ formValue, data }: DeviceWithoutGatewayGeneralFormValuesSetModel) =>
    setBasicFormValue(formValue, data?.room),
  [DeviceWithoutGatewayGeneralFormValuesNames.SERIAL_NUMBER]: ({ formValue, data }: DeviceWithoutGatewayGeneralFormValuesSetModel) =>
    setBasicFormValue(formValue, data?.serialNumber),
};

const getBasicFormValues = ({
  formValues,
  data,
}: {
  formValues: DeviceWithoutGatewayFormDataModel[];
  data?: DeviceWithoutGatewayModel;
}) => {
  const basicFormValuesObjectValues = Object.values(DeviceWithoutGatewayGeneralFormValuesNames);
  return formValues
    .filter((formValue) => basicFormValuesObjectValues.includes(formValue.key as DeviceWithoutGatewayGeneralFormValuesNames))
    .map((formValue) => setFormValuesManager[formValue.key as DeviceWithoutGatewayGeneralFormValuesNames]({ formValue, data }));
};

const getConfigParameterFormValues = (
  formValues: DeviceWithoutGatewayFormDataModel[],
  configParameters: DeviceTypeWithoutGatewayDataConfigParameterModel[],
  data?: DeviceWithoutGatewayModel
): DeviceWithoutGatewayFormDataModel[] => {
  return formValues
    .filter((formValue) => formValue.name.includes(DEVICE_WITHOUT_GATEWAY_FORM_CONFIG_PARAMETERS_KEY))
    .map((formValue) => {
      const dataValues = data?.configParameters?.find((dataValue) => dataValue.name === formValue.key);
      const value = !!dataValues ? String(dataValues.data[0].value) : '';
      const units = !!dataValues ? String(dataValues.data[0].unit) : '';
      const formGroupValue = !!data ? formValue.formGroupValue - 1 : formValue.formGroupValue;

      const configParameterValues = configParameters.find((configParameter) => configParameter.name === formValue.key);
      const required = configParameterValues?.required || false;
      const multiple = configParameterValues?.multiple || false;
      const rules = !!configParameterValues
        ? getFormValueRules({ required: configParameterValues.required, valueType: configParameterValues.valueType })
        : [];

      return configParameterValues && { ...formValue, value, units, required, multiple, rules, formGroupValue };
    })
    .filter((formValue) => !!formValue);
};

const getExtraInformationFormValues = (
  formValues: DeviceWithoutGatewayFormDataModel[],
  extraInformation: DeviceTypeWithoutGatewayDataExtraInformationModel[],
  data?: DeviceWithoutGatewayModel
): DeviceWithoutGatewayFormDataModel[] => {
  return formValues
    .filter((formValue) => formValue.name.includes(DEVICE_WITHOUT_GATEWAY_FORM_EXTRA_INFORMATION_KEY))
    .map((formValue) => {
      const dataValues = data?.extraInformation?.find((dataValue) => dataValue.name === formValue.key);
      const value = !!dataValues ? String(dataValues.value) : '';
      const formGroupValue = !!data ? formValue.formGroupValue - 1 : formValue.formGroupValue;

      const extraInformationValues = extraInformation.find((info) => info.name === formValue.key);
      const required = extraInformationValues?.required || false;
      const rules = !!extraInformationValues
        ? getFormValueRules({ required: extraInformationValues.required, valueType: extraInformationValues.valueType })
        : [];

      return extraInformationValues && { ...formValue, value, required, rules, formGroupValue };
    })
    .filter((formValue) => !!formValue);
};

const getSensorsFormValues = (
  formValues: DeviceWithoutGatewayFormDataModel[],
  sensors: DeviceTypeWithoutGatewayDataSensorModel[],
  data?: DeviceWithoutGatewayModel
): DeviceWithoutGatewayFormDataModel[] => {
  return formValues
    .filter((formValue) => formValue.name.includes(DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_KEY))
    .map((formValue) => {
      const dataValues = data?.sensors?.find((dataValue) => formValue.key.includes(dataValue.label));
      const value = formValue.key.includes(DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_ROOM_KEY) ? dataValues?.room : dataValues?.name;
      const uuid = dataValues?.sensorUuid || '';
      const formGroupValue = !!data ? formValue.formGroupValue - 1 : formValue.formGroupValue;

      const sensor = sensors.find((sensor) => formValue.key.includes(sensor.label));
      const type = sensor?.sensorType || '';

      return sensor && { ...formValue, value: value || '', uuid, type, formGroupValue };
    })
    .filter((formValue) => !!formValue);
};

export const setDeviceWithoutGatewayFormValues = ({
  deviceType,
  data,
}: {
  deviceType: DeviceTypeWithoutGatewayDataModel;
  data?: DeviceWithoutGatewayModel;
}): DeviceWithoutGatewayFormDataModel[] => {
  const formValues = getInitialFormValues();

  const { configParameters, extraInformation, sensors } = deviceType;
  const basicFormValues = getBasicFormValues({ formValues, data });
  const configParametersFormValues = getConfigParameterFormValues(formValues, configParameters, data);
  const extraInformationFormValues = getExtraInformationFormValues(formValues, extraInformation, data);
  const sensorsFormValues = getSensorsFormValues(formValues, sensors, data);

  return [...basicFormValues, ...extraInformationFormValues, ...configParametersFormValues, ...sensorsFormValues];
};

const configParametersToPayload = ({
  values,
  formValues,
}: {
  values: Record<string, string | null>;
  formValues: DeviceWithoutGatewayFormDataModel[];
}): DeviceWithoutGatewayParameterModel[] => {
  return Object.keys(values)
    .filter((value) => value.includes(DEVICE_WITHOUT_GATEWAY_FORM_CONFIG_PARAMETERS_KEY))
    .map((key) => {
      const formValue = formValues.find((formValue) => formValue.name === key);

      return {
        name: formValue?.key || '',
        data: [{ value: values[key] || null, unit: formValue?.units || null }],
        multiple: formValue?.multiple || false,
        required: formValue?.required || false,
      };
    });
};

const extraInformationToPayload = ({
  values,
  formValues,
}: {
  values: Record<string, string | null>;
  formValues: DeviceWithoutGatewayFormDataModel[];
}): DeviceWithoutGatewayExtraInformationModel[] => {
  return Object.keys(values)
    .filter((value) => value.includes(DEVICE_WITHOUT_GATEWAY_FORM_EXTRA_INFORMATION_KEY))
    .map((key) => {
      const formValue = formValues.find((formValue) => formValue.name === key);

      return {
        name: formValue?.key || '',
        value: values[key] ?? null,
        required: formValue?.required || false,
      };
    });
};

const sensorsToPayload = ({
  values,
  formValues,
}: {
  values: Record<string, string | null>;
  formValues: DeviceWithoutGatewayFormDataModel[];
}): DeviceWithoutGatewaySensorModel[] => {
  return Object.keys(values)
    .filter((value) => value.includes(DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_KEY))
    .filter((value) => !value.includes(DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_ROOM_KEY))
    .map((key) => {
      const formValue = formValues.find((formValue) => formValue.name === key);
      const room = !!formValue && !!formValue.relatedFormKey ? values[formValue.relatedFormKey] : null;

      return {
        label: formValue?.key || '',
        sensorType: formValue?.type || '',
        sensorId: formValue?.key || '',
        sensorUuid: formValue?.uuid || '',
        name: values[key] || null,
        room: room || null,
      };
    });
};

export const formValuesToPayload = ({
  assetId,
  formType,
  values,
  formValues,
  data,
}: {
  assetId: string;
  formType: string | null;
  values: Record<string, string | null>;
  formValues: DeviceWithoutGatewayFormDataModel[];
  data?: DeviceWithoutGatewayModel;
}): DeviceWithoutGatewayModel => {
  const name = values[DeviceWithoutGatewayGeneralFormValuesNames.NAME];
  const room = values[DeviceWithoutGatewayGeneralFormValuesNames.ROOM];
  const serialNumber = values[DeviceWithoutGatewayGeneralFormValuesNames.SERIAL_NUMBER];

  const deviceUuid = !!data ? data.deviceUuid : '';

  const configParameters = configParametersToPayload({ values, formValues });
  const extraInformation = extraInformationToPayload({ values, formValues });
  const sensors = sensorsToPayload({ values, formValues });

  return {
    assetId,
    deviceType: formType || '',
    deviceUuid,
    name: name ?? '',
    room: room ?? '',
    serialNumber: serialNumber ?? '',
    configParameters,
    sensors,
    extraInformation,
  };
};
