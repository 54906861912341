import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEventsAndNewsModel, EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import { UpdateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { RemoveEventsAndNews } from 'core/domain/eventsAndNews/repositories/removeEventsAndNews';
import { CreateEventsAndNews } from 'core/domain/eventsAndNews/repositories/createEventsAndNews';
import { UpdateEventsAndNews } from 'core/domain/eventsAndNews/repositories/updateEventsAndNews';
import { GetEventsAndNewsById } from 'core/domain/eventsAndNews/repositories/getEventsAndNewsById';
import { GetEventsAndNewsList } from 'core/domain/eventsAndNews/repositories/getEventsAndNewsList';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { EventsAndNewsVisorViews } from '../enums/visorViews';
import { TRANSLATION } from '../enums/translations';
import { useMenuListItems } from './useMenuListItems';

type FallbackModel = (
  selectedProjectId: string,
  selectedEventsAndNewsId?: string
) => Promise<{
  eventsAndNewsList: EventsAndNewsModel[] | undefined;
  eventsAndNewsItem: EventsAndNewsModel | undefined;
}>;
type OnUpdateModel = (id: string, values: UpdateFormValues) => Promise<void>;
type OnCreateModel = (eventsAndNewsValues: CreateEventsAndNewsModel) => Promise<void>;
type OnRemoveModel = (eventsAndNewsId: string) => Promise<void>;
type OnSelectModel = (selectedId: string) => Promise<void>;
type OnVisibleUpdate = () => void;
type OnInvisibleUpdate = () => void;
type OnVisibleCreate = () => void;
type OnInvisibleCreate = () => void;

export const useEventsAndNews = (projectId: string) => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [eventsAndNewsList, setEventsAndNewsList] = useState<EventsAndNewsModel[]>([]);
  const [selectedEventsAndNews, setSelectedEventsAndNews] = useState<EventsAndNewsModel>({} as EventsAndNewsModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [visibleType, setVisibleType] = useState<EventsAndNewsVisorViews>(EventsAndNewsVisorViews.CONTENT);
  const { menuListItems, onSetMenuListItems, disabledItemsMenu, enabledItemsMenu, disabledItemMenu } = useMenuListItems();

  const fallback: FallbackModel = async (selectedProjectId: string, selectedEventsAndNewsId?: string) => {
    try {
      const eventsAndNewsList = await GetEventsAndNewsList({ host, token, projectId: selectedProjectId });

      const eventsAndNewsId: string | undefined =
        selectedEventsAndNewsId || (!!eventsAndNewsList?.length ? eventsAndNewsList[0].id : undefined);
      const eventsAndNewsItem: EventsAndNewsModel | undefined = eventsAndNewsList?.find((item) => eventsAndNewsId === item.id);

      if (eventsAndNewsId && !eventsAndNewsItem) {
        const refreshArea = await GetEventsAndNewsById({ host, token, eventsAndNewsId });
        return { eventsAndNewsList, eventsAndNewsItem: refreshArea };
      }

      return { eventsAndNewsList, eventsAndNewsItem };
    } catch (error) {
      setMessageError({ description: t('_EVENTS_AND_NEWS_LIST_ERROR_MESSAGE') });
      return { eventsAndNewsList: [], eventsAndNewsItem: undefined };
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fallback(projectId)
      .then(({ eventsAndNewsList, eventsAndNewsItem }) => {
        eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
        eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
        eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
        eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onUpdateItemEventsAndNews: OnUpdateModel = async (id, values) => {
    setIsSaving(true);
    try {
      await UpdateEventsAndNews({ host, token, values: { id, ...values } });
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId, id);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      setVisibleType(EventsAndNewsVisorViews.CONTENT);
      setMessageSuccess({ description: t(TRANSLATION.saved) });
    } finally {
      setIsSaving(false);
    }
  };

  const onCreateEventsAndNews: OnCreateModel = async (eventsAndNewsValues) => {
    setIsSaving(true);
    try {
      await CreateEventsAndNews({ host, token, projectId, values: eventsAndNewsValues });
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      setMessageSuccess({ description: t(TRANSLATION.created) });
    } catch (error) {
      setMessageError({ description: t('_EVENTS_AND_NEWS_CREATE_ERROR_MESSAGE') });
    } finally {
      setIsSaving(false);
    }
  };

  const onRemoveEventsAndNews: OnRemoveModel = async (id) => {
    setIsLoading(true);
    try {
      await RemoveEventsAndNews({ token, host, eventsAndNewsId: id });
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      setMessageSuccess({ description: t(TRANSLATION.removed) });
    } catch (error) {
      setMessageError({ description: t('_EVENTS_AND_NEWS_DELETE_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectItemEventsAndNews: OnSelectModel = async (selectedId) => {
    const eventsAndNewsValues = eventsAndNewsList.find((item) => selectedId === item.id);
    eventsAndNewsValues && setSelectedEventsAndNews(eventsAndNewsValues);
    eventsAndNewsValues ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.CONTENT);

    if (!eventsAndNewsValues) {
      setIsLoading(true);
      try {
        const eventsAndNews = await GetEventsAndNewsById({ host, token, eventsAndNewsId: selectedId });
        eventsAndNews && setSelectedEventsAndNews(eventsAndNews);
        eventsAndNews && setVisibleType(EventsAndNewsVisorViews.CONTENT);
      } catch (error) {
        setMessageError({ description: t('_EVENTS_AND_NEWS_DETAIL_ERROR_MESSAGE') });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onVisibleUpdate: OnVisibleUpdate = () => {
    !!selectedEventsAndNews.id && disabledItemMenu(selectedEventsAndNews.id);
    setVisibleType(EventsAndNewsVisorViews.UPDATE);
  };

  const onInvisibleUpdate: OnInvisibleUpdate = () => {
    enabledItemsMenu();
    !!selectedEventsAndNews.id ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.CONTENT);
  };

  const onVisibleCreate: OnVisibleCreate = () => {
    setSelectedEventsAndNews({} as EventsAndNewsModel);
    disabledItemsMenu();
    setVisibleType(EventsAndNewsVisorViews.CREATE);
  };

  const onInvisibleCreate: OnInvisibleCreate = () => {
    enabledItemsMenu();
    !!eventsAndNewsList.length && setSelectedEventsAndNews(eventsAndNewsList[0]);
    !!eventsAndNewsList.length ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
  };

  return {
    menuListItems,
    selectedEventsAndNews,
    visibleType,
    onCreateEventsAndNews,
    onInvisibleCreate,
    onInvisibleUpdate,
    onRemoveEventsAndNews,
    onSelectItemEventsAndNews,
    onUpdateItemEventsAndNews,
    onVisibleCreate,
    onVisibleUpdate,
    isLoading,
    isSaving,
  };
};
