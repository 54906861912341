import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { uploadEventsAndNewsToPayload } from '../mappers/uploadEventsAndNewsToPayload';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

const getUrl = ({ host, eventsAndNewsId }: { host: string; eventsAndNewsId: string }) => {
  return `${host}/events-news/${eventsAndNewsId}`;
};

export const UpdateEventsAndNews = async ({
  host,
  token,
  values,
}: {
  host: string;
  token: string;
  values: EventsAndNewsModel;
}): Promise<void> => {
  try {
    const url = getUrl({ host, eventsAndNewsId: values.id });

    const mappedData = uploadEventsAndNewsToPayload(values);

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
