import { FilterOperatorsType } from 'constants/filters';

export enum TableFilterType {
  CHECKBOX = 'checkbox',
  INPUT = 'input',
  INPUT_NUMBER = 'inputNumber',
  DATE = 'date',
  RANGE = 'range',
  DATE_RANGE = 'dateRange',
}

export enum TableFilterSubtypeType {
  MIN = 'minimum',
  MIN_OR_EQUAL = 'minimumOrEqual',
  MAX = 'maximum',
  MAX_OR_EQUAL = 'maximumOrEqual',
  EQUAL = 'equal',
}

export const numericFilterTypes = [TableFilterType.DATE, TableFilterType.DATE_RANGE, TableFilterType.INPUT_NUMBER, TableFilterType.RANGE];

export const rangeFilterTypes = [TableFilterType.DATE_RANGE, TableFilterType.RANGE];

export interface TableFilterOptionModel {
  key: string;
  type: TableFilterType;
  subtype: TableFilterSubtypeType;
  label: string;
  operator: FilterOperatorsType;
  defaultValue: string | null;
  value: string | null;
  selected: boolean;
  error: boolean;
  errorMessage: string;
  min?: number;
  max?: number;
}

export interface TableFilterModel {
  key: string;
  label: string;
  type: TableFilterType;
  options: TableFilterOptionModel[];
}

export interface TableFilterComponentModel {
  category: string;
  options: TableFilterOptionModel[];
}

export interface TableDefaultFilterOptionsModel {
  key: string;
  operator: FilterOperatorsType;
  value: string;
  exactValue: boolean;
}
