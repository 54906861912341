import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Form } from 'antd';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { UpdateGatewayDetail } from 'core/domain/gateways/repositories/updateGatewayDetail';
import { UpdateGatewayDataModel } from 'core/domain/gateways/model';

export interface ProvisionedFormValues {
  GID: string;
  QID: string;
}

export enum ProvisionedFormValuesNames {
  GID = 'GID',
  QID = 'QID',
}

enum ProvisionedErrorsType {
  CONFLICTS = 409,
  NOT_FOUND = 404,
}

const ONLY_NUMBERS_AND_CAPITAL_LETTERS_PATTERN = /^[A-Z0-9]+$/;

export const useProvisioned = () => {
  const { t } = useTranslation();
  const path = useLocation();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const { setMessageSuccess, setMessageError } = useMessage();
  const [form] = Form.useForm<ProvisionedFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const title = t('bind_GID_QID_Gateway');

  const formLiterals = {
    gidLabel: `${t('enter_the')} GID:`,
    gidPlaceholder: '00000000xx00xx00',
    qidLabel: `${t('enter_the')} QID:`,
    qidPlaceholder: 'Q000000000000',
    qidValidationMessage: t('_VALIDATE_QID_VALUE'),
    formButtonText: t('_PROVISIONES_GATEWAY_BUTTON_TEXT'),
    fieldRequiredText: t('field_required'),
  };

  const modalTitle = `${t('scan')} QID`;

  const controlledErrorLiteralsManager = {
    [ProvisionedErrorsType.CONFLICTS]: t('_PROVISIONED_GATEWAY_ERROR_QR_ALREADY_IN_USE_MESSAGE'),
    [ProvisionedErrorsType.NOT_FOUND]: t('_PROVISIONED_GATEWAY_ERROR_GID_NOT_FOUND_MESSAGE'),
  };

  const checkErrorsLiterals = (error: MessageErrorModel) => {
    const controlledErrors = Object.values(ProvisionedErrorsType);
    const isControlledError = controlledErrors.includes(error.code);
    const description = isControlledError
      ? controlledErrorLiteralsManager[error.code as ProvisionedErrorsType]
      : t('_PROVISIONED_GATEWAY_ERROR_MESSAGE');

    return setMessageError({ description });
  };

  const onFinish = async (values: ProvisionedFormValues) => {
    setIsLoading(true);
    try {
      const gateway: UpdateGatewayDataModel = { qrGatewayId: values.QID };
      await UpdateGatewayDetail({ host, token, gatewayId: values.GID, gateway });
      setMessageSuccess({ description: t('linked') });
      form.resetFields();
    } catch (error) {
      checkErrorsLiterals(error as MessageErrorModel);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickQr = () => {
    setIsModalVisible(true);
  };

  const trimID = (qr: string, indexQueryString: number) => {
    return qr.slice(indexQueryString + 1, qr.length);
  };

  const onScanQr = (qr: string) => {
    const indexQueryString = qr.indexOf('=');

    if (indexQueryString === -1) form.setFieldsValue({ QID: qr });
    if (indexQueryString !== -1) {
      const _qr = trimID(qr, indexQueryString);
      form.setFieldsValue({ QID: _qr });
    }

    setIsModalVisible(false);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(path.search);
    const gid = params.get('gid');
    if (gid) {
      form.setFieldsValue({ GID: gid });
      setIsModalVisible(true);
    }
  }, [t, form, path.search]);

  return {
    title,
    form,
    formLiterals,
    modalTitle,
    gidPatternValidator: ONLY_NUMBERS_AND_CAPITAL_LETTERS_PATTERN,
    onCloseModal,
    onClickQr,
    onScanQr,
    onFinish,
    isModalVisible,
    isLoading,
  };
};
