import { useEffect, useState, Key } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import { ShareSensorTranslationsModel } from 'components/organisms/ShareSensorModal';
import { GetAssetsListByProjectId } from 'core/domain/project/repositories/getAssetsListByProjectId';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { ColumnsShareSensor } from '../components/pages/ProjectSharedGatewayDetailPage/Tabs/TabDevices/ShareSensorColumns';
import { useMessage } from 'hooks/useMessage';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { GetGatewayDetail } from 'core/domain/gateways/repositories/getGatewayDetail';
import { GatewayParsed, GatewaySharedSensorModel } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { UpdateGatewayDataModel } from 'core/domain/gateways/model';
import { UpdateGatewayDetail } from 'core/domain/gateways/repositories/updateGatewayDetail';

export interface CustomAssetData {
  assetId: string;
  alias: string;
  address: string;
}

export interface ShareSensorOptionsModel {
  translations: ShareSensorTranslationsModel;
  columns: ColumnType<CustomAssetData>[];
  assets: CustomAssetData[];
  selectedRowKeys: Key[];
  onOpenShareSensorModal: ({ sensorUuid, gatewayId }: { sensorUuid: string; gatewayId: string }) => void;
  onCloseShareSensorModal: () => void;
  onChangeAssetShareSensor: (assets: Key[]) => void;
  onUpdateGatewayWithNewSensors: () => Promise<void>;
  shareSensorModalVisible: boolean;
  loading: boolean;
  updating: boolean;
}

export const useShareSensor = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [currentSensor, setCurrentSensor] = useState<string>('');
  const [currentGatewayId, setCurrentGatewayId] = useState<string>('');
  const [currentGateway, setCurrentGateway] = useState<GatewayParsed | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<CustomAssetData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const columns = ColumnsShareSensor(t('address'));

  const translations: ShareSensorTranslationsModel = {
    title: t('_SHARE_SENSOR_MODAL_TITLE'),
    mainButtonText: t('update'),
    secondaryButtonText: t('cancel'),
    selectedAssetsText: !!selectedRowKeys.length
      ? t('_SHARE_SENSOR_MODAL_SELECTED_ASSETS_TEXT', { assets: selectedRowKeys.length })
      : t('_SHARE_SENSOR_MODAL_SELECTED_ASSETS_EMPTY_TEXT'),
  };

  const resetValues = () => {
    setCurrentSensor('');
    setAssets([]);
    setSelectedRowKeys([]);
  };

  const onOpenShareSensorModal = ({ sensorUuid, gatewayId }: { sensorUuid: string; gatewayId: string }) => {
    setModalVisible(true);
    setCurrentSensor(sensorUuid);
    setCurrentGatewayId(gatewayId);
  };

  const onCloseShareSensorModal = () => {
    setModalVisible(false);
    resetValues();
  };

  const transformSharedSensors = () => {
    if (!!currentGateway && !!currentGateway.sharedSensors) {
      const isCurrentSensorAlreadyShared = !!currentGateway.sharedSensors.some((sensor) => sensor.sensorUuid === currentSensor);
      const newSharedSensor: GatewaySharedSensorModel = { sensorUuid: currentSensor, assets: selectedRowKeys as string[] };
      const transformedSharedSensors = currentGateway.sharedSensors.map((sensor) =>
        sensor.sensorUuid === currentSensor ? newSharedSensor : sensor
      );
      return isCurrentSensorAlreadyShared ? transformedSharedSensors : [...transformedSharedSensors, newSharedSensor];
    }
  };

  const transformGatewayToBeUpdated = (): UpdateGatewayDataModel => {
    const transformedSharedSensors = transformSharedSensors();
    return { projectId: currentGateway?.projectId, sharedSensors: transformedSharedSensors };
  };

  const onUpdateGatewayWithNewSensors = async () => {
    setUpdating(true);
    try {
      const transformedGatewayToBeUpdated = transformGatewayToBeUpdated();
      await UpdateGatewayDetail({ host, token, gatewayId: currentGatewayId, gateway: transformedGatewayToBeUpdated });
      setMessageSuccess({ description: t('_SHARE_SENSOR_MODAL_UPDATING_SUCCESS_MESSAGE') });
      onCloseShareSensorModal();
    } catch (error) {
      setMessageError({ description: t('_SHARE_SENSOR_MODAL_UPDATING_ERROR_MESSAGE') });
    } finally {
      setUpdating(false);
    }
  };

  const onChangeAssetShareSensor = (newAssets: Key[]) => {
    setSelectedRowKeys(newAssets);
  };

  const getAssetAddress = (asset: ProjectAssetModel): string => {
    const streetType = !!asset.streetType ? `${asset.streetType} ` : '';
    const streetName = !!asset.streetName ? `${asset.streetName} ` : '';
    const blockNumber = !!asset.blockNumber ? `${asset.blockNumber}, ` : '';
    const apartment = !!asset.apartment ? `${asset.apartment}, ` : '';
    const city = !!asset.city ? `${asset.city} ` : '';
    const state = !!asset.state ? `(${asset.state})` : '';
    const country = !!asset.country ? `, ${asset.country}` : '';
    return `${streetType}${streetName}${blockNumber}${apartment}${city}${state}${country}`;
  };

  const transformAssetsToCustomAssetsData = (assets: ProjectAssetModel[]): CustomAssetData[] => {
    return assets.map(({ id, alias, ...asset }) => {
      const address: string = getAssetAddress({ ...asset, id, alias });
      return { assetId: id, alias, address };
    });
  };

  const getData = async ({ currentProject, sensor }: { currentProject: string; sensor: string }) => {
    setLoading(true);
    try {
      const { data: assets } = await GetAssetsListByProjectId({ host, token, projectId: currentProject });
      const transformedAssets = transformAssetsToCustomAssetsData(assets);
      setAssets(transformedAssets);
      const gatewayDetail = await GetGatewayDetail(currentGatewayId);
      if (!!gatewayDetail && !!gatewayDetail.sharedSensors) {
        const sharedSensor = gatewayDetail.sharedSensors.find(({ sensorUuid }) => sensorUuid === sensor);
        !!sharedSensor && setSelectedRowKeys(sharedSensor.assets);
        setCurrentGateway(gatewayDetail);
      }
    } catch (error) {
      setMessageError({ description: t('_SHARE_SENSOR_MODAL_GETTING_ASSETS_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !!projectId && !!currentSensor && !!currentGatewayId && getData({ currentProject: projectId, sensor: currentSensor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentSensor, currentGatewayId]);

  const shareSensorOptions: ShareSensorOptionsModel = {
    translations,
    columns,
    assets,
    selectedRowKeys,
    onOpenShareSensorModal,
    onCloseShareSensorModal,
    onChangeAssetShareSensor,
    onUpdateGatewayWithNewSensors,
    shareSensorModalVisible: modalVisible,
    loading,
    updating,
  };

  return { shareSensorOptions };
};
