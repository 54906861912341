import { SensorEntityModel } from '../models/sensorEntityModels';
import { SensorPayloadModel } from '../models/sensorPayloadModels';

export const payloadToSensorsAssets = ({
  id,
  uid,
  name,
  room,
  usage,
  sensor_type,
  are_values_accumulated,
  can_data_be_fetched,
  device_id,
  gateway_id,
  sensor_id,
}: SensorPayloadModel): SensorEntityModel => {
  return {
    id,
    uid,
    name: name ?? '',
    room: room ?? '',
    usage: usage ?? '',
    sensorType: sensor_type ?? '',
    areValuesAccumulated: are_values_accumulated,
    canDataBeFetched: can_data_be_fetched,
    deviceId: device_id ?? '',
    gatewayId: gateway_id ?? null,
    sensorId: sensor_id ?? '',
  };
};
