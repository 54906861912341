import { SorterOrderType } from 'constants/sort';
import { FilterModel } from './globalModels';

export const getUrlSearchParams = (params: Record<string, any>) => new URLSearchParams(params);

const parseConditions = (filters: FilterModel): string => {
  return filters.conditions
    .map((condition) =>
      'logic' in condition ? `(${parseConditions(condition)})` : `${condition.field}${condition.operator}${condition.value}`
    )
    .join(filters.logic);
};

export const buildUrlFilters = (filters: FilterModel): string => {
  return parseConditions(filters);
};

export const buildUrlSort = (sort: { field: string; order?: SorterOrderType }[]): string => {
  return sort.map(({ field, order = SorterOrderType.ASC }) => (order === SorterOrderType.DESC ? `-${field}` : field)).join(',');
};
