import { FC } from 'react';
import FormAtom from 'components/atoms/FormAtom';
import InputForm from 'components/atoms/InputForm';
import { SendNotificationModel } from 'core/domain/notifications/model';

interface SendNotificationFormProps {
  formId: string;
  literals: Record<string, string>;
  onSave: (values: SendNotificationModel) => Promise<void>
}

export const SendNotificationForm: FC<SendNotificationFormProps> = ({
  formId,
  literals,
  onSave
}) => {
  const {
    formTitleLabel,
    formTitlePlaceholder,
    formMessageLabel,
    formMessagePlaceholder,
    formFieldRequiredMessage,
  } = literals;

  return (
    <FormAtom
      id={formId}
      onFinish={onSave}
      layout={'vertical'}
    >
      <FormAtom.Item
        name={'title'}
        label={`${formTitleLabel}:`}
        rules={[{ required: true, message: formFieldRequiredMessage }]}
      >
        <InputForm
          name={'title'}
          placeholder={formTitlePlaceholder}
        />
      </FormAtom.Item>
      <FormAtom.Item
        name={'content'}
        label={`${formMessageLabel}:`}
        rules={[{ required: true, message: formFieldRequiredMessage }]}
        hasFeedback
      >
        <InputForm.TextArea
          value={'content'}
          placeholder={formMessagePlaceholder}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </FormAtom.Item>
    </FormAtom>
  );
};