import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, message } from 'antd';
import { useProjectCRUD } from './useProjectCRUD';
import { ProjectModel, ProjectType, UpdateProjectModel } from 'core/domain/project/model/projectModel';
import {
  ProjectContextualMenuTranslations,
  ProjectEditFormData,
  ProjectEditFormTranslations,
  ProjectEditFormValues,
  ProjectEditModalTranslations,
  ProjectHeaderDescription,
} from 'components/pages/ProjectPage/resources/models';
import { getClientPathById } from 'components/pages/App/routes/settings/config';

export const useProject = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { data, loading, updateProject, updating, getProjectById } = useProjectCRUD();
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [projectType, setProjectType] = useState<ProjectType>(ProjectType.HOME);
  const [form] = Form.useForm<ProjectEditFormValues>();

  const contextualMenuTranslations: ProjectContextualMenuTranslations = {
    editButton: t('edit'),
    notifyButton: t('notify_the_community'),
  };

  const editModalTranslations: ProjectEditModalTranslations = {
    title: t('_EDIT_PROJECT_MODAL_TITLE'),
    cancelButton: t('to_cancel'),
    editButton: t('edit'),
  };

  const localProjectTypeManager: { [type: string]: string } = {
    [ProjectType.HOME]: t('_PROJECT_TYPE_HOME'),
    [ProjectType.RENT]: t('_PROJECT_TYPE_RENT'),
    [ProjectType.OFFICE]: t('_PROJECT_TYPE_OFFICE'),
    [ProjectType.PARKING_SLOT]: t('_PROJECT_TYPE_PARKING_SLOT'),
    [ProjectType.HOTEL]: t('_PROJECT_TYPE_HOTEL'),
    [ProjectType.VACATIONAL_RENTAL]: t('_PROJECT_TYPE_VACATIONAL_RENTAL'),
    [ProjectType.BUILDING]: t('_PROJECT_TYPE_VACATIONAL_BUILDING'),
  };

  const getLocalProjectType = (type: ProjectType) => localProjectTypeManager[type];

  const editFormTranslations: ProjectEditFormTranslations = {
    name: t('_EDIT_PROJECT_FORM_NAME'),
    namePlaceholder: t('_EDIT_PROJECT_FORM_NAME_PLACEHOLDER'),
    nameError: t('_EDIT_PROJECT_FORM_NAME_ERROR'),
    supportEmails: t('_EDIT_PROJECT_FORM_SUPPORT_EMAILS'),
    supportEmailsPlaceholder: t('_EDIT_PROJECT_FORM_SUPPORT_EMAILS_PLACEHOLDER'),
    emailErrorFormat: t('_EDIT_PROJECT_FORM_EMAIL_ERROR_FORMAT'),
    emailFormat: t('_EDIT_PROJECT_FORM_EMAIL_FORMAT'),
    usersVerifiers: t('_EDIT_PROJECT_FORM_USERS_VERIFIERS'),
    usersVerifiersPlaceholder: t('_EDIT_PROJECT_FORM_USERS_VERIFIERS_PLACEHOLDER'),
    usersRequireVerification: t('_EDIT_PROJECT_FORM_USERS_REQUIRE_VERIFICATION'),
    fieldRequired: t('field_required'),
    submitError: t('_EDIT_PROJECT_FORM_SUBMIT_ERROR'),
    submitSuccess: t('_EDIT_PROJECT_FORM_SUBMIT_SUCCESS'),
    type: t('_EDIT_PROJECT_FORM_TYPE'),
  };

  const headerDescriptionTranslations: ProjectHeaderDescription = {
    name: t('name'),
    supportEmail: t('support_email'),
    verifiers: t('registration_validator_user'),
    client: t('company'),
    clientId: null,
    requireVerification: t('_ENABLED_REQUIRE_VERIFICATION'),
    requireVerificationInfo: t('registration_validator_user_info'),
    type: t('_PROJECT_TYPE'),
  };

  const getInitialEditFormValues = (project: ProjectModel) => {
    const formattedSupportEmails = project.supportEmail?.join(' ').trim();
    const formattedUsersVerifiers = project.usersVerifiers?.map(({ value }) => value);
    setProjectType(project.type as unknown as ProjectType);

    form.setFieldsValue({
      name: project.name,
      usersRequireVerification: project.usersRequireVerification,
      usersVerifiers: formattedUsersVerifiers,
      supportEmails: formattedSupportEmails,
      type: getLocalProjectType(data.type as unknown as ProjectType),
    });
  };

  const onOpenEditModal = () => setIsEditModalVisible(true);

  const onCloseEditModal = () => {
    setIsEditModalVisible(false);
    data && getInitialEditFormValues(data);
  };

  const validateEmailFormat = async (value: string): Promise<void> => {
    if (value === '') {
      return;
    }
    const emails = value.split(' ');
    emails.forEach((email: string) => {
      const emailValidation = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'gm'
      );
      const isValidFormat = emailValidation.test(email);
      if (!isValidFormat) {
        throw new Error(editFormTranslations.emailErrorFormat);
      }
    });
  };

  const formatUsersVerifiersData = (users: string[]): string[] => {
    return users
      .flatMap((user) => data.allowedUsersToVerifyAccounts.filter((allowedUser) => allowedUser.value === user) || [])
      .map((user) => user.id);
  };

  const getFormattedEditFormData = (values: ProjectEditFormValues): UpdateProjectModel => {
    const { name, usersRequireVerification, usersVerifiers, supportEmails, type } = values;
    const formattedSupportEmails = !!supportEmails.length ? supportEmails.split(' ') : [];
    const formattedUsersVerifiers = formatUsersVerifiersData(usersVerifiers);
    return {
      ...data,
      name,
      usersVerifiers: formattedUsersVerifiers,
      usersRequireVerification,
      supportEmail: formattedSupportEmails,
      type,
    };
  };

  const onSubmitEditForm = async (values: ProjectEditFormValues) => {
    const formattedFormData = getFormattedEditFormData({ ...values, type: projectType });
    try {
      await updateProject(formattedFormData);
      getProjectById(projectId);
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const onSubmitEditFormFailed = () => {
    message.error(editFormTranslations.submitError, 3);
  };

  useEffect(() => {
    data && getInitialEditFormValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const typeOptions = [
    { id: ProjectType.HOME, value: getLocalProjectType(ProjectType.HOME) },
    { id: ProjectType.RENT, value: getLocalProjectType(ProjectType.RENT) },
    { id: ProjectType.OFFICE, value: getLocalProjectType(ProjectType.OFFICE) },
    { id: ProjectType.PARKING_SLOT, value: getLocalProjectType(ProjectType.PARKING_SLOT) },
    { id: ProjectType.HOTEL, value: getLocalProjectType(ProjectType.HOTEL) },
    { id: ProjectType.VACATIONAL_RENTAL, value: getLocalProjectType(ProjectType.VACATIONAL_RENTAL) },
    { id: ProjectType.BUILDING, value: getLocalProjectType(ProjectType.BUILDING) },
  ];

  const onSelectType = (value: ProjectType) => {
    setProjectType(value);
  };

  const onClickClientLink = (clientId: string | null) => {
    if (!!clientId) {
      const route = getClientPathById({ clientId });
      history.push(route);
    }
  };

  const formData: ProjectEditFormData = {
    form,
    editFormTranslations,
    typeOptions,
    onSubmitEditForm,
    onSubmitEditFormFailed,
    onSelectType,
    validateEmailFormat,
    editing: updating,
  };

  const projectHeaderDescription: ProjectHeaderDescription = {
    name: data.name || t('not_reported'),
    supportEmail: data.supportEmail?.join(', ') || t('_EDIT_PROJECT_SUPPORT_USERS_EMPTY'),
    verifiers: data.usersVerifiers?.map((user) => user.value).join(', ') || t('_EDIT_PROJECT_USERS_VERIFIERS_EMPTY'),
    client: data.client?.name || t('not_reported'),
    clientId: data.client?.id ?? null,
    requireVerification: data.usersRequireVerification ? t('_ENABLED') : t('_DISABLED'),
    requireVerificationInfo: t('registration_validator_user_info'),
    type: getLocalProjectType(data.type as unknown as ProjectType),
  };

  return {
    data,
    loadingData: loading,
    contextualMenuTranslations,
    modal: {
      editModalTranslations,
      onOpenEditModal,
      onCloseEditModal,
      editModalVisible: isEditModalVisible,
    },
    formData,
    header: {
      projectHeaderDescription,
      headerDescriptionTranslations,
    },
    onClickClientLink,
  };
};
