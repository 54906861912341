import { FC } from 'react';
import { Form, Input, Button, Image, PageHeader } from 'antd';
import { SendOutlined, QrcodeOutlined } from '@ant-design/icons';
import { IMAGES } from 'constants/images';
import Paper from 'components/atoms/Paper';
import ModalQRScanner from './ModalQRScanner';
import { ProvisionedFormValuesNames, useProvisioned } from './resources/useProvisioned';
import './styles.scss';

const ProvisionedPage: FC = () => {
  const {
    title,
    form,
    formLiterals,
    modalTitle,
    gidPatternValidator,
    onCloseModal,
    onClickQr,
    onScanQr,
    onFinish,
    isModalVisible,
    isLoading,
  } = useProvisioned();

  return (
    <>
      <PageHeader ghost={false} title={title} />
      <div className={'ProvisionedPage__wrapper'}>
        <Paper>
          <div className='ProvisionedPage__container'>
            <Form form={form} onFinish={onFinish} className={'ProvisionedPage__form'} layout={'vertical'}>
              <Form.Item
                name={ProvisionedFormValuesNames.GID}
                label={formLiterals.gidLabel}
                rules={[{ required: true, message: formLiterals.fieldRequiredText }]}>
                <Input
                  allowClear
                  placeholder={formLiterals.gidPlaceholder}
                />
              </Form.Item>
              <Form.Item
                name={ProvisionedFormValuesNames.QID}
                label={formLiterals.qidLabel}
                tooltip={<Image src={IMAGES.qid} preview={false} />}
                rules={[
                  { required: true, message: formLiterals.fieldRequiredText },
                  { pattern: gidPatternValidator, message: formLiterals.qidValidationMessage }
                ]}
              >
                <Input
                  allowClear
                  placeholder={formLiterals.qidPlaceholder}
                  addonAfter={<QrcodeOutlined onClick={onClickQr} />}
                />
              </Form.Item>
              <Form.Item>
                <Button icon={<SendOutlined />} key={'link'} htmlType={'submit'} type={'primary'} loading={isLoading}>
                  {formLiterals.formButtonText}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Paper>
      </div>
      <ModalQRScanner
        title={modalTitle}
        onCancel={onCloseModal}
        onScan={(qr) => onScanQr(qr)}
        open={isModalVisible}
      />
    </>
  );
};

export default ProvisionedPage;
