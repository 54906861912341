import { FC } from 'react';
import { Checkbox, Input } from 'antd';
import { TableFilterOptionModel } from '../../models';
import './styles.scss';

interface Props {
  option: TableFilterOptionModel;
  onChangeFilter: (option: TableFilterOptionModel) => void;
}

const DEFAULT_MAX_LENGTH = 20;
const MAX_LABEL_LENGTH = 12;

export const InputComponent: FC<Props> = ({ option, onChangeFilter }) => {
  const isFullWidth = option.label.length >= MAX_LABEL_LENGTH;

  return (
    <div className={'TableFilterInput__wrapper'} key={option.key}>
      <div className={'TableFilterInput__wrapper-content'}>
        <div className={`TableFilterInput__wrapper-content-selector${isFullWidth ? '-full' : ''}`}>
          <div className={'TableFilterInput__selector'}>
            <Checkbox
              key={option.key}
              checked={option.selected}
              onChange={({ target }) => onChangeFilter({ ...option, selected: target.checked })}
            >
              <p className={`TableFilterInput__text${!option.selected ? '-disabled' : ''}`}>
                {option.label}
              </p>
            </Checkbox>
          </div>
          <div className={`TableFilterInput__input-wrapper${isFullWidth ? '-full' : ''}`}>
            <Input
              size={'small'}
              value={option.value || ''}
              onChange={(value) => onChangeFilter({ ...option, value: value.target.value })}
              disabled={!option.selected}
              maxLength={DEFAULT_MAX_LENGTH}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
