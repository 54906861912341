import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { payloadToEventsAndNews } from '../mappers/payloadToEventsAndNews';
import { PayloadEventsAndNewsModel } from '../models/payloadEventsAndNews';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

const getUrl = ({ host, projectId }: { host: string; projectId: string }) => {
  return `${host}/projects/${projectId}/events-news`;
};

export const GetEventsAndNewsList = async ({
  host,
  token,
  projectId,
}: {
  host: string;
  token: string;
  projectId: string;
}): Promise<EventsAndNewsModel[]> => {
  try {
    const url = getUrl({ host, projectId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<{ data: PayloadEventsAndNewsModel[] }>;
    return data.data.map((item) => payloadToEventsAndNews(item));
  } catch (error) {
    throw getErrorFormat(error);
  }
};
