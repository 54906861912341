import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDeviceModel } from 'constants/models';
import { DeleteDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/deleteDeviceWithoutGateway';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';

export interface DeleteDeviceTranslationsType {
  title: string;
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
}

export interface DeleteDeviceOptionsModel {
  deleteDeviceModalTranslations: DeleteDeviceTranslationsType;
  onOpenDeleteDeviceModal: (device: CustomDeviceModel) => void;
  onCloseDeleteDeviceModal: () => void;
  onDeleteDevice: () => void;
  deleteDeviceModalVisible: boolean;
  deleting: boolean;
}

export const useDeleteDeviceWithoutGateway = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [device, setDevice] = useState<CustomDeviceModel | null>(null);
  const [deleteDeviceModalVisible, setDeleteDeviceModalVisible] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [successDeletingDevice, setSuccessDeletingDevice] = useState<boolean>(false);

  const deleteDeviceModalTranslations: DeleteDeviceTranslationsType = {
    title: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
    text: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT_CONFIRMATION', { name: device?.deviceName }),
    mainButtonText: t('delete'),
    secondaryButtonText: t('cancel'),
  };

  const onOpenDeleteDeviceModal = (selectedDevice: CustomDeviceModel) => {
    setDeleteDeviceModalVisible(true);
    setDevice(selectedDevice);
  };

  const onCloseDeleteDeviceModal = () => {
    setDeleteDeviceModalVisible(false);
    setDevice(null);
  };

  const checkErrors = (error: MessageErrorModel) => {
    const description =
      error.code === 404 ? t('_DELETE_DEVICE_WITHOUT_GATEWAY_ERROR_404_MESSAGE') : t('_DELETE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');

    setMessageError({ description });
  };

  const onDeleteDevice = async () => {
    try {
      setDeleting(true);
      await DeleteDeviceDetailWithoutGateway({ host, token, deviceId: device?.deviceId || '' });
      setMessageSuccess({ description: t('_DELETE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      setSuccessDeletingDevice(true);
      onCloseDeleteDeviceModal();
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setDeleting(false);
      setSuccessDeletingDevice(false);
    }
  };

  const deleteDeviceOptions: DeleteDeviceOptionsModel = {
    deleteDeviceModalTranslations,
    onDeleteDevice,
    onCloseDeleteDeviceModal,
    onOpenDeleteDeviceModal,
    deleteDeviceModalVisible,
    deleting,
  };

  return {
    deleteDeviceOptions,
    successDeletingDevice,
  };
};
