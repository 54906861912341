import { Key, MouseEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProjectTabInfoWithPagination } from 'core/domain/project/repositories/project';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import { getProjectPath } from 'components/pages/App/routes/projects/config';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { messageAtom } from 'components/atoms/MessageAtom';
import { RowSelectMethod } from 'antd/lib/table/interface';
import { getUserSearch } from 'services/api/users';
import { User } from 'models/users.model';
import { LinkSearchSelectV2Items } from 'components/organisms/LinkSearchSelectV2';
import { GrantUserAccessToManyAssets } from 'core/domain/users/repositories/grantUserAccessToManyAssets';
import {
  GrantUserAccessToManyAssetsResponseKey,
  GrantUserAccessToManyAssetsResponseModel,
} from 'core/domain/users/models/grantUserAccessModel';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';

const usersListToOptionsList = (usersList: User[]): LinkSearchSelectV2Items[] => {
  return usersList.map(({ username, email, id }) => ({
    label: `${username} (${email})`,
    value: id,
  }));
};

export interface AddedAssetsModel {
  [GrantUserAccessToManyAssetsResponseKey.ADDED]: ProjectAssetModel[];
  [GrantUserAccessToManyAssetsResponseKey.EXISTING]: ProjectAssetModel[];
  [GrantUserAccessToManyAssetsResponseKey.FAILED]: ProjectAssetModel[];
}

const grantUserAccessToManyAssetsModelToAddedAssetsModel = (
  list: GrantUserAccessToManyAssetsResponseModel,
  assetsList: ProjectAssetModel[]
): AddedAssetsModel => {
  const addedAssetsList = assetsList.filter((asset) => {
    return list[GrantUserAccessToManyAssetsResponseKey.ADDED].some((item) => item === asset.id);
  });
  const existingAssetsList = assetsList.filter((asset) => {
    return list[GrantUserAccessToManyAssetsResponseKey.EXISTING].some((item) => item === asset.id);
  });
  const failedAssetsList = assetsList.filter((asset) => {
    return list[GrantUserAccessToManyAssetsResponseKey.FAILED].some((item) => item.assetId === asset.id);
  });
  return {
    [GrantUserAccessToManyAssetsResponseKey.ADDED]: addedAssetsList,
    [GrantUserAccessToManyAssetsResponseKey.EXISTING]: existingAssetsList,
    [GrantUserAccessToManyAssetsResponseKey.FAILED]: failedAssetsList,
  };
};

export const useProjectAssets = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const history = useHistory();
  const [allResults, setAllResults] = useState<number>(0);
  const [assets, setAssets] = useState<ProjectAssetModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [checkedAssetIdList, setCheckedAssetIdList] = useState<string[]>([]);
  const [checkedAssetList, setCheckedAssetList] = useState<ProjectAssetModel[]>([]);
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [completeAssetsList, setCompleteAssetsList] = useState<ProjectAssetModel[]>([]);
  const [isVisibleAddUserModal, setIsVisibleAddUserModal] = useState<boolean>(false);
  const [isDisabledAddButton, setIsDisabledAddButton] = useState<boolean>(true);
  const [isDisabledCancelButton, setIsDisabledCancelButton] = useState<boolean>(false);
  const [isHiddenAddButton, setIsHiddenAddButton] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<LinkSearchSelectV2Items>();
  const [isLoadingWhileAddUsers, setIsLoadingWhileAddUsers] = useState<boolean>(false);
  const [failedAssetsData, setFailedAssetsData] = useState<ProjectAssetModel[]>([]);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState<boolean>(false);

  const contextualMenuAddUserText = t('_PROJECT_ASSETS_ADD_USER_MENU_ITEM');
  const selectedAssetsText = t('_PROJECT_ASSETS_ADD_USER_SELECTED_ASSETS', {
    selectedAssets: checkedAssetIdList.length,
  });
  const allResultsText =
    checkedAssetIdList.length === allResults
      ? t('_PROJECT_ASSETS_ADD_USER_UNSELECT_ALL_RESULTS')
      : t('_PROJECT_ASSETS_ADD_USER_ALL_RESULTS', { allResults: allResults });
  const addUserTitleModalText = !!failedAssetsData.length
    ? t('_PROJECT_ASSETS_ADD_USER_MODAL_ERROR_TITLE_TEXT')
    : t('_PROJECT_ASSETS_ADD_USER_MODAL_TITLE_TEXT');
  const cancelButtonModalText = t('_PROJECT_ASSETS_ADD_USER_MODAL_CANCEL');
  const addButtonModalText = t('_PROJECT_ASSETS_ADD_USER_MODAL_ADD');
  const searchUserModalPlaceholder = t('_PROJECT_ASSETS_ADD_USER_MODAL_PLACEHOLDER_SEARCH_USER');
  const modalBoxText = t('_PROJECT_ASSETS_ADD_USER_MODAL_LABEL_BOX', { assets: checkedAssetIdList.length });
  const errorModalBoxText = t('_PROJECT_ASSETS_ADD_USER_MODAL_LABEL_RESPONSE_ERROR_BOX', {
    user: selectedUser?.label || '{...}',
  });
  const assetOccupationTranslations = {
    assetOccupied: t('_ASSET_OCCUPIED'),
    assetNotOccupied: t('_ASSET_NOT_OCCUPIED'),
  };
  const assetGatewayConnectionStatusTranslations = {
    connected: t('_ASSET_GATEWAY_CONNECTED'),
    disconnected: t('_ASSET_GATEWAY_DISCONNECTED'),
    noLinkedGateways: t('_ASSET_GATEWAY_NO_LINKED'),
    defaultText: t('not_reported'),
  };

  const getProjectAssetsWithPagination = async ({ page, size, search }: { page: number; size: number; search?: string }) => {
    setIsLoading(true);
    try {
      const response = await getProjectTabInfoWithPagination({ projectId, page, size, search });
      if (response) {
        setAssets(response.data);
        setTotalPages(response.meta.totalPages);
        setAllResults(response.meta.allResults);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectAsset = (asset: ProjectAssetModel) => {
    history.push(getAssetPathById(asset.id));
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getProjectPath({ projectId, page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onOpenLink = (assetId: string, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const url = `${window.location.origin}/admin/assets/${assetId}/start`;
    window.open(url, '_blank');
  };

  const onSelectChange = (
    selectedRowKeys: Key[],
    selectedRows: ProjectAssetModel[],
    info: {
      type: RowSelectMethod;
    }
  ) => {
    const { type } = info;
    if (type === 'all' || type === 'multiple') {
      if (!selectedRowKeys.length) {
        const selectedList = checkedAssetIdList.filter((item) => !assets.some((asset) => item === asset.id));
        setCheckedAssetIdList(selectedList);
      }
      if (selectedRowKeys.length) {
        const list = [...checkedAssetIdList, ...(selectedRowKeys as string[])];
        const data = new Set(list) as unknown as string[];
        setCheckedAssetIdList([...data]);
      }
    }
  };

  const onSelect = (record: ProjectAssetModel, selected: boolean) => {
    if (selected) {
      const list = [...checkedAssetIdList, record.id];
      setCheckedAssetIdList(list);
    }

    if (!selected) {
      const list = checkedAssetIdList.filter((item) => record.id !== item);
      setCheckedAssetIdList(list);
    }
  };

  const rowSelection: TableProps<ProjectAssetModel>['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys: checkedAssetIdList as Key[],
    onSelect: onSelect,
    onChange: onSelectChange,
  };

  const onSelectAll = () => {
    checkedAssetIdList.length === allResults ? setCheckedAssetIdList([]) : setCheckedAssetIdList(completeAssetsList.map((item) => item.id));
  };

  const onAddMassiveUsers = () => {
    setIsHiddenAddButton(false);
    setIsDisabledAddButton(true);
    setIsVisibleAddUserModal(true);
    setIsDisabledCancelButton(false);
  };

  const onAddUser = () => {
    const assetIdList = checkedAssetIdList.map((id) => ({ id }));
    const userId = selectedUser?.value;

    if (userId) {
      setIsHiddenAddButton(true);
      setIsDisabledCancelButton(true);
      setIsLoadingWhileAddUsers(true);
      setIsSearchInputOpen(false);
      GrantUserAccessToManyAssets({ host, token, assetIdList, userId })
        .then((response) => {
          const responseAssets = grantUserAccessToManyAssetsModelToAddedAssetsModel(response, assets);
          if (!!responseAssets[GrantUserAccessToManyAssetsResponseKey.FAILED].length) {
            setFailedAssetsData(responseAssets[GrantUserAccessToManyAssetsResponseKey.FAILED]);
          }
          if (!responseAssets[GrantUserAccessToManyAssetsResponseKey.FAILED].length) {
            setIsVisibleAddUserModal(false);
            setSelectedUser(undefined);

            messageAtom.success(
              t('_PROJECT_ASSETS_ADD_USER_SUCCESS_ADDED', {
                assets:
                  responseAssets[GrantUserAccessToManyAssetsResponseKey.ADDED].length +
                  responseAssets[GrantUserAccessToManyAssetsResponseKey.EXISTING].length,
              }),
              5
            );
          }
        })
        .catch(() => setIsHiddenAddButton(false))
        .finally(() => {
          setIsDisabledCancelButton(false);
          setIsLoadingWhileAddUsers(false);
        });
    }
  };

  const onCancel = () => {
    setIsVisibleAddUserModal(false);
    setSelectedUser(undefined);
    setIsDisabledAddButton(false);
    setIsHiddenAddButton(false);
    setFailedAssetsData([]);
    setIsSearchInputOpen(false);
  };

  const onSearchUser = async (query: string): Promise<LinkSearchSelectV2Items[]> => {
    const MIN_CHARACTERS = 2;
    setIsSearchInputOpen(false);

    if (query.length > MIN_CHARACTERS) {
      try {
        const searchedUsers = !!query ? await getUserSearch(query) : [];
        !query && setIsDisabledAddButton(true);
        const optionsList = usersListToOptionsList(searchedUsers);
        setIsSearchInputOpen(true);
        return optionsList;
      } catch (error) {
        setIsSearchInputOpen(false);
        messageAtom.error('ERROR');
        return [];
      }
    }

    return [];
  };

  const onSelectUser = (item?: LinkSearchSelectV2Items) => {
    setSelectedUser(item);
    setIsSearchInputOpen(false);
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getProjectAssetsWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, page, size, searchQuery]);

  useEffect(() => {
    getProjectTabInfoWithPagination({ projectId, size: allResults })
      .then((response) => {
        setCompleteAssetsList(response.data);
      })
      .catch(() => {
        messageAtom.error(t('_PROJECT_ASSETS_SELECT_ALL_ASSETS_ERROR'), 5);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResults, projectId]);

  useEffect(() => {
    const assetList = completeAssetsList.filter((asset) => checkedAssetIdList.some((item) => item === asset.id));
    setCheckedAssetList(assetList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedAssetIdList]);

  useEffect(() => {
    setIsDisabledAddButton(!selectedUser);
  }, [selectedUser]);

  return {
    allResultsText,
    assetGatewayConnectionStatusTranslations,
    assetOccupationTranslations,
    assets,
    contextualMenuAddUserText,
    addUserText: addUserTitleModalText,
    cancelText: cancelButtonModalText,
    addText: addButtonModalText,
    currentPage,
    pageSize,
    rowSelection,
    checkedAssetList,
    selectedAssetsText,
    totalPages,
    searchUserModalPlaceholder,
    selectedUser,
    modalBoxText,
    failedAssetsData,
    errorModalBoxText,
    checkedAssetIdList,
    onAddUser,
    onCancel,
    onAddMassiveUsers,
    onChangePage,
    onOpenLink,
    onSearch,
    onSelectAll,
    onSelectAsset,
    onSearchUser,
    onSelectUser,
    isDisabledAddButton,
    isLoadingWhileAddUsers,
    isVisibleAddUserModal,
    isLoading,
    isHiddenAddButton,
    isDisabledCancelButton,
    isSearchInputOpen,
  };
};
