export interface DeviceTypeWithoutGatewayDataSensorPayload {
  sensor_type: string;
  label: string;
}

export interface DeviceTypeWithoutGatewayDataExtraInformationPayload {
  name: string;
  value_type: string;
  required: boolean;
}

export interface DeviceTypeWithoutGatewayDataConfigParameterPayload {
  name: string;
  value_type: string;
  required: boolean;
  multiple: boolean;
}

export interface DeviceTypeWithoutGatewayDataPayload {
  device_type: string;
  name: string;
  sensors: DeviceTypeWithoutGatewayDataSensorPayload[];
  extra_information: DeviceTypeWithoutGatewayDataExtraInformationPayload[];
  config_parameters: DeviceTypeWithoutGatewayDataConfigParameterPayload[];
}

export interface DeviceTypeWithoutGatewayMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface DeviceWithoutGatewayLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface DeviceTypeWithoutGatewayResponsePayload {
  data: DeviceTypeWithoutGatewayDataPayload[];
  meta: DeviceTypeWithoutGatewayMetaPayload;
  links: DeviceWithoutGatewayLinksPayload;
}

export interface DeviceWithoutGatewayParameterDataPayload {
  value: string | number | null;
  unit: string | null;
}

export interface DeviceWithoutGatewayParameterPayload {
  name: string;
  data: DeviceWithoutGatewayParameterDataPayload[];
  required: boolean;
  multiple: boolean;
}

export interface DeviceWithoutGatewaySensorPayload {
  sensor_type: string;
  label: string;
  name: string | null;
  room: string | null;
  sensor_uuid: string;
  sensor_id: string;
}

export interface DeviceWithoutGatewayExtraInformationPayload {
  name: string;
  value: string | null;
  required: boolean;
}

export interface DeviceWithoutGatewaySensorCreatePayload {
  sensor_type: string;
  label: string;
  name: string | null;
  room: string | null;
}

export interface DeviceWithoutGatewayExtraInformationCreatePayload {
  name: string;
  value: string | null;
  required: boolean;
}

export interface DeviceWithoutGatewayCreatePayload {
  device_type: string;
  serial_number: string;
  name: string;
  room: string;
  asset_id: string;
  config_parameters: DeviceWithoutGatewayParameterPayload[] | null;
  sensors: DeviceWithoutGatewaySensorCreatePayload[];
  extra_information: DeviceWithoutGatewayExtraInformationCreatePayload[] | null;
}

export interface DeviceWithoutGatewayUpdatePayload {
  device_type: string;
  serial_number: string;
  name: string;
  room: string;
  asset_id: string;
  config_parameters: DeviceWithoutGatewayParameterPayload[] | null;
  sensors: DeviceWithoutGatewaySensorPayload[];
  extra_information: DeviceWithoutGatewayExtraInformationPayload[] | null;
}

export interface DeviceWithoutGatewayPayload {
  device_uuid: string;
  device_type: string;
  serial_number: string;
  name: string;
  room: string;
  asset_id: string;
  config_parameters: DeviceWithoutGatewayParameterPayload[] | null;
  sensors: DeviceWithoutGatewaySensorPayload[];
  extra_information: DeviceWithoutGatewayExtraInformationPayload[] | null;
}

export interface DeviceWithoutGatewayResponsePayload {
  data: DeviceWithoutGatewayPayload;
}

export interface DevicesListWithoutGatewayResponsePayload {
  data: DeviceWithoutGatewayPayload[];
  meta: DeviceTypeWithoutGatewayMetaPayload;
  links: DeviceWithoutGatewayLinksPayload;
}

export interface DeviceTypeWithoutGatewayDataSensorModel {
  sensorType: string;
  label: string;
}

export interface DeviceTypeWithoutGatewayDataExtraInformationModel {
  name: string;
  valueType: string;
  required: boolean;
}

export interface DeviceTypeWithoutGatewayDataConfigParameterModel {
  name: string;
  valueType: string;
  required: boolean;
  multiple: boolean;
}

export interface DeviceTypeWithoutGatewayDataModel {
  deviceType: string;
  name: string;
  sensors: DeviceTypeWithoutGatewayDataSensorModel[];
  extraInformation: DeviceTypeWithoutGatewayDataExtraInformationModel[];
  configParameters: DeviceTypeWithoutGatewayDataConfigParameterModel[];
}

export interface DeviceTypeWithoutGatewayMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface DeviceWithoutGatewayLinksModel {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface DeviceTypeWithoutGatewayResponseModel {
  data: DeviceTypeWithoutGatewayDataModel[];
  meta: DeviceTypeWithoutGatewayMetaModel;
  links: DeviceWithoutGatewayLinksModel;
}

export interface DeviceWithoutGatewayParameterDataModel {
  value: string | number | null;
  unit: string | null;
}

export interface DeviceWithoutGatewayParameterModel {
  name: string;
  data: DeviceWithoutGatewayParameterDataModel[];
  required: boolean;
  multiple: boolean;
}

export interface DeviceWithoutGatewaySensorModel {
  sensorType: string;
  label: string;
  name: string | null;
  room: string | null;
  sensorUuid: string;
  sensorId: string;
}

export interface DeviceWithoutGatewayExtraInformationModel {
  name: string;
  value: string | null;
  required: boolean;
}

export interface DeviceWithoutGatewayModel {
  deviceUuid: string;
  deviceType: string;
  serialNumber: string;
  name: string;
  room: string;
  assetId: string;
  configParameters: DeviceWithoutGatewayParameterModel[] | null;
  sensors: DeviceWithoutGatewaySensorModel[];
  extraInformation: DeviceWithoutGatewayExtraInformationModel[];
}

export interface DeviceWithoutGatewayResponseModel {
  data: DeviceWithoutGatewayModel;
}

export interface DevicesListWithoutGatewayResponseModel {
  data: DeviceWithoutGatewayModel[];
  meta: DeviceTypeWithoutGatewayMetaModel;
  links: DeviceWithoutGatewayLinksModel;
}

export interface DeviceWithoutGatewaySensorCreateModel {
  sensorType: string;
  label: string;
  name: string | null;
  room: string | null;
}

export interface DeviceWithoutGatewayExtraInformationCreateModel {
  name: string;
  value: string | null;
  required: boolean;
}

export interface DeviceWithoutGatewayCreateModel {
  deviceType: string;
  serialNumber: string;
  name: string;
  room: string;
  assetId: string;
  configParameters: DeviceWithoutGatewayParameterModel[] | null;
  sensors: DeviceWithoutGatewaySensorCreateModel[];
  extraInformation: DeviceWithoutGatewayExtraInformationCreateModel[] | null;
}

export interface DeviceWithoutGatewayUpdateModel {
  deviceType: string;
  serialNumber: string;
  name: string;
  room: string;
  assetId: string;
  configParameters: DeviceWithoutGatewayParameterModel[];
  sensors: DeviceWithoutGatewaySensorModel[];
  extraInformation: DeviceWithoutGatewayExtraInformationModel[];
}

export interface DeviceParameters {
  [key: string]: string;
}

export enum MaintenanceAction {
  BATTERY_REPLACEMENT = 'battery_replacement',
  REPLACEMENT = 'replacement',
  ADDED_DEVICE = 'added_device',
  REMOVED_DEVICE = 'removed_device',
  SOFTWARE_UPDATE = 'software_update',
  CLEANING = 'cleaning',
  ON_SITE_CHECK = 'on_site_check',
  REMOTE_CHECK = 'remote_check',
  CALL_ATTENDED = 'call_attended',
  MANAGEMENT_PASSWORD = 'management_password',
}

export interface DeviceSensorPayload {
  access_sensor_id: string;
  device_id: string;
  sensor_id: string;
  sensor_type: string;
  sensor_uuid: string;
  room: string;
  name: string;
  usage: string | null;
}

export interface DevicePayload {
  asset_id: string | null;
  available_actions: MaintenanceAction[];
  battery_level: number | null;
  connectivity: string | null;
  device_id: string;
  device_type: string;
  device_name: string;
  enabled: boolean;
  gateway_uuid: string | null;
  parameters: DeviceParameters;
  project_id: string | null;
  sensors: DeviceSensorPayload[];
  shared: boolean;
  uuid: string;
  updated_at: number | null;
}

export interface DeviceMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface DeviceLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface DevicesResponsePayload {
  data: DevicePayload[];
  meta: DeviceMetaPayload;
  links: DeviceLinksPayload;
}

export interface DeviceSensorModel {
  accessSensorId: string;
  deviceId: string;
  sensorId: string;
  sensorType: string;
  sensorUuid: string;
  room: string;
  name: string;
  usage: string | null;
}

export interface DeviceModel {
  assetId: string | null;
  availableActions: MaintenanceAction[];
  batteryLevel: number | null;
  connectivity: string | null;
  deviceId: string;
  deviceType: string;
  deviceName: string;
  enabled: boolean;
  gatewayUuid: string | null;
  parameters: DeviceParameters;
  projectId: string | null;
  sensors: DeviceSensorModel[];
  shared: boolean;
  uuid: string;
  updatedAt: number | null;
}

export interface DeviceMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface DeviceLinksModel {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface DevicesResponseModel {
  data: DeviceModel[];
  meta: DeviceMetaModel;
  links: DeviceLinksModel;
}

export enum DeviceSorterValueType {
  GATEWAY_ID = 'gateway_id',
  DEVICE_ID = 'device_id',
  DEVICE_TYPE = 'device_type',
}
