import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { DeviceTypeWithoutGatewayDataModel, DeviceWithoutGatewayModel } from 'core/domain/devices/models';
import { setDeviceWithoutGatewayFormValues, formValuesToPayload } from '../mappers/index';
import { DeviceWithoutGatewayFormDataModel, DeviceWithoutGatewayFormLiteralsModel } from '../utils';

export const useDeviceWithoutGatewayForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Record<string, string | null>>();

  const formLiterals: DeviceWithoutGatewayFormLiteralsModel = {
    deviceSectionTitle: t('_DEVICE_WITHOUT_GATEWAY_SECTION_TITLE'),
    sensorSectionTitle: t('_DEVICE_WITHOUT_GATEWAY_SENSOR_SECTION_TITLE'),
    sensorSectionDescription: t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_SECTION_DESCRIPTION'),
    requiredFieldTextLegend: t('required_field'),
  };

  const resetFormFields = () => {
    form.resetFields();
  };

  const submitForm = () => {
    form.submit();
  };

  const getFormValues = () => {
    return form.getFieldsValue();
  };

  const setFormFieldValue = ({ key, value }: { key: string; value: string }) => {
    form.setFieldValue(key, value);
  };

  const createFormStructure = ({
    value,
    devicesTypes,
    data,
  }: {
    value: string;
    devicesTypes: DeviceTypeWithoutGatewayDataModel[];
    data?: DeviceWithoutGatewayModel;
  }): DeviceWithoutGatewayFormDataModel[] => {
    const selectedDeviceType = devicesTypes.find((deviceType) => deviceType.deviceType === value);

    if (selectedDeviceType) {
      const parsedFormValues = setDeviceWithoutGatewayFormValues({ deviceType: selectedDeviceType, data });
      parsedFormValues.map(({ name, value }) => setFormFieldValue({ key: name, value }));
      return parsedFormValues;
    }

    return [];
  };

  return {
    form,
    formLiterals,
    setFormFieldValue,
    getFormValues,
    resetFormFields,
    createFormStructure,
    submitForm,
    setDeviceWithoutGatewayFormValues,
    formValuesToPayload,
  };
};
