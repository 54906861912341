import i18next from 'i18next';
import { SelectorSorterModel } from 'components/molecules/TableSorter';
import { DeviceSorterValueType } from 'core/domain/devices/models';
import { SorterOrderType } from 'constants/sort';
import { DeviceConnectivityType } from 'constants/devices';

export const getDevicesListSorterOptions = (): SelectorSorterModel[] => {
  return [
    {
      value: `${DeviceSorterValueType.GATEWAY_ID}.${SorterOrderType.ASC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_GATEWAY_ID_ASC'),
    },
    {
      value: `${DeviceSorterValueType.GATEWAY_ID}.${SorterOrderType.DESC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_GATEWAY_ID_DESC'),
    },
    {
      value: `${DeviceSorterValueType.DEVICE_ID}.${SorterOrderType.ASC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_DEVICE_ID_ASC'),
    },
    {
      value: `${DeviceSorterValueType.DEVICE_ID}.${SorterOrderType.DESC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_DEVICE_ID_DESC'),
    },
    {
      value: `${DeviceSorterValueType.DEVICE_TYPE}.${SorterOrderType.ASC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_DEVICE_TYPE_ASC'),
    },
    {
      value: `${DeviceSorterValueType.DEVICE_TYPE}.${SorterOrderType.DESC}`,
      label: i18next.t('_DEVICES_LIST_SORTER_LABEL_DEVICE_TYPE_DESC'),
    },
  ];
};

export const connectivityLiteralsManager = {
  [DeviceConnectivityType.OFFLINE]: () => i18next.t('offline'),
  [DeviceConnectivityType.ONLINE]: () => i18next.t('online'),
  [DeviceConnectivityType.UNKNOWN]: () => i18next.t('unknown'),
};
