import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from '../../../../organisms/PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import { CreateDeviceWithoutGatewayDrawer } from 'components/organisms/CreateDeviceWithoutGatewayDrawer';
import { UpdateDeviceWithoutGatewayDrawer } from 'components/organisms/UpdateDeviceWithoutGatewayDrawer';
import DeleteDeviceWithoutGatewayModal from 'components/molecules/DeleteDeviceWithoutGatewayModal';
import AddMaintenanceActionDrawer from 'components/organisms/AddMaintenanceActionDrawer';
import { TableSorter } from 'components/molecules/TableSorter';
import TableFilter from 'components/organisms/TableFilter';
import './styles.scss';

const TabDevices: FC = () => {
  const {
    devices,
    currentPage,
    currentSize,
    totalPages,
    columns,
    expandedColumns,
    gatewayId,
    createDeviceOptions,
    updateDeviceOptions,
    deleteDeviceOptions,
    maintenanceActionsDrawerOptions,
    sorterOptions,
    initialFilters,
    onChangePage,
    onApplyFilters,
    loading,
  } = useTabDevices();

  const { literals: createLiterals, onOpenCreateDeviceDrawer, onCloseCreateDeviceDrawer, createDeviceDrawerVisible } = createDeviceOptions;
  const { literals: updateLiterals, onCloseUpdateDeviceDrawer, updateDeviceDrawerVisible } = updateDeviceOptions;
  const { maintenanceDevice, isMaintenanceActionsDrawerVisible, onCloseMaintenanceActionsDrawer } = maintenanceActionsDrawerOptions;
  const { deleteDeviceModalTranslations, deleteDeviceModalVisible, onCloseDeleteDeviceModal, onDeleteDevice, deleting } = deleteDeviceOptions;
  const { title, text, mainButtonText, secondaryButtonText } = deleteDeviceModalTranslations;

  return (
    <>
      <Paper>
        <div className={'TabDevices__buttons'}>
          <TableFilter
            loading={loading}
            initialFilters={initialFilters}
            onApply={onApplyFilters}
          />
          <TableSorter
            label={sorterOptions.label}
            valueOptions={sorterOptions.valueOptions}
            value={sorterOptions.value}
            onChange={sorterOptions.onChangeSorter}
            disabled={sorterOptions.disabled}
            loading={sorterOptions.loading}
          />
          <DefaultButton
            onClick={onOpenCreateDeviceDrawer}
            icon={<Icon.Plus />}
            title={createLiterals.deviceActionButtonText}
          />
        </div>
        <PeripheralDevices
          loading={loading}
          devices={devices}
          columns={columns}
          expandedColumns={expandedColumns}
          currentPage={currentPage}
          currentSize={currentSize}
          totalPages={totalPages}
          onPagination={onChangePage}
        />
      </Paper>
      <CreateDeviceWithoutGatewayDrawer
        title={createLiterals.title}
        options={createDeviceOptions}
        onClose={onCloseCreateDeviceDrawer}
        open={createDeviceDrawerVisible}
      />
      <UpdateDeviceWithoutGatewayDrawer
        options={updateDeviceOptions}
        title={updateLiterals.title}
        open={updateDeviceDrawerVisible}
        onClose={onCloseUpdateDeviceDrawer}
      />
      <DeleteDeviceWithoutGatewayModal
        deleting={deleting}
        mainButtonText={mainButtonText}
        secondaryButtonText={secondaryButtonText}
        text={text}
        onCancel={onCloseDeleteDeviceModal}
        onOk={onDeleteDevice}
        title={title}
        open={deleteDeviceModalVisible}
      />
      {gatewayId && (
        <AddMaintenanceActionDrawer
          visible={isMaintenanceActionsDrawerVisible}
          gatewayId={gatewayId}
          onClose={onCloseMaintenanceActionsDrawer}
          deviceData={maintenanceDevice}
        />
      )}
    </>
  );
};

export default TabDevices;
