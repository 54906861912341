import { DEFAULT_PAGE_SIZE } from 'components/template/AlfredTableTemplate';
import {
  QrAccessListLinksModel,
  QrAccessListLinksPayload,
  QrAccessListMetaModel,
  QrAccessListMetaPayload,
  QrAccessListResponseModel,
  QrAccessListResponsePayload,
  QrAccessModel,
  QrAccessPayload,
} from '../models';

const payloadToQrAccess = ({ created_by, qr, qr_base_64, status, uid, valid_from, valid_until, is_shown }: QrAccessPayload): QrAccessModel => {
  return {
    createdBy: created_by ?? '',
    qr: qr ?? '',
    qrBase64: qr_base_64 ?? '',
    status: status ?? '',
    uid: uid ?? '',
    validFrom: valid_from ?? 0,
    validUntil: valid_until ?? 0,
    isShown: is_shown ?? false,
  };
};

const payloadToQrAccessListMeta = ({ all_results, page_size, results, total_pages }: QrAccessListMetaPayload): QrAccessListMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? DEFAULT_PAGE_SIZE,
    results: results ?? 0,
    totalPages: total_pages ?? 1,
  };
};

const payloadToQrAccessListLinks = ({ first, last, next, prev, self }: QrAccessListLinksPayload): QrAccessListLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToQrAccessListResponse = ({ data, meta, links }: QrAccessListResponsePayload): QrAccessListResponseModel => {
  const transformedData = data.map((item) => payloadToQrAccess(item));
  const transformedMeta = payloadToQrAccessListMeta(meta);
  const transformedLinks = payloadToQrAccessListLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};
