import { FC } from 'react';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { TableFilterOptionModel } from '../../models';
import { DATE_TIME_FORMAT_REVERSE_SLASH } from 'constants/date';
import './styles.scss';

interface Props {
  option: TableFilterOptionModel;
  onChangeFilter: (option: TableFilterOptionModel) => void;
}

const MAX_DEFAULT_DATE = Date.now();
const MAX_LABEL_LENGTH = 12;

const disabledDate = ({
  current,
  minValue = 0,
  maxValue = MAX_DEFAULT_DATE,
}: {
  current: moment.Moment | null,
  minValue?: number,
  maxValue?: number,
}) => {
  if (!current) return false;

  const currentDate = current.toDate().getTime();
  return currentDate > maxValue || currentDate < minValue;
};

export const DatePickerComponent: FC<Props> = ({ option, onChangeFilter }) => {
  const maxValue = option.max || MAX_DEFAULT_DATE;
  const defaultValue = !!option.defaultValue ? Number(option.defaultValue) : maxValue;
  const isFullWidth = option.label.length >= MAX_LABEL_LENGTH;

  return (
    <div className={'TableFilterDatePicker__wrapper'} key={option.key}>
      <div className={'TableFilterDatePicker__wrapper-content'}>
        <div className={`TableFilterDatePicker__wrapper-content-selector${isFullWidth ? '-full' : ''}`}>
          <div className={'TableFilterDatePicker__selector'}>
            <Checkbox
              key={option.key}
              checked={option.selected}
              onChange={({ target }) => onChangeFilter({ ...option, selected: target.checked, value: option.value || String(defaultValue) })}
            >
              <p className={`TableFilterCheckbox__text${!option.selected ? '-disabled' : ''}`}>
                {option.label}
              </p>
            </Checkbox>
          </div>
          <div className={`TableFilterDatePicker__input-wrapper${isFullWidth ? '-full' : ''}`}>
            <DatePicker
              showTime
              format={DATE_TIME_FORMAT_REVERSE_SLASH}
              onChange={(value) => onChangeFilter({ ...option, value: String(value?.valueOf()) || null })}
              disabled={!option.selected}
              disabledDate={(current) => disabledDate({ current, minValue: option.min, maxValue: option.max })}
              defaultValue={moment(!!option.value ? Number(option.value) : defaultValue)}
              size={'small'}
              status={option.error ? 'error' : ''}
            />
          </div>
        </div>
      </div>
      <p className={'TableFilterDatePicker__errorMessage'}>{option.errorMessage}</p>
    </div>
  )
}
