import { FC } from 'react';
import { Pagination, TableColumnsType } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { DeviceSensorModel } from 'core/domain/devices/models';
import TableAtom from 'components/atoms/TableAtom';
import { TABLE_PAGE_SIZE_DEFAULT_OPTIONS } from 'constants/table';
import { DEFAULT_PAGE_SIZE } from 'components/organisms/AlfredTable';
import { CustomDeviceModel } from 'constants/models';

interface PeripheralDevicesProps {
  gatewayId?: string;
  devices?: CustomDeviceModel[]
  loading?: boolean;
  columns: ColumnType<CustomDeviceModel>[];
  currentPage: number;
  currentSize: number;
  totalPages: number;
  expandedColumns: TableColumnsType<DeviceSensorModel>;
  onPagination: (page: number, pageSize?: number) => void;
  onExpand: (expanded: boolean, record: CustomDeviceModel) => void;
}

const PeripheralDevices: FC<PeripheralDevicesProps> = ({
  devices = [],
  loading = false,
  columns,
  expandedColumns,
  currentPage,
  currentSize,
  totalPages,
  onPagination,
  onExpand,
}) => {

  const expandedRowRender = ({ sensors }: CustomDeviceModel) => {
    return (
      <TableAtom<DeviceSensorModel>
        columns={expandedColumns}
        dataSource={sensors}
        pagination={false}
        rowKey={'sensorUuid'}
      />
    );
  };

  return (
    <>
      <TableAtom<CustomDeviceModel>
        className={'PeripheralDevices__expanded-table-wrapper'}
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender,
          onExpand,
        }}
        rowKey={'deviceId'}
        dataSource={devices}
        pagination={false}
      />
      <Pagination
        className={'PeripheralDevices__pagination'}
        size={'small'}
        showSizeChanger={true}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSizeOptions={TABLE_PAGE_SIZE_DEFAULT_OPTIONS}
        current={currentPage}
        total={currentSize * totalPages}
        pageSize={currentSize}
        onChange={onPagination}
      />
    </>
  );
};

export default PeripheralDevices;
