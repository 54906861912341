import { AssetTabType } from 'components/pages/AssetPage';
import { ROUTE_PATH_ADMIN } from '../../configRoutes';

export const ROUTE_PATH_ASSETS = ROUTE_PATH_ADMIN + '/assets';
export const ROUTE_PATH_ASSET = ROUTE_PATH_ADMIN + '/assets/:assetId/:tab';
export const ROUTE_PATH_ASSET_QR_TOOL = ROUTE_PATH_ADMIN + '/assets/qr-generator-tool';

export const getAssetPathById = (assetId: string) => `${ROUTE_PATH_ADMIN}/assets/${assetId}/start`;

export const getAssetWithParamsPathById = ({
  assetId,
  tab = AssetTabType.START,
  page,
  size,
  sort,
  filters,
}: {
  assetId: string;
  tab?: string;
  page?: string;
  size?: string;
  sort?: string;
  filters?: string;
}) => {
  const routeTab = `${ROUTE_PATH_ASSETS}/${assetId}/${tab}`;
  const routeWithPagination = `${routeTab}?page=${page}&size=${size}`;
  const routeWithPaginationAndSort = `${routeWithPagination}&sort=${sort}`;
  const routeWithPaginationFilterParams = filters ? `${routeWithPagination}&filter=${filters}` : routeWithPagination;
  const routeWithPaginationSortAndFilterParams = filters ? `${routeWithPaginationAndSort}&filter=${filters}` : routeWithPaginationAndSort;
  const routeWithPaginationSortParams = sort ? routeWithPaginationSortAndFilterParams : routeWithPaginationFilterParams;
  return page ? routeWithPaginationSortParams : routeTab;
};

export const getAccessesAssetPathById = (assetId: string) => `${ROUTE_PATH_ADMIN}/assets/${assetId}/accesses`;

export const getAssetsWithPaginationPath = ({ page, size }: { page: string; size: string }) =>
  `${ROUTE_PATH_ADMIN}/assets?page=${page}&size=${size}`;
