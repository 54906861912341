import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DevicesResponseModel, DevicesResponsePayload } from '../models';
import { payloadToDevicesListResponse } from '../mappers';
import { FilterModel, SortModel } from 'utils/globalModels';
import { buildUrlFilters, buildUrlSort } from 'utils/url';

const getUrl = ({
  host,
  projectId,
  assetId,
  gatewayId,
  page,
  size,
  search,
  sort,
  filters,
}: {
  host: string;
  projectId?: string;
  assetId?: string;
  gatewayId?: string;
  page: number;
  size: number;
  search?: string;
  sort: SortModel[];
  filters?: FilterModel;
}): string => {
  const params = new URLSearchParams();
  projectId && params.append('project_id', projectId);
  assetId && params.append('asset_id', assetId);
  gatewayId && params.append('gateway_id', gatewayId);
  page && params.append('page[number]', `${page}`);
  size && params.append('page[size]', `${size}`);
  !!search && params.append('search', search);
  sort.length > 0 && params.append('sort', buildUrlSort(sort));
  !!filters?.conditions.length && params.append('filter', buildUrlFilters(filters));

  return `${host}/devices?${params}`;
};

export const GetDevicesLists = async ({
  host,
  token,
  projectId,
  assetId,
  gatewayId,
  page,
  size,
  search,
  sort = [],
  filters,
}: {
  host: string;
  token: string;
  projectId?: string;
  assetId?: string;
  gatewayId?: string;
  page: number;
  size: number;
  search?: string;
  sort?: SortModel[];
  filters?: FilterModel;
}): Promise<DevicesResponseModel> => {
  try {
    const url = getUrl({ host, projectId, assetId, gatewayId, page, size, search, sort, filters });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 30000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<DevicesResponsePayload>;
    return payloadToDevicesListResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
