import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from 'components/organisms/PeripheralDevices';
import ShareSensorModal from 'components/organisms/ShareSensorModal';
import { TableSorter } from 'components/molecules/TableSorter';
import { useTabDevices } from './resources/useTabDevices';
import TableFilter from 'components/organisms/TableFilter';
import './styles.scss';

const TabDevices: FC = () => {
  const {
    devices,
    currentPage,
    currentSize,
    totalPages,
    peripheralsTitle,
    columns,
    expandedColumns,
    shareSensorOptions,
    sorterOptions,
    initialFilters,
    onApplyFilters,
    onChangePage,
    onExpandDevice,
    loading,
  } = useTabDevices();

  return (
    <>
      <Paper>
        <h2>{peripheralsTitle}</h2>
        <div className={'TabDevices__buttons'}>
          <TableFilter
            loading={loading}
            initialFilters={initialFilters}
            onApply={onApplyFilters}
          />
          <TableSorter
            label={sorterOptions.label}
            valueOptions={sorterOptions.valueOptions}
            value={sorterOptions.value}
            onChange={sorterOptions.onChangeSorter}
            disabled={sorterOptions.disabled}
            loading={sorterOptions.loading}
          />
        </div>
        <PeripheralDevices
          devices={devices}
          columns={columns}
          expandedColumns={expandedColumns}
          currentPage={currentPage}
          currentSize={currentSize}
          totalPages={totalPages}
          onPagination={onChangePage}
          onExpand={onExpandDevice}
          loading={loading}
        />
      </Paper>
      <ShareSensorModal
        modalOptions={shareSensorOptions}
        open={shareSensorOptions.shareSensorModalVisible}
        onCancel={shareSensorOptions.onCloseShareSensorModal}
      />
    </>
  );
};

export default TabDevices;
