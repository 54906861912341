import { ProjectTab } from 'models/project.model';
import { useHistory, useParams } from 'react-router-dom';
import { ProjectServiceSubTabs } from '../Tabs/TabServices/Subtabs/constants';
import { ProjectCustomizationSubTabs } from '../Tabs/TabCustomization/SubTabs/constants';
import { getProjectPath, getProjectPathById } from 'components/pages/App/routes/projects/config';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { DeviceSorterValueType } from 'core/domain/devices/models';
import { SorterOrderType } from 'constants/sort';

export const useProjectNavigation = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();

  const projectSubTabRouteManager = {
    [ProjectTab.ASSETS]: {
      projectId,
      tab: ProjectTab.ASSETS,
      page: TABLE_PAGINATION_PAGE_DEFAULT,
      size: TABLE_PAGINATION_SIZE_DEFAULT,
    },
    [ProjectTab.CAMERAS]: {
      projectId,
      tab: ProjectTab.CAMERAS,
    },
    [ProjectTab.DOCUMENTS]: {
      projectId,
      tab: ProjectTab.DOCUMENTS,
    },
    [ProjectTab.GATEWAYS]: {
      projectId,
      tab: ProjectTab.GATEWAYS,
    },
    [ProjectTab.SERVICES]: {
      projectId,
      tab: ProjectTab.SERVICES,
      subTab: ProjectServiceSubTabs.USEFUL_CONTACTS,
    },
    [ProjectTab.CUSTOMIZATION]: {
      projectId,
      tab: ProjectTab.CUSTOMIZATION,
      subTab: ProjectCustomizationSubTabs.APP_AND_WEBAPP,
    },
    [ProjectTab.INTERCOMS]: {
      projectId,
      tab: ProjectTab.INTERCOMS,
    },
    [ProjectTab.LAYOUT]: {
      projectId,
      tab: ProjectTab.LAYOUT,
    },
    [ProjectTab.DEVICES]: {
      projectId,
      tab: ProjectTab.DEVICES,
      page: TABLE_PAGINATION_PAGE_DEFAULT,
      size: TABLE_PAGINATION_SIZE_DEFAULT,
      sort: `${DeviceSorterValueType.GATEWAY_ID}.${SorterOrderType.ASC}`,
    },
  };

  const onClickTab = (activeTab: ProjectTab): void => {
    const routeManager = projectSubTabRouteManager[activeTab];
    const route = getProjectPath(routeManager);
    return history.replace(route);
  };

  const onClickCustomizationSubTab = (subTab?: ProjectCustomizationSubTabs): void => {
    const projectRoutes = { projectId, tab: ProjectTab.CUSTOMIZATION, subTab: subTab || ProjectCustomizationSubTabs.APP_AND_WEBAPP };
    return history.replace(getProjectPathById(projectRoutes));
  };

  const onClickServicesSubTab = (subTab?: ProjectServiceSubTabs): void => {
    const projectRoutes = { projectId, tab: ProjectTab.SERVICES, subTab: subTab || ProjectServiceSubTabs.USEFUL_CONTACTS };
    return history.replace(getProjectPathById(projectRoutes));
  };

  return {
    onClickTab,
    onClickCustomizationSubTab,
    onClickServicesSubTab,
  };
};
