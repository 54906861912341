import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { CreateEventsAndNewsModel } from '../models/eventsAndNewsEntityModels';
import { createEventsAndNewsToPayload } from '../mappers/createEventsAndNewsToPayload';

const getUrl = ({ host, projectId }: { host: string; projectId: string }) => {
  return `${host}/projects/${projectId}/events-news`;
};

export const CreateEventsAndNews = async ({
  host,
  token,
  projectId,
  values,
}: {
  host: string;
  token: string;
  projectId: string;
  values: CreateEventsAndNewsModel;
}): Promise<void> => {
  try {
    const url = getUrl({ host, projectId });

    const mappedData = createEventsAndNewsToPayload(values);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
