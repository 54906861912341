import { message } from 'antd';
import { ROUTE_PATH_USERS } from 'components/pages/App/routes/users/config';
import { SendNotificationModel } from 'core/domain/notifications/model';
import { SendNotification } from 'core/domain/notifications/repositories/sendNotification';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { User, UserStatus } from 'models/users.model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { deleteUser, getProfileUser, getUser } from 'services/api/users';

const STATUS_USER: UserStatus[] = ['pending', 'confirmation', 'withdrawn'];

export const useUser = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { userId, tab } = useParams<{ userId: string; tab: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showingPop, setShowingPop] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [user, setUser] = useState<User>();
  const [currentUser, setCurrentUser] = useState('');
  const [activeTab, setActiveTab] = useState(tab);
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [isSendNotificationModalVisible, setIsSendNotificationModalVisible] = useState(false);

  const userPageLiterals = {
    userLabel: t('user'),
    userStatus: user && t(STATUS_USER[user.status]),
    userLang: user && t(`LANG.${user.lang}`),
    userRoles: (user && user?.roles.map((role) => t(`ROLES.${role}`)).join(', ')) || t('not_reported'),
    userName: t('userName'),
    email: t('email'),
    notReported: t('not_reported'),
    roles: t('roles'),
    status: t('status'),
    lang: t('lang'),
    assets: t('assets'),
    unknown: t('unknown'),
    createdAt: t('created_at'),
    confirmedAt: t('confirmed_at'),
    deleteUserConfirm: t('delete_user_confirm'),
    deleteUser: t('delete_user'),
    sendNotification: t('send_notification'),
  };

  const sendNotificationFormLiterals = {
    formTitleLabel: t('_SEND_NOTIFICATION_FORM_TITLE_LABEL'),
    formTitlePlaceholder: t('_SEND_NOTIFICATION_FORM_TITLE_PLACEHOLDER'),
    formMessageLabel: t('_SEND_NOTIFICATION_FORM_MESSAGE_LABEL'),
    formMessagePlaceholder: t('_SEND_NOTIFICATION_FORM_MESSAGE_PLACEHOLDER'),
    formFieldRequiredMeesage: t('_FIELD_REQUIRED'),
  };
  const sendNotificationModalLiterals = {
    modalTitle: t('_SEND_NOTIFICATION_FORM_TITLE'),
    modalCancelButton: t('_SEND_NOTIFICATION_FORM_CANCEL'),
    modalSendButton: t('_SEND_NOTIFICATION_FORM_SEND'),
  };

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    setLoading(true);
    getUser(userId)
      .then(({ data }) => setUser(data))
      .finally(() => setLoading(false));
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    getProfileUser()
      .then(({ id }) => setCurrentUser(id))
      .finally(() => setLoading(false));
  }, []);

  const onGoBack = () => history.goBack();

  const onShowDeleteUserPop = (show: boolean) => setShowingPop(show);

  const handleDeleteUser = async (user: User) => {
    setDeletingUser(true);
    try {
      const response = await deleteUser(user.id);
      message.success(t('deleted_user'), 3);
      history.push(ROUTE_PATH_USERS);
      return response;
    } catch (error) {
      message.error(t('delete_error_message'), 3);
    } finally {
      setDeletingUser(false);
    }
  };

  const onChangeTab = (activeTab: string) => history.push(activeTab);

  const onClickSendNotification = () => {
    setIsSendNotificationModalVisible(true);
  };

  const onCancelSendNotification = () => {
    setIsSendNotificationModalVisible(false);
  };

  const onSendNotification = async ({ title, content }: SendNotificationModel) => {
    setIsSendingNotification(true);
    try {
      const userEmail = user?.email || '';
      if (!userEmail) throw new Error();

      const notification = {
        title,
        content,
        userList: [userEmail],
      };
      await SendNotification({
        token,
        host,
        notification,
      });
      message.success(t('_SEND_NOTIFICATION_FORM_SEND_SUCCESS'), 3);
      setIsSendNotificationModalVisible(false);
    } catch (error) {
      message.error(t('_SEND_NOTIFICATION_FORM_SEND_ERROR'), 3);
    } finally {
      setIsSendingNotification(false);
    }
  };

  return {
    user,
    currentUser,
    activeTab,
    userPageLiterals,
    sendNotificationFormLiterals,
    sendNotificationModalLiterals,
    onGoBack,
    onChangeTab,
    handleDeleteUser,
    onShowDeleteUserPop,
    onClickSendNotification,
    onCancelSendNotification,
    onSendNotification,
    showingPop,
    isSendNotificationModalVisible,
    isSendingNotification,
    deletingUser,
    loading,
  };
};
