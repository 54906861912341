import { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { DatePicker, Form } from 'antd';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import { COLORS } from 'constants/colors';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import InputAtom from 'components/atoms/InputAtom';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { ManualQRAccessInitialStatesModel } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useManualQRAccess/models';
import './styles.scss';

const DRAWER_ATOM_WIDTH = '480px';

export enum ManualQRAccessInputFormKey {
  RANGE_DATE = 'rangeDate',
  QR_CODE = 'QRCode',
}

interface Props extends ManualQRAccessInitialStatesModel {
  title: string;
  closeButtonText: string;
  saveButtonText: string;
  initialQrBadgeText: string;
  qrCharacterCounterText: string;
  onClose: () => void;
  onSave: () => void;
  isLoading: boolean;
  isVisible: boolean;
}

const ManualQRAccessDrawer: FC<Props> = ({
  title,
  rangeDate,
  QRCode,
  closeButtonText,
  saveButtonText,
  initialQrBadgeText,
  qrCharacterCounterText,
  onClose,
  onSave,
  isLoading,
  isVisible,
}) => {

  return (
    <DrawerAtom
      width={DRAWER_ATOM_WIDTH}
      className={'ManualQRAccessDrawer__wrapper'}
      title={
        <TextAtom
          className={'ManualQRAccessDrawer__title'}
          style={{ color: COLORS.text }}
        >{title}</TextAtom>
      }
      destroyOnClose={true}
      closable={!isLoading}
      maskClosable={!isLoading}
      onClose={onClose}
      placement={'right'}
      size={'large'}
      open={isVisible}
    >
      <main className={'ManualQRAccessDrawer__main'}>
        <div className={'ManualQRAccessDrawer__main-wrapper'}>
          <div className={'ManualQRAccessDrawer__main-wrapper-input'}>
            <div className={'ManualQRAccessDrawer__input-label-range-date'}>{`${rangeDate.label} ${rangeDate.isRequired && '*'}`}</div>
            <Form.Item
              validateStatus={rangeDate.isError ? 'error' : undefined}
              help={rangeDate.errorMessage}
            >
              <DatePicker.RangePicker
                className={'ManualQRAccessDrawer__main-wrapper-input-range-picker'}
                format={rangeDate.formatDate}
                allowClear
                defaultValue={rangeDate.values as any}
                onChange={rangeDate.onChangeRange as any}
              />
            </Form.Item>
          </div>
          <div>
            <div className={'ManualQRAccessDrawer__input-label-QR-code'}>{`${QRCode.label} ${QRCode.isRequired && '*'}`}</div>
            <Form.Item
              validateStatus={QRCode.isError ? 'error' : undefined}
              help={QRCode.errorMessage}
            >
              <InputAtom
                onChange={(event) => QRCode.onChangeValue(event.target.value)}
                value={QRCode.value}
                placeholder={QRCode.placeholder}
              />
              <div className={'ManualQRAccessDrawer__char-count-QR-code'}>{qrCharacterCounterText}</div>
            </Form.Item>
          </div>
        </div>
        <div className={'ManualQRAccessDrawer__model'}>
          <div className={'ManualQRAccessDrawer__model-wrapper'}>
            <div className={'ManualQRAccessDrawer__model-qr-wrapper'}>
              {!QRCode.value && (
                <div className={'ManualQRAccessDrawer__model-qr-initial'}>
                  <p className={'ManualQRAccessDrawer__model-qr-initial-text'}>{initialQrBadgeText}</p>
                </div>
              )}
              {!!QRCode.value && <QRCodeSVG value={QRCode.value} className={'ManualQRAccessDrawer__model-qr'} />}
            </div>
          </div>
        </div>
      </main>
      <footer>
        <DefaultButton
          disabled={isLoading}
          onClick={onClose}
          title={closeButtonText} />
        <PrimaryButton
          loading={isLoading}
          onClick={onSave}
          title={saveButtonText}
        />
      </footer>
    </DrawerAtom>
  );
}

export default ManualQRAccessDrawer;
