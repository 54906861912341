import { FilterOperatorsType } from 'constants/filters';

export const compareOperatorsManager = {
  [FilterOperatorsType.EQUAL]: (a: number, b: number) => a === b,
  [FilterOperatorsType.GREATER_OR_EQUAL_THAN]: (a: number, b: number) => a >= b,
  [FilterOperatorsType.GREATER_THAN]: (a: number, b: number) => a > b,
  [FilterOperatorsType.LESS_OR_EQUAL_THAN]: (a: number, b: number) => a <= b,
  [FilterOperatorsType.LESS_THAN]: (a: number, b: number) => a < b,
  [FilterOperatorsType.NOT_EQUAL]: (a: number, b: number) => a !== b,
  [FilterOperatorsType.NOT_NULL]: (a: number) => a !== null,
  [FilterOperatorsType.NULL]: (a: number) => a === null,
};
