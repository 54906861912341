export enum FilterOperatorsType {
  NOT_NULL = '!*=null',
  NULL = '*=null',
  GREATER_OR_EQUAL_THAN = '>=',
  LESS_OR_EQUAL_THAN = '<=',
  NOT_EQUAL = '!=',
  EQUAL = '=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
}

export enum FilterLogicsType {
  AND = ',',
  OR = '|',
}

export const exactOperators = [
  FilterOperatorsType.EQUAL,
  FilterOperatorsType.NOT_EQUAL,
  FilterOperatorsType.NULL,
  FilterOperatorsType.NOT_NULL,
];

export const DEFAULT_FILTER_INPUT_NUMBER_MIN_VALUE = 0;
export const DEFAULT_FILTER_INPUT_NUMBER_MAX_VALUE = 100;
export const FILTER_INPUT_NUMBER_MAX_LABEL_LENGTH = 12;
