import { FC } from 'react';
import { TableFilterOptionModel, TableFilterType } from '../../models';
import { CheckboxComponent } from '../Checkbox';
import { InputComponent } from '../Input';
import { NumericInputComponent } from '../InputNumber';
import { DatePickerComponent } from '../DatePicker';

interface Props {
  option: TableFilterOptionModel;
  type: TableFilterType;
  onChangeFilter: (option: TableFilterOptionModel) => void;
}

export const TableFilterComponentManager: FC<Props> = ({ type, ...props }) => {
  const ComponentsManager = {
    [TableFilterType.CHECKBOX]: <CheckboxComponent {...props} key={props.option.key} />,
    [TableFilterType.INPUT]: <InputComponent {...props} key={props.option.key} />,
    [TableFilterType.INPUT_NUMBER]: <NumericInputComponent {...props} key={props.option.key} />,
    [TableFilterType.RANGE]: <NumericInputComponent {...props} key={props.option.key} />,
    [TableFilterType.DATE]: <DatePickerComponent {...props} key={props.option.key} />,
    [TableFilterType.DATE_RANGE]: <DatePickerComponent {...props} key={props.option.key} />,
  };

  return ComponentsManager[type];
}
