import { TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import {
  DeviceLinksModel,
  DeviceLinksPayload,
  DeviceMetaModel,
  DeviceMetaPayload,
  DeviceModel,
  DevicePayload,
  DeviceSensorModel,
  DeviceSensorPayload,
  DeviceTypeWithoutGatewayDataConfigParameterModel,
  DeviceTypeWithoutGatewayDataConfigParameterPayload,
  DeviceTypeWithoutGatewayDataExtraInformationModel,
  DeviceTypeWithoutGatewayDataExtraInformationPayload,
  DevicesResponseModel,
  DevicesResponsePayload,
  DeviceTypeWithoutGatewayDataModel,
  DeviceTypeWithoutGatewayDataPayload,
  DeviceTypeWithoutGatewayDataSensorModel,
  DeviceTypeWithoutGatewayDataSensorPayload,
  DeviceTypeWithoutGatewayMetaModel,
  DeviceTypeWithoutGatewayMetaPayload,
  DeviceTypeWithoutGatewayResponseModel,
  DeviceTypeWithoutGatewayResponsePayload,
  DeviceWithoutGatewayCreateModel,
  DeviceWithoutGatewayCreatePayload,
  DeviceWithoutGatewayExtraInformationModel,
  DeviceWithoutGatewayExtraInformationPayload,
  DeviceWithoutGatewayModel,
  DeviceWithoutGatewayParameterDataModel,
  DeviceWithoutGatewayParameterDataPayload,
  DeviceWithoutGatewayParameterModel,
  DeviceWithoutGatewayParameterPayload,
  DeviceWithoutGatewayPayload,
  DeviceWithoutGatewayResponseModel,
  DeviceWithoutGatewayResponsePayload,
  DeviceWithoutGatewaySensorCreateModel,
  DeviceWithoutGatewaySensorCreatePayload,
  DeviceWithoutGatewaySensorModel,
  DeviceWithoutGatewaySensorPayload,
  DeviceWithoutGatewayUpdateModel,
  DeviceWithoutGatewayUpdatePayload,
} from '../models';

const payloadToDeviceTypeWithoutGatewayDataSensor = ({
  label,
  sensor_type,
}: DeviceTypeWithoutGatewayDataSensorPayload): DeviceTypeWithoutGatewayDataSensorModel => {
  return {
    label: label ?? '',
    sensorType: sensor_type ?? '',
  };
};

const payloadToDeviceTypeWithoutGatewayDataConfigParameter = ({
  multiple,
  name,
  required,
  value_type,
}: DeviceTypeWithoutGatewayDataConfigParameterPayload): DeviceTypeWithoutGatewayDataConfigParameterModel => {
  return {
    multiple: multiple ?? false,
    name: name ?? '',
    required: required ?? false,
    valueType: value_type ?? '',
  };
};

const payloadToDeviceTypeWithoutGatewayDataExtraInformation = ({
  name,
  required,
  value_type,
}: DeviceTypeWithoutGatewayDataExtraInformationPayload): DeviceTypeWithoutGatewayDataExtraInformationModel => {
  return {
    name: name ?? '',
    required: required ?? false,
    valueType: value_type ?? '',
  };
};

const payloadToDeviceTypeWithoutGatewayData = ({
  name,
  device_type,
  sensors,
  config_parameters,
  extra_information,
}: DeviceTypeWithoutGatewayDataPayload): DeviceTypeWithoutGatewayDataModel => {
  const transformedSensors = sensors.map((item) => payloadToDeviceTypeWithoutGatewayDataSensor(item)) ?? [];
  const transformedConfigParameters = config_parameters.map((item) => payloadToDeviceTypeWithoutGatewayDataConfigParameter(item)) ?? [];
  const transformedExtraInformation = extra_information.map((item) => payloadToDeviceTypeWithoutGatewayDataExtraInformation(item)) ?? [];

  return {
    name: name ?? '',
    deviceType: device_type ?? '',
    sensors: transformedSensors,
    configParameters: transformedConfigParameters,
    extraInformation: transformedExtraInformation,
  };
};

const payloadToDeviceTypeWithoutGatewayMeta = ({
  all_results,
  page_size,
  results,
  total_pages,
}: DeviceTypeWithoutGatewayMetaPayload): DeviceTypeWithoutGatewayMetaModel => {
  return {
    allResults: all_results ?? 1,
    pageSize: page_size ?? 1,
    results: results ?? 1,
    totalPages: total_pages ?? 1,
  };
};

export const payloadToDevicesTypesListWithoutGateways = ({
  data,
  meta,
  links,
}: DeviceTypeWithoutGatewayResponsePayload): DeviceTypeWithoutGatewayResponseModel => {
  const transformedData = data.map((item) => payloadToDeviceTypeWithoutGatewayData(item)) ?? [];
  const transformedMeta = payloadToDeviceTypeWithoutGatewayMeta(meta);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: {
      first: links.first ?? '',
      last: links.last ?? '',
      next: links.next ?? '',
      prev: links.prev ?? '',
      self: links.self ?? '',
    },
  };
};

const deviceParameterDataToPayload = ({
  value,
  unit,
}: DeviceWithoutGatewayParameterDataModel): DeviceWithoutGatewayParameterDataPayload => {
  return { value, unit };
};

const deviceParameterToPayload = ({
  data,
  multiple,
  name,
  required,
}: DeviceWithoutGatewayParameterModel): DeviceWithoutGatewayParameterPayload => {
  const transformedData = !!data.length ? data.map((item) => deviceParameterDataToPayload(item)) : [];
  return {
    data: transformedData,
    multiple,
    name,
    required,
  };
};

const deviceWithoutGatewaySensorCreateToPayload = ({
  label,
  name,
  room,
  sensorType,
}: DeviceWithoutGatewaySensorCreateModel): DeviceWithoutGatewaySensorCreatePayload => {
  return {
    label,
    name,
    room,
    sensor_type: sensorType,
  };
};

const deviceWithoutGatewayExtraInformationCreateToPayload = ({
  name,
  required,
  value,
}: DeviceWithoutGatewayExtraInformationModel): DeviceWithoutGatewayExtraInformationPayload => {
  return {
    name,
    required,
    value,
  };
};

export const deviceWithoutGatewayCreateToPayload = ({
  configParameters,
  deviceType,
  name,
  room,
  serialNumber,
  assetId,
  sensors,
  extraInformation,
}: DeviceWithoutGatewayCreateModel): DeviceWithoutGatewayCreatePayload => {
  const transformedConfigParameters = configParameters?.length ? configParameters.map((item) => deviceParameterToPayload(item)) : null;
  const transformedSensors = sensors.map((item) => deviceWithoutGatewaySensorCreateToPayload(item)) ?? [];
  const transformedExtraInformation = extraInformation?.length
    ? extraInformation.map((item) => deviceWithoutGatewayExtraInformationCreateToPayload(item))
    : null;

  return {
    config_parameters: transformedConfigParameters,
    device_type: deviceType,
    name,
    room,
    asset_id: assetId,
    serial_number: serialNumber,
    sensors: transformedSensors,
    extra_information: transformedExtraInformation,
  };
};

const deviceWithoutGatewaySensorUpdateToPayload = ({
  label,
  name,
  room,
  sensorId,
  sensorType,
  sensorUuid,
}: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorPayload => {
  return {
    label,
    name,
    room,
    sensor_id: sensorId,
    sensor_type: sensorType,
    sensor_uuid: sensorUuid,
  };
};

const deviceWithoutGatewayExtraInformationUpdateToPayload = ({
  name,
  required,
  value,
}: DeviceWithoutGatewayExtraInformationModel): DeviceWithoutGatewayExtraInformationPayload => {
  return {
    name,
    required,
    value,
  };
};

export const deviceWithoutGatewayUpdateToPayload = ({
  configParameters,
  deviceType,
  name,
  room,
  serialNumber,
  assetId,
  sensors,
  extraInformation,
}: DeviceWithoutGatewayUpdateModel): DeviceWithoutGatewayUpdatePayload => {
  const transformedConfigParameters = configParameters?.length ? configParameters.map((item) => deviceParameterToPayload(item)) : null;
  const transformedSensors = sensors.map((item) => deviceWithoutGatewaySensorUpdateToPayload(item)) ?? [];
  const transformedExtraInformation = extraInformation?.length
    ? extraInformation.map((item) => deviceWithoutGatewayExtraInformationUpdateToPayload(item))
    : null;

  return {
    config_parameters: transformedConfigParameters,
    device_type: deviceType,
    name,
    room,
    asset_id: assetId,
    serial_number: serialNumber,
    sensors: transformedSensors,
    extra_information: transformedExtraInformation,
  };
};

const payloadToDeviceWithoutGatewayParameterDataPayload = ({
  value,
  unit,
}: DeviceWithoutGatewayParameterDataPayload): DeviceWithoutGatewayParameterDataModel => {
  const transformedValue = typeof value === 'number' ? 0 : '';
  return {
    value: value ?? transformedValue,
    unit: unit ?? '',
  };
};

const payloadToDeviceWithoutGatewayParameter = ({
  data,
  multiple,
  name,
  required,
}: DeviceWithoutGatewayParameterPayload): DeviceWithoutGatewayParameterModel => {
  const transformedData = data.map((item) => payloadToDeviceWithoutGatewayParameterDataPayload(item)) ?? [];

  return {
    data: transformedData,
    multiple: multiple ?? false,
    name: name ?? '',
    required: required ?? false,
  };
};

const payloadToDeviceWithoutGatewaySensor = ({
  label,
  name,
  room,
  sensor_id,
  sensor_type,
  sensor_uuid,
}: DeviceWithoutGatewaySensorPayload): DeviceWithoutGatewaySensorModel => {
  return {
    label: label ?? '',
    name: name ?? null,
    room: room ?? '',
    sensorId: sensor_id ?? '',
    sensorType: sensor_type ?? '',
    sensorUuid: sensor_uuid ?? '',
  };
};

const payloadToDeviceTypeWithoutGatewayExtraInformation = ({
  name,
  required,
  value,
}: DeviceWithoutGatewayExtraInformationPayload): DeviceWithoutGatewayExtraInformationModel => {
  return {
    name: name ?? '',
    required: required ?? false,
    value: value ?? null,
  };
};

const payloadToDeviceDetailWithoutGateway = ({
  config_parameters,
  device_type,
  device_uuid,
  name,
  room,
  serial_number,
  asset_id,
  sensors,
  extra_information,
}: DeviceWithoutGatewayPayload): DeviceWithoutGatewayModel => {
  const transformedConfigParameters = config_parameters?.map((item) => payloadToDeviceWithoutGatewayParameter(item)) ?? [];
  const transformedSensors = sensors.map((item) => payloadToDeviceWithoutGatewaySensor(item)) ?? [];
  const transformedExtraInformation = extra_information?.map((item) => payloadToDeviceTypeWithoutGatewayExtraInformation(item)) ?? [];

  return {
    configParameters: transformedConfigParameters,
    deviceType: device_type ?? '',
    deviceUuid: device_uuid ?? '',
    name: name ?? '',
    room: room ?? '',
    serialNumber: serial_number ?? '',
    assetId: asset_id ?? '',
    sensors: transformedSensors,
    extraInformation: transformedExtraInformation,
  };
};

export const payloadToDeviceDetailWithoutGatewayResponse = ({
  data,
}: DeviceWithoutGatewayResponsePayload): DeviceWithoutGatewayResponseModel => {
  const transformedData = payloadToDeviceDetailWithoutGateway(data);

  return {
    data: transformedData,
  };
};

const payloadToDeviceSensor = ({
  access_sensor_id,
  device_id,
  name,
  room,
  sensor_id,
  sensor_type,
  sensor_uuid,
  usage,
}: DeviceSensorPayload): DeviceSensorModel => {
  return {
    accessSensorId: access_sensor_id ?? '',
    deviceId: device_id ?? '',
    name: name ?? '',
    room: room ?? '',
    sensorId: sensor_id ?? '',
    sensorType: sensor_type ?? '',
    sensorUuid: sensor_uuid ?? '',
    usage: usage ?? null,
  };
};

const payloadToDevice = ({
  asset_id,
  available_actions,
  battery_level,
  connectivity,
  device_id,
  device_name,
  device_type,
  enabled,
  gateway_uuid,
  parameters,
  project_id,
  sensors,
  shared,
  uuid,
  updated_at,
}: DevicePayload): DeviceModel => {
  const transformedSensors = sensors.map((sensor) => payloadToDeviceSensor(sensor)) ?? [];

  return {
    assetId: asset_id ?? null,
    availableActions: available_actions ?? [],
    batteryLevel: battery_level ?? null,
    connectivity: connectivity ?? null,
    deviceId: device_id ?? '',
    deviceName: device_name ?? '',
    deviceType: device_type ?? '',
    enabled: enabled ?? false,
    gatewayUuid: gateway_uuid ?? null,
    parameters: parameters ?? [],
    projectId: project_id ?? null,
    sensors: transformedSensors,
    shared: shared ?? false,
    uuid: uuid ?? '',
    updatedAt: updated_at ?? null,
  };
};

const payloadToDevicesListResponseMeta = ({ all_results, page_size, results, total_pages }: DeviceMetaPayload): DeviceMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? Number(TABLE_PAGINATION_SIZE_DEFAULT),
    results: results ?? 0,
    totalPages: total_pages ?? 1,
  };
};

const payloadToDevicesListResponseLinks = ({ first, last, next, prev, self }: DeviceLinksPayload): DeviceLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToDevicesListResponse = ({ data, links, meta }: DevicesResponsePayload): DevicesResponseModel => {
  const transformedData = data.map((item) => payloadToDevice(item)) ?? [];
  const transformedMeta = payloadToDevicesListResponseMeta(meta);
  const transformedLinks = payloadToDevicesListResponseLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};
