import { FC } from 'react';
import { Checkbox } from 'antd';
import { TableFilterOptionModel } from '../../models';
import './styles.scss'

interface Props {
  option: TableFilterOptionModel;
  onChangeFilter: (option: TableFilterOptionModel) => void;
}

export const CheckboxComponent: FC<Props> = ({ option, onChangeFilter }) => {
  return (
    <div className={'TableFilterCheckbox__wrapper'} key={option.key}>
      <Checkbox
        key={option.key}
        checked={option.selected}
        onChange={({ target }) => onChangeFilter({ ...option, selected: target.checked })}
      >
        <p className={`TableFilterCheckbox__text${!option.selected ? '-disabled' : ''}`}>
          {option.label}
        </p>
      </Checkbox>
    </div>
  )
}
