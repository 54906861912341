import { FC } from 'react';
import { Dropdown, Badge } from 'antd';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { FilterModel } from 'utils/globalModels';
import { useFilters } from './resources/useFilters';
import { TableFilterModel } from './models';
import { DropdownContent } from './components/DropdownContent';

interface Props {
  initialFilters: TableFilterModel[];
  onApply: (url: string, filters?: FilterModel) => void;
  loading: boolean;
}

export const TableFilter: FC<Props> = ({ loading, initialFilters, onApply }) => {
  const {
    filters,
    literals,
    numberOfFiltersApplied,
    onApplyFilters,
    onChangeFilter,
    onClickFiltersMenu,
    onResetFilters,
    onCloseFilters,
    resetButtonDisabled,
    visible,
    applyButtonDisabled,
  } = useFilters({ initialFilters, onApply });

  return (
    <Badge count={numberOfFiltersApplied} color={COLORS.corporate} key={'badge'}>
      <Dropdown
        trigger={['click']}
        open={visible}
        onOpenChange={onClickFiltersMenu}
        key={'dropdown'}
        getPopupContainer={(triggerNode) => triggerNode.parentElement!}
        dropdownRender={() => (
          <DropdownContent
            initialFilters={initialFilters}
            filters={filters}
            literals={literals}
            onApplyFilters={onApplyFilters}
            onChangeFilter={onChangeFilter}
            onCloseFilters={onCloseFilters}
            onResetFilters={onResetFilters}
            applyButtonDisabled={applyButtonDisabled}
            resetButtonDisabled={resetButtonDisabled}
          />)}
      >
        <DefaultButton
          loading={loading}
          disabled={loading}
          title={literals.headerButtonText}
          icon={<Icon.Filter />}
          key={'filterButton'}
        />
      </Dropdown>
    </Badge>
  );
};

export default TableFilter;
