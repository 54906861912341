import i18next from 'i18next';
import { Rule, FormInstance } from 'antd/lib/form';
import { DeviceTypeWithoutGatewayDataModel, DeviceWithoutGatewayModel } from 'core/domain/devices/models';

export const DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE = 2;
export const DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE = 3;
export const DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_GENERAL_GROUP_VALUE = 1;
export const DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_SENSOR_GROUP_VALUE = 2;
export const DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_KEY = 'sensor';
export const DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_ROOM_KEY = 'room';
export const DEVICE_WITHOUT_GATEWAY_FORM_CONFIG_PARAMETERS_KEY = 'config_parameters';
export const DEVICE_WITHOUT_GATEWAY_FORM_EXTRA_INFORMATION_KEY = 'extra_information';
export const ONLY_NUMERIC_VALUES_REGEX = /^[0-9]+$/;

export enum DeviceWithoutGatewayGeneralFormValuesNames {
  NAME = 'name',
  ROOM = 'room',
  SERIAL_NUMBER = 'serial_number',
}

export enum DeviceWithoutGatewayFormValuesNames {
  NAME = 'name',
  ROOM = 'room',
  SERIAL_NUMBER = 'serial_number',
  COMMUNICATION_PLATFORM_IP = 'communication_platform_ip',
  COMMUNICATION_PLATFORM_PORT = 'communication_platform_port',
  REPORTING_INTERVAL_TIME = 'reporting_interval_time',
  REPORTING_INTERVAL_NUMBER = 'reporting_interval_number',
  SECRET_KEY = 'secret_key',
  BUILDING_ID = 'building_id',
  COUNT_UP = 'count_up',
  COUNT_UP_ROOM = 'count_up_room',
  COUNT_DOWN = 'count_down',
  COUNT_DOWN_ROOM = 'count_down_room',
  BATTERY = 'battery',
  BATTERY_ROOM = 'battery_room',
}

export enum DeviceWithoutGatewayParsedFormValuesNames {
  NAME = 'name',
  ROOM = 'room',
  SERIAL_NUMBER = 'serial_number',
  COMMUNICATION_PLATFORM_IP = 'config_parameters_communication_platform_ip',
  COMMUNICATION_PLATFORM_PORT = 'config_parameters_communication_platform_port',
  REPORTING_INTERVAL_TIME = 'config_parameters_reporting_interval_time',
  REPORTING_INTERVAL_NUMBER = 'config_parameters_reporting_interval_number',
  SECRET_KEY = 'extra_information_secret_key',
  BUILDING_ID = 'extra_information_building_id',
  COUNT_UP_NAME = 'sensor_count_up_name',
  COUNT_UP_ROOM = 'sensor_count_up_room',
  COUNT_DOWN_NAME = 'sensor_count_down_name',
  COUNT_DOWN_ROOM = 'sensor_count_down_room',
  BATTERY_NAME = 'sensor_battery_name',
  BATTERY_ROOM = 'sensor_battery_room',
}
export interface DeviceWithoutGatewayFormDataModel {
  key: string;
  name: string;
  title?: string;
  label: string;
  placeholder: string;
  value: string;
  units?: string;
  formGroupValue: number;
  required: boolean;
  multiple: boolean;
  type?: string;
  uuid?: string;
  relatedFormKey?: string;
  rules: Rule[];
}

export interface DeviceWithoutGatewayFormLiteralsModel {
  deviceSectionTitle: string;
  sensorSectionTitle: string;
  sensorSectionDescription: string;
  requiredFieldTextLegend: string;
}

export interface DeviceWithoutGatewayFormOptionsModel {
  form: FormInstance<Record<string, string | null>>;
  formValues: DeviceWithoutGatewayFormDataModel[];
  formLiterals: DeviceWithoutGatewayFormLiteralsModel;
  deviceSectionNumber: number;
  sensorSectionNumber: number;
  onFinish: (values: Record<string, string | null>) => void;
  onFinishFailed: () => void;
}

export interface DeviceWithoutGatewayGeneralFormValuesSetModel {
  formValue: DeviceWithoutGatewayFormDataModel;
  data?: DeviceWithoutGatewayModel;
}
export interface DeviceWithoutGatewayFormValuesSetModel {
  formValue: DeviceWithoutGatewayFormDataModel;
  deviceType: DeviceTypeWithoutGatewayDataModel;
  data?: DeviceWithoutGatewayModel;
}

export const getInitialFormValues = (): DeviceWithoutGatewayFormDataModel[] => {
  return [
    {
      key: DeviceWithoutGatewayFormValuesNames.NAME,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_NAME_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.NAME,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_NAME_PLACEHOLDER'),
      required: true,
      multiple: false,
      value: '',
      rules: [{ required: true, message: i18next.t('required_field') }],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.ROOM,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_ROOM_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.ROOM,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_ROOM_PLACEHOLDER'),
      required: true,
      multiple: false,
      value: '',
      rules: [{ required: true, message: i18next.t('required_field') }],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COMMUNICATION_PLATFORM_IP,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_IP_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COMMUNICATION_PLATFORM_IP,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_IP_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      units: '',
      rules: [{ required: true, message: i18next.t('required_field') }],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COMMUNICATION_PLATFORM_PORT,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_PORT_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COMMUNICATION_PLATFORM_PORT,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_PORT_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      units: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.REPORTING_INTERVAL_NUMBER,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_INTERVAL_NUMBER_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.REPORTING_INTERVAL_NUMBER,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_INTERVAL_NUMBER_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      units: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.REPORTING_INTERVAL_TIME,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_INTERVAL_TIME_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.REPORTING_INTERVAL_TIME,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_INTERVAL_TIME_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      units: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.SECRET_KEY,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SECRET_KEY_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.SECRET_KEY,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SECRET_KEY_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.BUILDING_ID,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_BUILDING_ID_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.BUILDING_ID,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_BUILDING_ID_PLACEHOLDER'),
      required: false,
      multiple: false,
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.SERIAL_NUMBER,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SERIAL_NUMBER_LABEL'),
      name: DeviceWithoutGatewayParsedFormValuesNames.SERIAL_NUMBER,
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SERIAL_NUMBER_PLACEHOLDER'),
      required: true,
      multiple: false,
      value: '',
      rules: [{ required: true, message: i18next.t('required_field') }],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COUNT_UP,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_UP_TEXT'),
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_UP_NAME_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_UP_NAME_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COUNT_UP_NAME,
      required: false,
      multiple: false,
      type: '',
      relatedFormKey: DeviceWithoutGatewayParsedFormValuesNames.COUNT_UP_ROOM,
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COUNT_UP_ROOM,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: '',
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_UP_ROOM_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_UP_ROOM_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COUNT_UP_ROOM,
      required: false,
      multiple: false,
      type: '',
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COUNT_DOWN,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_DOWN_TEXT'),
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_DOWN_NAME_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_DOWN_NAME_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COUNT_DOWN_NAME,
      required: false,
      multiple: false,
      type: '',
      relatedFormKey: DeviceWithoutGatewayParsedFormValuesNames.COUNT_DOWN_ROOM,
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.COUNT_DOWN_ROOM,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: '',
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_DOWN_ROOM_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_COUNT_DOWN_ROOM_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.COUNT_DOWN_ROOM,
      required: false,
      multiple: false,
      type: '',
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.BATTERY,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_BATTERY_TEXT'),
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_BATTERY_NAME_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_BATTERY_NAME_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.BATTERY_NAME,
      required: false,
      multiple: false,
      type: '',
      relatedFormKey: DeviceWithoutGatewayParsedFormValuesNames.BATTERY_ROOM,
      value: '',
      rules: [],
    },
    {
      key: DeviceWithoutGatewayFormValuesNames.BATTERY_ROOM,
      formGroupValue: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      uuid: '',
      title: '',
      label: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_BATTERY_ROOM_LABEL'),
      placeholder: i18next.t('_DEVICE_WITHOUT_GATEWAY_FORM_SENSOR_BATTERY_ROOM_PLACEHOLDER'),
      name: DeviceWithoutGatewayParsedFormValuesNames.BATTERY_ROOM,
      required: false,
      multiple: false,
      type: '',
      value: '',
      rules: [],
    },
  ];
};

export const getErrorMessageLiterals = () => [
  {
    message: 'Device already exists',
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_DEVICE_ALREADY_EXISTS_MESSAGE'),
  },
  {
    message: 'Device serial number exists for another device',
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_SERIAL_NUMBER_ALREADY_EXISTS_MESSAGE'),
  },
  {
    message: 'Config for external provider not found for Gomez Group Metering',
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_GG_CONFIG_NOT_FOUND_MESSAGE'),
  },
  {
    message: 'You do not have permission to link this device',
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_FORBIDDEN_LINKING_MESSAGE'),
  },
  {
    message: 'Channel not found in payload',
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_CHANNEL_NOT_FOUND_MESSAGE'),
  },
  {
    message: "Property 'building_id' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_BUILDING_ID_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Property 'secret_key' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_SECRET_KEY_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Config parameter 'communication_platform_ip' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_IP_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Config parameter 'communication_platform_port' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_PORT_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Config parameter 'reporting_interval_time' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_INTERVAL_TIME_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Config parameter 'reporting_interval_number' has invalid value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_INTERVAL_NUMBER_INVALID_VALUE_MESSAGE'),
  },
  {
    message: "Property 'building_id' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_BUILDING_ID_REQUIRED_MESSAGE'),
  },
  {
    message: "Property 'secret_key' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_SECRET_KEY_REQUIRED_MESSAGE'),
  },
  {
    message: "Config parameter 'communication_platform_ip' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_IP_REQUIRED_MESSAGE'),
  },
  {
    message: "Config parameter 'communication_platform_port' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_PORT_REQUIRED_MESSAGE'),
  },
  {
    message: "Config parameter 'reporting_interval_time' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_INTERVAL_TIME_REQUIRED_MESSAGE'),
  },
  {
    message: "Config parameter 'reporting_interval_number' is required",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_INTERVAL_NUMBER_REQUIRED_MESSAGE'),
  },
  {
    message: "Serial number must be a numeric value",
    literal: i18next.t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_SERIAL_NUMBER_TYPE_MESSAGE'),
  },
];

