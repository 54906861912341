import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import AlfredDetailTemplate from 'components/template/AlfredDetailTemplate';
import { useAsset } from 'hooks/useAsset';
import AssetMenuExtra from 'components/organisms/AssetMenuExtra';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { RegisterAssetAttributes } from 'models/assets.model';
import { getGatewayStatusGID } from 'services/api/gateways';
import { getAssetWithParamsPathById } from '../App/routes/assets/config';
import HeaderDescription from './HeaderDescription';
import TabUsefulContacts from './Tabs/TabUsefulContacts';
import TabCameras from './Tabs/TabCameras';
import TabConsumption from './Tabs/TabConsumption';
import TabGateway from './Tabs/TabGateway';
import TabsTitles from './Tabs/TabsTitles/';
import TabUsers from './Tabs/TabUsers';
import TabStart from './Tabs/TabStart';
import TabDevices from './Tabs/TabDevices';
import TabEvents from './Tabs/TabEvents';
import TabAccesses from './Tabs/TabAccesses';
import TabDocuments from './Tabs/TabDocuments';
import { DeviceSorterValueType } from 'core/domain/devices/models';
import { SorterOrderType } from 'constants/sort';
import './styles.scss';

export enum AssetTabType {
  START = 'start',
  USERS = 'users',
  DEVICES = 'devices',
  GATEWAY = 'gateway',
  CONSUMPTIONS = 'consumptions',
  CAMERAS = 'cameras',
  EVENTS = 'events',
  ACCESSES = 'accesses',
  DOCUMENTS = 'documents',
  USEFUL_CONTACTS = 'useful-contacts',
}

const AssetPage: FC = () => {
  const { assetId, tab } = useParams<{ assetId: string; tab: string }>();
  const { t } = useTranslation();
  const {
    asset,
    project,
    client,
    deleteModalTitle,
    assetGatewayConnectionStatusTranslations,
    onDeleteAsset,
    onClickDelete,
    onCancelDelete,
    onClickAgree,
    editAsset,
    onClickClientLink,
    onClickProjectLink,
    isLoading,
    isLoadingUpdate,
    isLoadingDelete,
    showActiveUsersInfoModal,
    showLinkedGatewaysInfoModal,
    showConfirmDeleteModal,
    hasPermissionToDeleteAsset,
  } = useAsset();
  const [gatewayId, setGatewayId] = useState<string>('');
  const history = useHistory();

  const getPageTitle = () => {
    if (asset?.alias) return asset.alias;
    return `${asset?.street_type} ${asset?.street_name} ${asset?.block_number}, ${asset?.apartment}`;
  };

  const handleOnClick = (activeTab: AssetTabType) => {
    const options = { assetId, tab: activeTab };
    const paramsOptions = {
      ...options,
      page: TABLE_PAGINATION_PAGE_DEFAULT,
      size: TABLE_PAGINATION_SIZE_DEFAULT,
      sort: `${DeviceSorterValueType.GATEWAY_ID}.${SorterOrderType.ASC}`,
    };
    const route = activeTab === AssetTabType.DEVICES ? paramsOptions : options;
    history.replace(getAssetWithParamsPathById(route));
  };

  const handleOnSubmitEdit = async (values: RegisterAssetAttributes) => {
    await editAsset(values);
  };

  const handleOnDelete = async () => {
    await onDeleteAsset();
  }

  useEffect(() => {
    getGatewayStatusGID(assetId).then(({ gatewayId, gatewayQid }) => {
      setGatewayId(gatewayId);
    });
  }, [assetId]);

  return (
    <AlfredDetailTemplate
      currentTabKey={tab}
      title={getPageTitle()}
      loading={isLoading}
      extra={[
        asset.is_blocked && <Tag>{t('_BLOCKED')}</Tag>,
        asset.id && (
          <AssetMenuExtra
            key={'AssetMenuExtra'}
            asset={asset}
            gatewayId={gatewayId}
            deleteModalTitle={deleteModalTitle}
            onDeleteAsset={handleOnDelete}
            onSubmitEdit={handleOnSubmitEdit}
            onClickDelete={onClickDelete}
            onCancelDelete={onCancelDelete}
            onClickAgree={onClickAgree}
            isEditLoading={isLoadingUpdate}
            isDeleteLoading={isLoadingDelete}
            showActiveUsersInfoModal={showActiveUsersInfoModal}
            showLinkedGatewaysInfoModal={showLinkedGatewaysInfoModal}
            deleteDisabled={!hasPermissionToDeleteAsset}
            maintenanceDisabled={!gatewayId}
            showConfirmDeleteModal={showConfirmDeleteModal}
          />
        ),
      ]}
      description={asset && (
        <HeaderDescription
          assetId={assetId}
          asset={asset}
          client={client}
          project={project}
          assetGatewayConnectionStatusTranslations={assetGatewayConnectionStatusTranslations}
          onClickClientLink={onClickClientLink}
          onClickProjectLink={onClickProjectLink}
        />)}
      tabsTitles={(currentTab) => <TabsTitles onChange={(activeTab: string) => handleOnClick(activeTab as AssetTabType)} activeKey={currentTab} />}
    >
      {[
        { key: AssetTabType.START, render: <TabStart key={AssetTabType.START} /> },
        { key: AssetTabType.USERS, render: <TabUsers key={AssetTabType.USERS} /> },
        { key: AssetTabType.DEVICES, render: <TabDevices key={AssetTabType.DEVICES} /> },
        { key: AssetTabType.GATEWAY, render: <TabGateway key={AssetTabType.GATEWAY} /> },
        { key: AssetTabType.CONSUMPTIONS, render: <TabConsumption key={AssetTabType.CONSUMPTIONS} assetId={assetId} /> },
        { key: AssetTabType.CAMERAS, render: <TabCameras key={AssetTabType.CAMERAS} /> },
        { key: AssetTabType.EVENTS, render: <TabEvents key={AssetTabType.EVENTS} /> },
        { key: AssetTabType.ACCESSES, render: <TabAccesses key={AssetTabType.ACCESSES} /> },
        {
          key: AssetTabType.DOCUMENTS,
          render: <TabDocuments key={AssetTabType.DOCUMENTS} projectId={!!asset.project ? asset.project[0].id : undefined} />,
        },
        { key: AssetTabType.USEFUL_CONTACTS, render: <TabUsefulContacts key={AssetTabType.USEFUL_CONTACTS} assetId={assetId} /> },
      ]}
    </AlfredDetailTemplate>
  );
};

export default AssetPage;
