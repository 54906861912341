import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, deviceId }: { host: string; deviceId: string }) => {
  return `${host}/gateway-cloud/devices/${deviceId}/enable`;
};

export const EnableDevice = async ({
  host,
  token,
  deviceId,
  enabled,
}: {
  host: string;
  token: string;
  deviceId: string;
  enabled: boolean;
}) => {
  try {
    const url = getUrl({ host, deviceId });

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: { enabled } },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
