import i18next from 'i18next';
import {
  TableDefaultFilterOptionsModel,
  TableFilterModel,
  TableFilterSubtypeType,
  TableFilterType,
} from 'components/organisms/TableFilter/models';
import { FilterOperatorsType } from 'constants/filters';
import { DevicePayload } from 'core/domain/devices/models';
import { checkIfOptionIsAlreadySelected } from 'utils/filter';
import { DeviceConnectivityType } from 'constants/devices';

const connectivityKey: keyof DevicePayload = 'connectivity';

export const getFilterOptions = (): TableDefaultFilterOptionsModel[] => {
  return [
    {
      key: connectivityKey,
      operator: FilterOperatorsType.EQUAL,
      value: DeviceConnectivityType.OFFLINE,
      exactValue: true,
    },
    {
      key: connectivityKey,
      operator: FilterOperatorsType.EQUAL,
      value: DeviceConnectivityType.ONLINE,
      exactValue: true,
    },
    {
      key: connectivityKey,
      operator: FilterOperatorsType.EQUAL,
      value: DeviceConnectivityType.UNKNOWN,
      exactValue: true,
    },
  ];
};

export const getInitialFilters = ({
  currentFilters,
  connectivityLiteralsManager,
}: {
  currentFilters: string;
  connectivityLiteralsManager: Record<string, () => string>;
}): TableFilterModel[] => {
  return [
    {
      key: connectivityKey,
      label: i18next.t('_ASSET_DEVICE_COLUMN_CONNECTIVITY_TITLE'),
      type: TableFilterType.CHECKBOX,
      options: [
        {
          key: `${connectivityKey}:${DeviceConnectivityType.OFFLINE}`,
          type: TableFilterType.CHECKBOX,
          subtype: TableFilterSubtypeType.EQUAL,
          label: connectivityLiteralsManager[DeviceConnectivityType.OFFLINE](),
          operator: FilterOperatorsType.EQUAL,
          selected: checkIfOptionIsAlreadySelected({
            key: connectivityKey,
            subKey: TableFilterSubtypeType.EQUAL,
            value: DeviceConnectivityType.OFFLINE,
            currentFilters,
            exactValue: true,
          }),
          defaultValue: DeviceConnectivityType.OFFLINE,
          value: DeviceConnectivityType.OFFLINE,
          error: false,
          errorMessage: '',
        },
        {
          key: `${connectivityKey}:${DeviceConnectivityType.ONLINE}`,
          type: TableFilterType.CHECKBOX,
          subtype: TableFilterSubtypeType.EQUAL,
          label: connectivityLiteralsManager[DeviceConnectivityType.ONLINE](),
          operator: FilterOperatorsType.EQUAL,
          selected: checkIfOptionIsAlreadySelected({
            key: connectivityKey,
            subKey: TableFilterSubtypeType.EQUAL,
            value: DeviceConnectivityType.ONLINE,
            currentFilters,
            exactValue: true,
          }),
          defaultValue: DeviceConnectivityType.ONLINE,
          value: DeviceConnectivityType.ONLINE,
          error: false,
          errorMessage: '',
        },
        {
          key: `${connectivityKey}:${DeviceConnectivityType.UNKNOWN}`,
          type: TableFilterType.CHECKBOX,
          subtype: TableFilterSubtypeType.EQUAL,
          label: connectivityLiteralsManager[DeviceConnectivityType.UNKNOWN](),
          operator: FilterOperatorsType.EQUAL,
          selected: checkIfOptionIsAlreadySelected({
            key: connectivityKey,
            subKey: TableFilterSubtypeType.EQUAL,
            value: DeviceConnectivityType.UNKNOWN,
            currentFilters,
            exactValue: true,
          }),
          defaultValue: DeviceConnectivityType.UNKNOWN,
          value: DeviceConnectivityType.UNKNOWN,
          error: false,
          errorMessage: '',
        },
      ],
    },
  ];
};
