import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { notificationToPayloadSendNotification } from '../mappers/notificationToPayloadSendNotification';
import { SendNotificationModel } from '../model';

const getUrl = (host: string) => {
  return `${host}/notifications/send`;
};

export const SendNotification = async ({
  token,
  host,
  notification,
}: {
  token: string;
  host: string;
  notification: SendNotificationModel;
}): Promise<void> => {
  try {
    const url = getUrl(host);

    const mappedData = notificationToPayloadSendNotification(notification);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
