import { FC } from 'react';
import DrawerAtom, { DrawerAtomProps } from 'components/atoms/DrawerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DeviceWithoutGatewayForm from 'components/pages/AssetPage/Tabs/TabDevices/resources/Form';
import { UpdateDeviceOptionsModel } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useUpdateDeviceWithoutGateway';
import './styles.scss';

export interface Props extends DrawerAtomProps {
  options: UpdateDeviceOptionsModel;
}

export const UpdateDeviceWithoutGatewayDrawer: FC<Props> = ({ open, onClose, title, options }) => {
  const {
    literals,
    formOptions,
    formType,
    onUpdateDevice,
    loading,
    updating,
  } = options;

  return (
    <DrawerAtom
      className={'UpdateDeviceWithoutGatewayDrawer__wrapper'}
      open={open}
      onClose={onClose}
      size={'large'}
      title={<span className={'UpdateDeviceWithoutGatewayDrawer__title'}>{title}</span>}
    >
      <main className={'UpdateDeviceWithoutGatewayDrawer__content-wrapper'}>
        <div className={'UpdateDeviceWithoutGatewayDrawer__form'}>
          {!!formType && <DeviceWithoutGatewayForm options={formOptions} loading={loading} />}
        </div>
      </main>
      <footer>
        <DefaultButton onClick={onClose} title={literals.secondaryButtonText} />
        <PrimaryButton loading={updating} title={literals.mainButtonText} onClick={onUpdateDevice} disabled={loading} />
      </footer>
    </DrawerAtom>
  );
}