import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { GetDevicesTypesListWithoutGateway } from 'core/domain/devices/repositories/getDevicesTypesListWithoutGateway';
import { CreateDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/createDeviceWithoutGateway';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { DeviceTypeWithoutGatewayDataModel } from 'core/domain/devices/models';
import { useDeviceWithoutGatewayForm } from './Form/resources/useDeviceWithoutGatewayForm';
import {
  DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
  DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
  DeviceWithoutGatewayFormDataModel,
  DeviceWithoutGatewayFormOptionsModel,
  getErrorMessageLiterals,
} from './Form/utils';

export interface CreateDeviceOptionsLiteralsModel {
  deviceActionButtonText: string;
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
  infoText: string;
  typeSectionTitle: string;
  deviceTypeLabel: string;
  deviceTypePlaceholder: string;
}

export interface CreateDeviceOptionsModel {
  literals: CreateDeviceOptionsLiteralsModel;
  devicesTypes: DeviceTypeWithoutGatewayDataModel[];
  formType: string | null;
  formOptions: DeviceWithoutGatewayFormOptionsModel;
  onOpenCreateDeviceDrawer: () => void;
  onCloseCreateDeviceDrawer: () => void;
  onChangeDeviceType: (value: string) => void;
  onAddDevice: () => void;
  createDeviceDrawerVisible: boolean;
  loading: boolean;
  hasDevicesTypes: boolean;
  creating: boolean;
  submitButtonDisabled: boolean;
}

enum CreateDeviceErrorCodeType {
  CONFLICTS = 400,
  PAYLOAD_ERROR = 422,
}

export const useCreateDeviceWithoutGateway = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [devicesTypes, setDevicesTypes] = useState<DeviceTypeWithoutGatewayDataModel[]>([]);
  const [formType, setFormType] = useState<string | null>(null);
  const [creating, setCreating] = useState<boolean>(false);
  const [successCreatingDevice, setSuccessCreatingDevice] = useState<boolean>(false);
  const [isSubmitButtonDisable, setIsSubmitButtonDisabled] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<DeviceWithoutGatewayFormDataModel[]>([]);
  const { form, formLiterals, getFormValues, resetFormFields, createFormStructure, formValuesToPayload, submitForm } =
    useDeviceWithoutGatewayForm();

  const literals: CreateDeviceOptionsLiteralsModel = {
    deviceActionButtonText: t('add'),
    title: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    mainButtonText: t('add'),
    secondaryButtonText: t('cancel'),
    infoText: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_INFO_MESSAGE'),
    typeSectionTitle: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    deviceTypeLabel: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_LABEL'),
    deviceTypePlaceholder: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_PLACEHOLDER'),
  };

  const hasDevicesTypes = !!devicesTypes.length;

  const resetValues = () => {
    setFormType(null);
    setCreating(false);
    resetFormFields();
    setIsSubmitButtonDisabled(true);
  };

  const onOpenCreateDeviceDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseCreateDeviceDrawer = () => {
    resetValues();
    setDrawerVisible(false);
  };

  const onChangeDeviceType = (value: string) => {
    setFormType(value);
    resetFormFields();
    const parsedFormValues = createFormStructure({ value, devicesTypes });
    setFormValues(parsedFormValues);
    setIsSubmitButtonDisabled(false);
  };

  const onAddDevice = () => {
    submitForm();
    setCreating(true);
  };

  const getDevicesTypes = async () => {
    try {
      setLoading(true);
      const { data } = await GetDevicesTypesListWithoutGateway({
        host,
        token,
        pageNumber: Number(TABLE_PAGINATION_PAGE_DEFAULT),
        pageSize: Number(TABLE_PAGINATION_SIZE_DEFAULT),
      });
      setDevicesTypes(data);
    } catch (error) {
      setMessageError({ description: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const getConflictErrorLiteralFromMessage = (message: string): string => {
    const errorMessageLiterals = getErrorMessageLiterals();
    const currentErrorMessage = errorMessageLiterals.find((errorLiteral) => message.includes(errorLiteral.message));

    return currentErrorMessage ? currentErrorMessage.literal : t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');
  };

  const controlledErrorDescriptionManager = {
    [CreateDeviceErrorCodeType.CONFLICTS]: getConflictErrorLiteralFromMessage,
    [CreateDeviceErrorCodeType.PAYLOAD_ERROR]: () => t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_422_MESSAGE'),
  };

  const getErrorLiteral = (error: any) => {
    const controlledErrors = Object.values(CreateDeviceErrorCodeType);
    const isControlledError = controlledErrors.includes(error.code);
    const description = isControlledError
      ? controlledErrorDescriptionManager[error.code as CreateDeviceErrorCodeType](error.message.detail)
      : t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');

    setMessageError({ description });
  };

  const onFinish = () => {
    createDevice();
  };

  const onFinishFailed = () => {
    setCreating(false);
  };

  const createDevice = async () => {
    try {
      const values = getFormValues();
      const device = formValuesToPayload({ assetId, formType, values, formValues });
      await CreateDeviceDetailWithoutGateway({ host, token, device });
      setMessageSuccess({ description: t('_CREATE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      setSuccessCreatingDevice(true);
      onCloseCreateDeviceDrawer();
    } catch (error) {
      getErrorLiteral(error);
    } finally {
      setCreating(false);
      setSuccessCreatingDevice(false);
    }
  };

  const createDeviceOptions: CreateDeviceOptionsModel = {
    literals,
    devicesTypes,
    formType,
    formOptions: {
      form,
      formValues,
      formLiterals,
      deviceSectionNumber: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_GENERAL_GROUP_VALUE,
      sensorSectionNumber: DEVICE_WITHOUT_GATEWAY_CREATE_FORM_SENSOR_GROUP_VALUE,
      onFinish,
      onFinishFailed,
    },
    onOpenCreateDeviceDrawer,
    onCloseCreateDeviceDrawer,
    onChangeDeviceType,
    onAddDevice,
    createDeviceDrawerVisible: drawerVisible,
    loading,
    hasDevicesTypes,
    creating,
    submitButtonDisabled: isSubmitButtonDisable,
  };

  useEffect(() => {
    getDevicesTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { createDeviceOptions, successCreatingDevice };
};
