import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabTemplate from 'components/template/TabTemplate';
import DevicesTabColumns from './DevicesTabColumns';
import { useSharedGateways } from 'hooks/useSharedGateways';
import TableAtom from 'components/atoms/TableAtom';
import { getSharedGatewayPathByGatewayId, SharedGatewayTabType } from 'components/pages/App/routes/projects/config';
import { ProjectSharedGatewayModel } from 'core/domain/project/model/projectModel';

interface TabGatewaysProps {
  loading: boolean;
}

const TabGateways: FC<TabGatewaysProps> = ({ loading }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const devicesTabColumns = DevicesTabColumns();
  const { data } = useSharedGateways();
  const history = useHistory();

  const title = t('_GATEWAYS_SHARED_TAB_TABLE_TITLE');

  const handleRowClick = (gateway: ProjectSharedGatewayModel) => {
    return history.push(getSharedGatewayPathByGatewayId({ projectId, gatewayId: gateway.id, tab: SharedGatewayTabType.INFO }));
  };

  return (
    <TabTemplate>
      <h2>{title}</h2>
      <TableAtom<ProjectSharedGatewayModel>
        loading={loading}
        columns={devicesTabColumns}
        pagination={false}
        onRow={(item) => {
          return {
            onClick: () => handleRowClick(item),
          };
        }}
        dataSource={data}
      />
    </TabTemplate>
  );
};

export default TabGateways;
