import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSensorsAsset } from 'hooks/useSensorsAsset';
import { useAssetConsumption } from 'hooks/useAssetConsumption';
import DownloadAssetDataCsv from 'core/domain/assets/repositories/downloadAssetDataCsv';
import { SensorEntityModel } from 'core/domain/sensors/models/sensorEntityModels';
import { downloadCsvFile } from 'utils/csv';
import { AssetConsumptionsFormTranslationsType, AssetConsumptionsFormValues } from './models';

const getLastWeek = () => moment().subtract(7, 'days');

const MONTH_PERIOD = 'month';
const ASSETS_CONSUMPTIONS_CSV = 'assets_consumptions.csv';

export const useChartConsumption = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [form] = Form.useForm<any>();
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>([getLastWeek(), moment()]);
  const [areThereData, setAreThereData] = useState<boolean>(false);
  const { data, selectedSensor, getSensorById, onSelectSensorAsset } = useSensorsAsset(assetId, false);
  const { data: allData, dataPresence, onGetSensorAssetConsumption, setAllData, loading } = useAssetConsumption();

  const isOnSubmitButtonEnabled = selectedSensor && dateRange;

  const initialAssetConsumptionFormValues: AssetConsumptionsFormValues = {
    sensor: '',
    timeRange: [getLastWeek(), moment()],
    month: '',
  };

  const initialSensorText: string = t('SELECT_A_SENSOR');
  const noDataText: string = t('no_data');
  const formTranslations: AssetConsumptionsFormTranslationsType = {
    initialDateText: t('_CONSUMPTION_INITIAL_SENSOR_PLACEHOLDER'),
    textButton: t('_CONSUMPTION_TEXT_BUTTON'),
    secondaryTextButton: t('download_csv'),
  };

  const disabledDate = (current: Moment): boolean => current && current >= moment();

  const disabledDateMonth = (current: Moment): boolean => current && current >= moment().endOf(MONTH_PERIOD);

  const onChangeCalendar = (values: [Moment, Moment] | null): void => {
    if (!!values && !!values[0] && !!values[1]) {
      form.setFieldsValue({ timeRange: values });
      setDateRange(values);
    }
    !values && setDateRange(null);
  };

  const onDownloadCSV = (): void => {
    if (selectedSensor && dateRange) {
      const props = {
        assetId,
        sensor_uuid: selectedSensor.id,
        from: moment(dateRange[0]).unix(),
        until: moment(dateRange[1]).unix(),
      };

      DownloadAssetDataCsv(props).then((csv) => {
        return downloadCsvFile(csv, ASSETS_CONSUMPTIONS_CSV);
      });
    }
  };

  const onSelectSensor = (sensor: SensorEntityModel): void => {
    onSelectSensorAsset(sensor);
    form.setFieldsValue({ sensor: sensor.id });
    setAllData(undefined);
  };

  const onGetSensorById = (id: string): SensorEntityModel => {
    return getSensorById(id);
  };

  const onChangeMonth = (value: Moment | null): void => {
    if (!!value) {
      const timeRange: [Moment, Moment] = [moment(value).startOf(MONTH_PERIOD), moment(value).endOf(MONTH_PERIOD)];
      setDateRange(timeRange);
      form.setFieldsValue({ timeRange });
    }
  };

  const onSubmit = () => {
    selectedSensor &&
      dateRange &&
      onGetSensorAssetConsumption({
        sensorId: selectedSensor.sensorId,
        sensor: selectedSensor.id,
        dateRange,
        assetId,
        deviceId: selectedSensor.deviceId,
        gatewayId: selectedSensor.gatewayId,
      });
  };

  useEffect(() => {
    selectedSensor && allData && !!allData.data.length && setAreThereData(true);
    selectedSensor && allData && !allData.data.length && setAreThereData(false);
  }, [allData, selectedSensor]);

  return {
    sensors: data,
    dateRange,
    dataPresence,
    selectedSensor,
    consumptionsData: allData,
    formTranslations,
    initialSensorText,
    noDataText,
    Form,
    form,
    initialAssetConsumptionFormValues,
    disabledDate,
    disabledDateMonth,
    onDownloadCSV,
    onSelectSensor,
    onGetSensorById,
    onChangeCalendar,
    onChangeMonth,
    onSubmit,
    areThereConsumptionData: areThereData,
    isLoadingConsumptions: loading,
    isOnSubmitButtonEnabled,
  };
};
