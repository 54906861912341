import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConditionModel, FilterModel } from 'utils/globalModels';
import { FilterLogicsType, FilterOperatorsType } from 'constants/filters';
import { compareOperatorsManager } from 'utils/operators';
import { rangeFilterTypes, TableFilterModel, TableFilterOptionModel, TableFilterSubtypeType, TableFilterType } from '../models';

export interface TableFilterChangeModel {
  option: TableFilterOptionModel;
  value?: string;
}

export interface TableFilterErrorOptionModel {
  option: TableFilterOptionModel;
  relatedOption: TableFilterOptionModel;
  operator: FilterOperatorsType;
  errorMessage: string;
}

interface Props {
  initialFilters: TableFilterModel[];
  onApply: (url: string, newFilters?: FilterModel) => void;
}

export const useFilters = ({ initialFilters, onApply }: Props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<TableFilterOptionModel[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState<boolean>(false);

  const numberOfFiltersApplied = filters.filter((currentFilter) => currentFilter.selected).length;
  const resetButtonDisabled = numberOfFiltersApplied === 0;

  const literals: Record<string, string> = {
    headerButtonText: t('_FILTER_HEADER_BUTTON_TEXT'),
    footerCloseButtonText: t('close'),
    footerMainButtonText: t('_FILTER_FOOTER_MAIN_BUTTON_TEXT'),
    footerSecondaryButtonText: t('_FILTER_FOOTER_SECONDARY_BUTTON_TEXT'),
    sliderInitialValueLabel: t('_FROM'),
    sliderFinalValueLabel: t('_UNTIL'),
  };

  const checkOptionErrors = ({ option, relatedOption, operator, errorMessage }: TableFilterErrorOptionModel): TableFilterOptionModel[] => {
    const value = option.value !== null ? Number(option.value) : Number(option.defaultValue);
    const relatedValue = relatedOption.value !== null ? Number(relatedOption.value) : Number(relatedOption?.defaultValue);
    const isValueCorrect = option.selected && relatedOption.selected ? compareOperatorsManager[operator](value, relatedValue) : true;
    const resetOption: TableFilterOptionModel = { ...option, error: false, errorMessage: '' };
    const errorOption: TableFilterOptionModel = { ...option, error: true, errorMessage };
    const resetRelatedOption: TableFilterOptionModel = { ...relatedOption, error: false, errorMessage: '' };

    return isValueCorrect ? [resetOption, resetRelatedOption] : [errorOption, resetRelatedOption];
  };

  const getRangeRelatedOption = (option: TableFilterOptionModel, currentFilters: TableFilterOptionModel[]): TableFilterOptionModel => {
    const relatedBaseKey = option.key.split(':');
    return currentFilters.filter((filter) => {
      const filterBaseKey = filter.key.split(':');
      return relatedBaseKey[0] === filterBaseKey[0] && relatedBaseKey[1] !== filterBaseKey[1];
    })[0];
  };

  const checkRangeErrors = (option: TableFilterOptionModel, currentFilters: TableFilterOptionModel[]): TableFilterOptionModel[] => {
    const relatedOption = getRangeRelatedOption(option, currentFilters);
    const isMinOption = option.subtype === TableFilterSubtypeType.MIN || option.subtype === TableFilterSubtypeType.MIN_OR_EQUAL;
    const operator = isMinOption ? FilterOperatorsType.LESS_THAN : FilterOperatorsType.GREATER_THAN;
    const errorMessage = isMinOption ? t('_FILTER_SUBTYPE_MIN_ERROR_MESSAGE') : t('_FILTER_SUBTYPE_MAX_ERROR_MESSAGE');

    return checkOptionErrors({ option, relatedOption, operator, errorMessage });
  };

  const validateCheckboxOption = (option: TableFilterOptionModel) => {
    return filters.map((filterOption) => {
      const isSelectedOption = filterOption.key === option.key;
      const isCheckboxOption = filterOption.type === TableFilterType.CHECKBOX;
      const parsedFilterOption = isCheckboxOption ? { ...filterOption, selected: false } : option;
      return isSelectedOption ? option : parsedFilterOption;
    });
  };

  const onChangeFilter = (option: TableFilterOptionModel) => {
    const validatedFilters = validateCheckboxOption(option);
    const isRangeType = rangeFilterTypes.includes(option.type);
    const checkedFilters = isRangeType ? checkRangeErrors(option, validatedFilters) : [option];
    const parsedFilters = validatedFilters.map((filter) => {
      const selectedFilter = checkedFilters.find((checkedFilter) => checkedFilter.key === filter.key);
      return !!selectedFilter ? selectedFilter : filter;
    });

    setFilters(parsedFilters);
  };

  const parseFiltersToBeSent = (): FilterModel => {
    const conditions: FilterConditionModel[] = filters
      .filter((filter) => filter.selected)
      .map(({ operator, value, key, defaultValue }) => {
        const parsedDefaultValue = !!defaultValue ? defaultValue : '';
        const parsedValue = !!value ? value : parsedDefaultValue;

        return { field: key.split(':')[0], operator, value: parsedValue };
      });

    return { logic: FilterLogicsType.AND, conditions };
  };

  const getUrlFilters = (parsedFilters: FilterModel): string => {
    return parsedFilters.conditions
      .map((condition) => ('field' in condition ? `${condition.field}${condition.operator}${condition.value}` : ''))
      .join(parsedFilters.logic);
  };

  const sendFilters = () => {
    const parsedFilters = parseFiltersToBeSent();
    const url = getUrlFilters(parsedFilters);
    onApply(url, parsedFilters);
  };

  const onApplyFilters = () => {
    setIsVisible(false);
    sendFilters();
  };

  const onResetFilters = () => {
    const parsedFilters = filters.map((filter) => ({
      ...filter,
      selected: false,
      value: '',
      error: false,
      errorMessage: '',
    }));
    setFilters(parsedFilters);
  };

  const onCloseFilters = () => {
    setIsVisible(false);
  };

  const onClickFiltersMenu = () => {
    setIsVisible(!isVisible);
  };

  const getInitialDataErrors = (initialOptions: TableFilterOptionModel[]): TableFilterOptionModel[] => {
    const initialCheckedOptions = initialOptions
      .filter((initialOption) => {
        const isRangeType = initialOption.type === TableFilterType.DATE_RANGE || initialOption.type === TableFilterType.RANGE;
        const isMinOption =
          initialOption.subtype === TableFilterSubtypeType.MIN || initialOption.subtype === TableFilterSubtypeType.MIN_OR_EQUAL;
        return isRangeType && isMinOption;
      })
      .map((initialCheckableErrorOption) => checkRangeErrors(initialCheckableErrorOption, initialOptions))
      .flat();

    return initialOptions.map((initialOption) => {
      const parsedErrorOption = initialCheckedOptions.find((errorOption) => errorOption.key === initialOption.key);
      return !!parsedErrorOption ? parsedErrorOption : initialOption;
    });
  };

  const getData = (initialFilters: TableFilterModel[]) => {
    const initialFilteredOptions = initialFilters.map((initialFilter) => initialFilter.options).flat();
    const initialCheckedOptions = getInitialDataErrors(initialFilteredOptions);
    setFilters(initialCheckedOptions);
  };

  useEffect(() => {
    !!initialFilters.length && getData(initialFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  useEffect(() => {
    const hasErrors = filters.some((checkedFilter) => !!checkedFilter.error);
    setIsApplyButtonDisabled(hasErrors);
  }, [filters]);

  return {
    filters,
    numberOfFiltersApplied,
    literals,
    onClickFiltersMenu,
    onChangeFilter,
    onApplyFilters,
    onResetFilters,
    onCloseFilters,
    resetButtonDisabled,
    visible: isVisible,
    applyButtonDisabled: isApplyButtonDisabled,
  };
};
