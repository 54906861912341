import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectTab } from 'models/project.model';
import { useProject } from 'hooks/useProject/useProject';
import TabsTitles from './Tabs/TabsTitles';
import TabCamera from './Tabs/TabCamera';
import TabAssets from './Tabs/TabAssets';
import TabDocuments from './Tabs/TabDocuments';
import TabServices from './Tabs/TabServices';
import AlfredDetailTemplate from 'components/template/AlfredDetailTemplate';
import HeaderDescription from './HeaderDescription';
import TabGateways from './Tabs/TabGateways';
import SubTabCommonAreas from './Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings';
import SubTabUsefulContacts from './Tabs/TabServices/Subtabs/SubtabUsefulContacts';
import SubTabEventsAndNews from './Tabs/TabServices/Subtabs/SubtabEventsAndNews';
import { ProjectServiceSubTabs } from './Tabs/TabServices/Subtabs/constants';
import TabCustomization from './Tabs/TabCustomization';
import { ProjectCustomizationSubTabs } from './Tabs/TabCustomization/SubTabs/constants';
import SubTabAppAndWebapp from './Tabs/TabCustomization/SubTabs/SubTabAppAndWebApp';
import ProjectNotificationModal from 'components/organisms/ProjectNotificationModal';
import ProjectMenuExtra from 'components/organisms/ProjectMenuExtra';
import ProjectEditModal from 'components/organisms/ProjectEditModal';
import { useProjectNotification } from './resources/useProjectNotification';
import { useProjectNavigation } from './resources/useProjectNavigation';
import TabIntercoms from './Tabs/TabIntercoms';
import { TabLayoutConfig } from './Tabs/TabLayoutConfig';
import TabDevices from './Tabs/TabDevices';
import './styles.scss';

const ProjectPage = () => {
  const { t } = useTranslation();
  const { projectId, tab, subTab } = useParams<{ projectId: string; tab: ProjectTab; subTab: ProjectServiceSubTabs }>();
  const { onClickTab, onClickCustomizationSubTab, onClickServicesSubTab } = useProjectNavigation();
  const { onOpenNotificationModal, onCloseNotificationModal, notificationModalVisible, hasNotificationPermission } = useProjectNotification();
  const { data, loadingData, contextualMenuTranslations, formData, modal, header, onClickClientLink } = useProject();
  const { editModalTranslations, onOpenEditModal, onCloseEditModal, editModalVisible } = modal;
  const { headerDescriptionTranslations, projectHeaderDescription } = header;

  return (
    <>
      <AlfredDetailTemplate
        key={projectId}
        currentTabKey={tab}
        title={data?.name || t('not_reported')}
        logo={data?.logo}
        loading={loadingData}
        extra={
          <ProjectMenuExtra
            key={'ProjectMenuExtra'}
            onClickEditButton={onOpenEditModal}
            onClickNotifyButton={onOpenNotificationModal}
            NotifyButtonEnabled={hasNotificationPermission}
            translations={contextualMenuTranslations}
          />
        }
        description={data && (
          <HeaderDescription
            data={projectHeaderDescription}
            translations={headerDescriptionTranslations}
            onClickClientLink={onClickClientLink}
          />
        )}
        tabsTitles={(currentTab) =>
          <TabsTitles
            activeKey={currentTab}
            onChange={(activeTab: string) => onClickTab(activeTab as ProjectTab)}
          />}
      >
        {[
          { key: ProjectTab.ASSETS, render: <TabAssets key={ProjectTab.ASSETS} /> },
          { key: ProjectTab.CAMERAS, render: <TabCamera key={ProjectTab.CAMERAS} /> },
          { key: ProjectTab.DOCUMENTS, render: <TabDocuments key={ProjectTab.DOCUMENTS} /> },
          { key: ProjectTab.GATEWAYS, render: <TabGateways key={ProjectTab.GATEWAYS} loading={loadingData} /> },
          {
            key: ProjectTab.SERVICES,
            render: (
              <TabServices key={ProjectTab.SERVICES} subTab={subTab} onSubTab={onClickServicesSubTab}>
                {[
                  {
                    key: ProjectServiceSubTabs.USEFUL_CONTACTS,
                    render: <SubTabUsefulContacts key={ProjectServiceSubTabs.USEFUL_CONTACTS} projectId={projectId} />,
                  },
                  {
                    key: ProjectServiceSubTabs.NEWS,
                    render: <SubTabEventsAndNews key={ProjectServiceSubTabs.NEWS} projectId={projectId} />,
                  },
                  {
                    key: ProjectServiceSubTabs.COMMON_AREA,
                    render: <SubTabCommonAreas key={ProjectServiceSubTabs.COMMON_AREA} projectId={projectId} />,
                  },
                ]}
              </TabServices>
            ),
          },
          {
            key: ProjectTab.CUSTOMIZATION, render: (
              <TabCustomization key={ProjectTab.CUSTOMIZATION} subTab={subTab} onSubTabClick={onClickCustomizationSubTab}>
                {[
                  {
                    key: ProjectCustomizationSubTabs.APP_AND_WEBAPP,
                    render: <SubTabAppAndWebapp key={ProjectCustomizationSubTabs.APP_AND_WEBAPP} />,
                  },
                ]}
              </TabCustomization>
            ),
          },
          { key: ProjectTab.INTERCOMS, render: <TabIntercoms key={ProjectTab.INTERCOMS} /> },
          { key: ProjectTab.LAYOUT, render: <TabLayoutConfig key={ProjectTab.LAYOUT} /> },
          { key: ProjectTab.DEVICES, render: <TabDevices key={ProjectTab.DEVICES} /> },
        ]}
      </AlfredDetailTemplate >
      <ProjectNotificationModal
        open={notificationModalVisible}
        onCancel={onCloseNotificationModal}
        onOk={onCloseNotificationModal}
        projectId={projectId}
      />
      <ProjectEditModal
        modalTranslations={editModalTranslations}
        open={editModalVisible}
        onCancel={onCloseEditModal}
        formData={formData}
        allowedUsersToVerifyAccounts={data.allowedUsersToVerifyAccounts}
      />
    </>
  );
};

export default ProjectPage;
