import { FC } from 'react';
import { Checkbox, InputNumber } from 'antd';
import { TableFilterOptionModel, TableFilterSubtypeType } from '../../models';
import {
  DEFAULT_FILTER_INPUT_NUMBER_MAX_VALUE,
  DEFAULT_FILTER_INPUT_NUMBER_MIN_VALUE,
  FILTER_INPUT_NUMBER_MAX_LABEL_LENGTH,
} from 'constants/filters';
import './styles.scss';

interface Props {
  option: TableFilterOptionModel;
  onChangeFilter: (option: TableFilterOptionModel) => void;
}

export const NumericInputComponent: FC<Props> = ({ option, onChangeFilter }) => {
  const isFullWidth = option.label.length >= FILTER_INPUT_NUMBER_MAX_LABEL_LENGTH;
  const minValue = !!option.min ? option.min : DEFAULT_FILTER_INPUT_NUMBER_MIN_VALUE;
  const maxValue = !!option.max ? option.max : DEFAULT_FILTER_INPUT_NUMBER_MAX_VALUE;
  const subtypeValue = (option.subtype === TableFilterSubtypeType.MAX || option.subtype === TableFilterSubtypeType.MAX_OR_EQUAL) ? maxValue : minValue;
  const defaultValue = !!option.defaultValue ? option.defaultValue : subtypeValue;
  const parsedValue = !!option.value ? option.value : defaultValue;

  return (
    <div className={'TableFilterInputNumber__wrapper'} key={option.key}>
      <div className={'TableFilterInputNumber__wrapper-content'}>
        <div className={`TableFilterInputNumber__wrapper-content-selector${isFullWidth ? '-full' : ''}`}>
          <div className={'TableFilterInputNumber__selector'}>
            <Checkbox
              key={option.key}
              checked={option.selected}
              onChange={({ target }) => onChangeFilter({ ...option, selected: target.checked, value: String(parsedValue) })}
            >
              <p className={`TableFilterInputNumber__text${!option.selected ? '-disabled' : ''}`}>
                {option.label}
              </p>
            </Checkbox>
          </div>
          <div className={`TableFilterInputNumber__input-wrapper${isFullWidth ? '-full' : ''}`}>
            <InputNumber
              size={'small'}
              value={Number(parsedValue)}
              defaultValue={Number(defaultValue)}
              onChange={(value) => onChangeFilter({ ...option, value: value !== null ? String(value) : null })}
              disabled={!option.selected}
              min={minValue}
              max={maxValue}
              status={option.error ? 'error' : ''}
            />
          </div>
        </div>
      </div>
      <p className={'TableFilterInputNumber__errorMessage'}>{option.errorMessage}</p>
    </div>
  )
}
