import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface SendNotificationModalProps {
  formId: string;
  literals: Record<string, string>;
  onCancel: () => void;
  visible: boolean;
  loading: boolean;
  children?: React.ReactNode;
}

export const SendNotificationModal: FC<SendNotificationModalProps> = ({
  formId,
  literals,
  onCancel,
  visible,
  loading,
  children
}) => {
  const {
    modalTitle,
    modalCancelButton,
    modalSendButton
  } = literals;

  return (
    <ModalAtom
      title={modalTitle}
      onCancel={onCancel}
      footer={[
        <DefaultButton
          key={'back'}
          onClick={onCancel}
          title={modalCancelButton}
        />,
        <PrimaryButton
          key={'submit'}
          type={'primary'}
          loading={loading}
          htmlType={'submit'}
          form={formId}
          title={modalSendButton}
          disabled={loading}
        />,
      ]}
      open={visible}
      destroyOnClose
    >
      {children}
    </ModalAtom>
  );
};

