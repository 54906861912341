import { ROUTE_PATH_ADMIN } from '../../configRoutes';
import { ProjectTab } from 'models/project.model';

interface SharedGatewayPathProps {
  projectId: string;
  gatewayId: string;
  tab: string;
  page?: string;
  size?: string;
  sort?: string;
  filters?: string;
}

interface ProjectPathProps {
  projectId: string;
  tab?: ProjectTab;
  subTab?: string;
}

export enum SharedGatewayTabType {
  INFO = 'info',
  DEVICES = 'devices',
}

export const ROUTE_PATH_PROJECTS = ROUTE_PATH_ADMIN + '/projects';
export const ROUTE_PATH_PROJECTS_NOTIFY = ROUTE_PATH_ADMIN + '/projects/notify';
export const ROUTE_PATH_GATEWAY_DETAIL = ROUTE_PATH_ADMIN + '/projects/:projectId/gateway/:gatewayId/:tab';
export const ROUTE_PATH_PROJECT = ROUTE_PATH_ADMIN + '/projects/:projectId/:tab';
export const ROUTE_PATH_PROJECT_SUB_TAB = ROUTE_PATH_ADMIN + '/projects/:projectId/:tab/:subTab';
export const ROUTE_PATH_PROJECT_SUB_TAB_ID = ROUTE_PATH_ADMIN + '/projects/:projectId/:tab/:subTab/:id';
export const ROUTE_PATH_PROJECT_SUB_TAB_ID_ACTION = ROUTE_PATH_ADMIN + '/projects/:projectId/:tab/:subTab/:id/:action';

export const getSharedGatewayPathByGatewayId = ({ projectId, gatewayId, tab }: SharedGatewayPathProps) => {
  return `${ROUTE_PATH_PROJECTS}/${projectId}/gateway/${gatewayId}/${tab}`;
};

export const getSharedGatewayParamsPath = ({ projectId, gatewayId, tab, page, size, sort, filters }: SharedGatewayPathProps) => {
  const routeTab = `${ROUTE_PATH_PROJECTS}/${projectId}/gateway/${gatewayId}/${tab}`;
  const routeWithPagination = `${routeTab}?page=${page}&size=${size}`;
  const routeWithPaginationAndSort = `${routeWithPagination}&sort=${sort}`;
  const routeWithPaginationFilterParams = filters ? `${routeWithPagination}&filter=${filters}` : routeWithPagination;
  const routeWithPaginationSortAndFilterParams = filters ? `${routeWithPaginationAndSort}&filter=${filters}` : routeWithPaginationAndSort;
  const routeWithPaginationSortParams = sort ? routeWithPaginationSortAndFilterParams : routeWithPaginationFilterParams;
  return page ? routeWithPaginationSortParams : routeTab;
};

export const getProjectServiceNewsIdPath = ({ projectId, id, action }: { projectId: string; id: string; action?: string }) => {
  const basicProjectServiceNewsPath = `${ROUTE_PATH_PROJECTS}/${projectId}/services/news/${id}`;
  return action ? basicProjectServiceNewsPath + `/${action}` : basicProjectServiceNewsPath;
};

export const getProjectServiceCommonAreasIdPath = ({ projectId, id, action }: { projectId: string; id: string; action?: string }) => {
  const basicProjectServiceCommonAreasPath = `${ROUTE_PATH_PROJECTS}/${projectId}/services/common-areas/${id}`;
  return action ? basicProjectServiceCommonAreasPath + `/${action}` : basicProjectServiceCommonAreasPath;
};

export const getProjectPathById = ({ projectId, tab = ProjectTab.ASSETS, subTab }: ProjectPathProps) => {
  const basicProjectPath = `${ROUTE_PATH_PROJECTS}/${projectId}/${tab}`;
  return subTab ? basicProjectPath + `/${subTab}` : basicProjectPath;
};

export const getProjectPath = ({
  projectId,
  tab = ProjectTab.ASSETS,
  subTab,
  page,
  size,
  sort,
  filters,
}: {
  projectId: string;
  tab?: string;
  subTab?: string;
  page?: string;
  size?: string;
  sort?: string;
  filters?: string;
}) => {
  const routeTab = `${ROUTE_PATH_PROJECTS}/${projectId}/${tab}`;
  const routeSubTab = `${routeTab}/${subTab}`;
  const routeWithPagination = `${routeTab}?page=${page}&size=${size}`;
  const routeWithPaginationAndSort = `${routeWithPagination}&sort=${sort}`;
  const routeWithPaginationFilterParams = filters ? `${routeWithPagination}&filter=${filters}` : routeWithPagination;
  const routeWithPaginationSortAndFilterParams = filters ? `${routeWithPaginationAndSort}&filter=${filters}` : routeWithPaginationAndSort;
  const routeWithPaginationSortParams = sort ? routeWithPaginationSortAndFilterParams : routeWithPaginationFilterParams;
  const route = page ? routeWithPaginationSortParams : routeTab;

  return subTab ? routeSubTab : route;
};

export const getProjectsWithPaginationPath = ({ page, size }: { page: string; size: string }) =>
  `${ROUTE_PATH_ADMIN}/projects?page=${page}&size=${size}`;
