import { FC } from 'react';
import { Form, FormProps, Input } from 'antd';
import { DeviceWithoutGatewayFormOptionsModel } from '../utils';
import './styles.scss';

interface Props extends FormProps {
  options: DeviceWithoutGatewayFormOptionsModel;
  loading: boolean;
}

const DeviceWithoutGatewayForm: FC<Props> = ({
  options: {
    form,
    formValues,
    deviceSectionNumber,
    sensorSectionNumber,
    formLiterals: {
      deviceSectionTitle,
      sensorSectionTitle,
      sensorSectionDescription,
      requiredFieldTextLegend,
    },
    onFinish,
    onFinishFailed,
  },
  loading,
}) => {
  return (
    <Form
      form={form}
      layout={'vertical'}
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      disabled={loading}
    >
      <div className={'DeviceWithoutGatewayForm__text-wrapper'}>
        <p className={'DeviceWithoutGatewayForm__title'}>{`${deviceSectionNumber}. ${deviceSectionTitle}`}</p>
      </div>
      <div className={'DeviceWithoutGatewayForm__wrapper'}>
        <div className={'DeviceWithoutGatewayForm__wrapper-row'}>
          {formValues.map(({ key, formGroupValue, label, name, required, placeholder, rules }) => formGroupValue === deviceSectionNumber ? (
            <Form.Item
              key={key}
              label={required ? `${label}*` : label}
              name={name}
              rules={rules}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
          ) : null
          )}
        </div>
      </div>
      <div className={'DeviceWithoutGatewayForm__text-wrapper'}>
        <p className={'DeviceWithoutGatewayForm__title'}>{`${sensorSectionNumber}. ${sensorSectionTitle}`}</p>
        <p>{sensorSectionDescription}</p>
      </div>
      <div className={'DeviceWithoutGatewayForm__sensors-list-wrapper'}>
        {formValues.map(({ key, formGroupValue, title, required, label, name, placeholder, rules }) => formGroupValue === sensorSectionNumber ? (
          <div className={'DeviceWithoutGatewayForm__sensor-wrapper'} key={key}>
            {!!title && <p>{title}</p>}
            <Form.Item
              label={required ? `${label}*` : label}
              name={name}
              rules={rules}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
          </div>
        ) : null
        )}
      </div>
      <div className={'DeviceWithoutGatewayForm__text-wrapper'}>
        <p className={'DeviceWithoutGatewayForm__text-wrapper-legend'}>{`(*) ${requiredFieldTextLegend}`}</p>
      </div>
    </Form>
  );
}

export default DeviceWithoutGatewayForm;
