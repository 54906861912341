import { FC } from 'react';
import DrawerAtom, { DrawerAtomProps } from 'components/atoms/DrawerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { Icon } from 'components/atoms/Icons';
import SelectAtom from 'components/atoms/SelectAtom';
import DeviceWithoutGatewayForm from 'components/pages/AssetPage/Tabs/TabDevices/resources/Form';
import { CreateDeviceOptionsModel } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useCreateDeviceWithoutGateway';
import './styles.scss';

export interface Props extends DrawerAtomProps {
  options: CreateDeviceOptionsModel;
}

const ICON_SIZE = 24;

export const CreateDeviceWithoutGatewayDrawer: FC<Props> = ({ open, onClose, title, options }) => {
  const {
    literals: {
      mainButtonText,
      secondaryButtonText,
      infoText,
      typeSectionTitle,
      deviceTypeLabel,
      deviceTypePlaceholder,
    },
    devicesTypes,
    formType,
    formOptions,
    onChangeDeviceType,
    onAddDevice,
    hasDevicesTypes,
    loading,
    creating,
    submitButtonDisabled,
  } = options;

  return (
    <DrawerAtom
      className={'CreateDeviceWithoutGatewayDrawer__wrapper'}
      open={open}
      onClose={onClose}
      size={'large'}
      title={<span className={'CreateDeviceWithoutGatewayDrawer__title'}>{title}</span>}
    >
      <main className={'CreateDeviceWithoutGatewayDrawer__content-wrapper'}>
        <div className={'CreateDeviceWithoutGatewayDrawer__info'}>
          <div className={'CreateDeviceWithoutGatewayDrawer__info-icon'}>
            <Icon.InfoCircleFilled style={{ fontSize: ICON_SIZE }} />
          </div>
          <div>
            <p className={'CreateDeviceWithoutGatewayDrawer__info-text'}>{infoText}</p>
          </div>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__text-wrapper'}>
          <p className={'CreateDeviceWithoutGatewayDrawer__text'}>{`1. ${typeSectionTitle}`}</p>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__type-wrapper'}>
          <div className={'CreateDeviceWithoutGatewayDrawer__type-selector'}>
            <label htmlFor={'deviceTypeSelector'}>{deviceTypeLabel}</label>
            <SelectAtom
              value={formType}
              placeholder={deviceTypePlaceholder}
              onChange={onChangeDeviceType}
              disabled={!hasDevicesTypes}
              loading={loading}
              allowClear
              id={'deviceTypeSelector'}
            >
              {hasDevicesTypes && devicesTypes.map(({ name, deviceType }) => (
                <SelectAtom.Option key={deviceType} value={deviceType}>{name}</SelectAtom.Option>
              ))}
            </SelectAtom>
          </div>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__form'}>
          {!!formType && <DeviceWithoutGatewayForm options={formOptions} loading={loading} />}
        </div>
      </main>
      <footer>
        <DefaultButton onClick={onClose} title={secondaryButtonText} />
        <PrimaryButton loading={creating} title={mainButtonText} disabled={submitButtonDisabled} onClick={onAddDevice} />
      </footer>
    </DrawerAtom>
  );
}