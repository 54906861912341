import { FC } from 'react';
import { PageHeader, Popconfirm, Tabs, Descriptions, Skeleton } from 'antd';
import { DeleteOutlined, HomeOutlined, SendOutlined } from '@ant-design/icons';
import { DATE_TIME_FORMAT } from 'constants/date';
import moment from 'moment';
import TabAssets from './TabAssets';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { useUser } from './resources/useUser';
import { SendNotificationModal } from 'components/molecules/SendNotificationModal';
import { SendNotificationForm } from 'components/molecules/SendNotificationForm';
import './styles.scss';

const UserPage: FC = () => {
  const {
    user,
    currentUser,
    activeTab,
    userPageLiterals,
    sendNotificationFormLiterals,
    sendNotificationModalLiterals,
    onGoBack,
    handleDeleteUser,
    onShowDeleteUserPop,
    onClickSendNotification,
    onCancelSendNotification,
    onSendNotification,
    onChangeTab,
    loading,
    isSendNotificationModalVisible,
    isSendingNotification,
    showingPop,
    deletingUser,
  } = useUser();

  const {
    userLabel,
    unknown,
    deleteUserConfirm,
    deleteUser,
    sendNotification,
    assets,
    email,
    userRoles,
    roles,
    lang,
    status,
    userStatus,
    userLang,
    createdAt,
    confirmedAt,
    notReported,
  } = userPageLiterals;

  return (
    <>
      <PageHeader
        ghost={false}
        title={`${userLabel}: ${user?.username.toLowerCase() !== 'unknown' ? user?.username : unknown}`}
        onBack={onGoBack}
        className='UserPage__header'
        extra={[
          <Popconfirm
            key='1'
            title={deleteUserConfirm}
            open={showingPop}
            onConfirm={() => handleDeleteUser(user!)}
            okButtonProps={{ loading: deletingUser }}
            onCancel={() => onShowDeleteUserPop(false)}
          >
            <DefaultButton
              icon={<DeleteOutlined />}
              onClick={() => onShowDeleteUserPop(true)}
              disabled={user?.id === currentUser}
              title={deleteUser}
            />
          </Popconfirm>,
          <DefaultButton
            icon={<SendOutlined />}
            onClick={onClickSendNotification}
            disabled={user?.id === currentUser}
            title={sendNotification}
          />
        ]}
        footer={
          <Tabs defaultActiveKey='TabAsset' onChange={onChangeTab}>
            <Tabs.TabPane
              tab={
                <span>
                  <HomeOutlined /> {assets}
                </span>
              }
              key='assets'
            />
          </Tabs>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Descriptions column={3} size='small'>
            <Descriptions.Item key='username' label={<b>{userLabel}</b>}>
              {user?.username && user.username.toLowerCase() !== 'unknown' ? user.username : unknown}
            </Descriptions.Item>
            <Descriptions.Item key='email' label={<b>{email}</b>}>
              {user?.email || notReported}
            </Descriptions.Item>
            <Descriptions.Item key='roles' label={<b>{roles}</b>}>
              {userRoles}
            </Descriptions.Item>
            <Descriptions.Item key='lang' label={<b>{lang}</b>}>
              {user?.lang ? userLang : notReported}
            </Descriptions.Item>
            <Descriptions.Item key='status' label={<b>{status}</b>}>
              {user?.status ? userStatus : notReported}
            </Descriptions.Item>
            <Descriptions.Item key='created_at' label={<b>{createdAt}</b>}>
              {user?.created_at ? moment.unix(user.created_at).format(DATE_TIME_FORMAT) : notReported}
            </Descriptions.Item>
            <Descriptions.Item key='confirmed_at' label={<b>{confirmedAt}</b>}>
              {user?.confirmed_at ? moment.unix(user.confirmed_at).format(DATE_TIME_FORMAT) : notReported}
            </Descriptions.Item>
          </Descriptions>
        )}
      </PageHeader>
      <div className='UserPage__tab'>{activeTab === 'assets' && <TabAssets key='assets' />}</div>
      <SendNotificationModal
        formId='sendNotification'
        literals={sendNotificationModalLiterals}
        onCancel={onCancelSendNotification}
        visible={isSendNotificationModalVisible}
        loading={isSendingNotification}
      >
        <SendNotificationForm
          formId='sendNotification'
          literals={sendNotificationFormLiterals}
          onSave={onSendNotification}
        />
      </SendNotificationModal>
    </>
  );
};

export default UserPage;
