import { FC } from 'react';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagWarning from 'components/atoms/tags/TagWarning';
import TagError from 'components/atoms/tags/TagError';
import TagDefault from 'components/atoms/tags/TagDefault';
import { QrAccessStatusEnum } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/utils';

export interface Props {
  status: QrAccessStatusEnum;
  text: string;
}

const statusTagManager = {
  [QrAccessStatusEnum.ACTIVE]: (text: string) => <TagSuccess text={text} />,
  [QrAccessStatusEnum.VALIDATED]: (text: string) => <TagDefault text={text} />,
  [QrAccessStatusEnum.REVOKED]: (text: string) => <TagError text={text} />,
  [QrAccessStatusEnum.EXPIRED]: (text: string) => <TagWarning text={text} />,
}

const QrAccessListStatusTagManager: FC<Props> = ({ status, text }) => {
  try {
    return statusTagManager[status](text);
  } catch (error) {
    return <TagDefault text={status} />
  }
}

export default QrAccessListStatusTagManager
