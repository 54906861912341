import { useState } from 'react';
import moment, { Moment } from 'moment';
import { AssetTimeSeriesData } from 'models/dataAcquisition.model';
import { getAssetTimeSeriesDataDemo } from 'services/api/dataAcquisition';
import { formatDate } from 'utils/date';

export interface SensorAssetConsumptions {
  sensorId: string;
  deviceId: string;
  gatewayId: string | null;
  dateRange: [Moment, Moment];
  assetId: string;
  sensor?: string;
}

export const useAssetConsumption = () => {
  const [allData, setAllData] = useState<AssetTimeSeriesData>();
  const [dataPresence, setDataPresence] = useState<AssetTimeSeriesData>();
  const [loading, setLoading] = useState<boolean>(false);

  const onGetSensorAssetConsumption = async ({ sensorId, sensor, dateRange, assetId, deviceId, gatewayId }: SensorAssetConsumptions) => {
    setLoading(true);
    try {
      const consumptionsData = await getAssetTimeSeriesDataDemo({
        assetId,
        sensorId,
        deviceId,
        gatewayId,
        sensor,
        from: formatDate(moment(dateRange[0])),
        until: formatDate(moment(dateRange[1])),
      });
      setAllData(consumptionsData);

      const virtualPresence = await getAssetTimeSeriesDataDemo({
        assetId,
        sensor: 'VIRTUAL_PRESENCE',
        deviceId,
        gatewayId,
        from: formatDate(moment(dateRange[0])),
        until: formatDate(moment(dateRange[1])),
      });
      setDataPresence(virtualPresence);
    } finally {
      setLoading(false);
    }
  };

  return { data: allData, dataPresence, onGetSensorAssetConsumption, setAllData, loading };
};
