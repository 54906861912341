import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { DeviceTypeWithoutGatewayDataModel, DeviceWithoutGatewayModel, DeviceWithoutGatewayUpdateModel } from 'core/domain/devices/models';
import { useMessage } from 'hooks/useMessage';
import { GetDeviceWithoutGateway } from 'core/domain/devices/repositories/getDeviceWithoutGateway';
import { UpdateDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/updateDeviceWithoutGateway';
import { GetDevicesTypesListWithoutGateway } from 'core/domain/devices/repositories/getDevicesTypesListWithoutGateway';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { useDeviceWithoutGatewayForm } from './Form/resources/useDeviceWithoutGatewayForm';
import {
  DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_GENERAL_GROUP_VALUE,
  DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_SENSOR_GROUP_VALUE,
  DeviceWithoutGatewayFormDataModel,
  DeviceWithoutGatewayFormOptionsModel,
  getErrorMessageLiterals,
} from './Form/utils';

export interface DeviceWithoutGatewayDrawerLiteralsType {
  deviceActionButtonText: string;
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
}

export interface UpdateDeviceOptionsModel {
  literals: DeviceWithoutGatewayDrawerLiteralsType;
  formType: string;
  formOptions: DeviceWithoutGatewayFormOptionsModel;
  onOpenUpdateDeviceDrawer: (device: any) => void;
  onCloseUpdateDeviceDrawer: () => void;
  onUpdateDevice: () => void;
  updateDeviceDrawerVisible: boolean;
  loading: boolean;
  updating: boolean;
}

enum UpdateDeviceErrorCodeType {
  CONFLICTS = 400,
  NOT_FOUND = 404,
  PAYLOAD_ERROR = 422,
}

export const useUpdateDeviceWithoutGateway = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<DeviceWithoutGatewayFormDataModel[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [successUpdatingDevice, setSuccessUpdatingDevice] = useState<boolean>(false);
  const [device, setDevice] = useState<DeviceWithoutGatewayModel>();
  const { form, formLiterals, createFormStructure, submitForm, resetFormFields, getFormValues, formValuesToPayload } =
    useDeviceWithoutGatewayForm();

  const literals: DeviceWithoutGatewayDrawerLiteralsType = {
    deviceActionButtonText: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
    title: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    mainButtonText: t('update'),
    secondaryButtonText: t('cancel'),
  };

  const getDevicesTypes = async (): Promise<DeviceTypeWithoutGatewayDataModel[]> => {
    try {
      setLoading(true);
      const { data } = await GetDevicesTypesListWithoutGateway({
        host,
        token,
        pageNumber: Number(TABLE_PAGINATION_PAGE_DEFAULT),
        pageSize: Number(TABLE_PAGINATION_SIZE_DEFAULT),
      });
      return data;
    } catch (error) {
      setMessageError({ description: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_ERROR_MESSAGE') });
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getDeviceData = async (deviceId: string) => {
    setLoading(true);
    try {
      const { data } = await GetDeviceWithoutGateway({ host, token, deviceId });
      const devicesTypes = await getDevicesTypes();
      const parsedFormValues = createFormStructure({ value: data.deviceType, devicesTypes, data });
      setDevice(data);
      setFormValues(parsedFormValues);
    } catch (error) {
      setMessageError({ description: t('_GET_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = () => {
    updateDevice();
  };

  const onFinishFailed = () => {
    setUpdating(false);
  };

  const onOpenUpdateDeviceDrawer = (selectedDevice: any) => {
    const { deviceType, deviceId } = selectedDevice;
    setDrawerVisible(true);
    setFormType(deviceType);
    getDeviceData(deviceId);
  };

  const resetValues = () => {
    setFormType('');
    setUpdating(false);
    setDevice(undefined);
    resetFormFields();
  };

  const onCloseUpdateDeviceDrawer = () => {
    resetValues();
    setDrawerVisible(false);
  };

  const onUpdateDevice = () => {
    submitForm();
    setUpdating(true);
  };

  const getConflictErrorLiteralFromMessage = (message: string): string => {
    const errorMessageLiterals = getErrorMessageLiterals();
    const currentErrorMessage = errorMessageLiterals.find((errorLiteral) => message.includes(errorLiteral.message));

    return currentErrorMessage ? currentErrorMessage.literal : t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');
  };

  const controlledErrorDescriptionManager = {
    [UpdateDeviceErrorCodeType.CONFLICTS]: getConflictErrorLiteralFromMessage,
    [UpdateDeviceErrorCodeType.NOT_FOUND]: () => t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_404_MESSAGE'),
    [UpdateDeviceErrorCodeType.PAYLOAD_ERROR]: () => t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_422_MESSAGE'),
  };

  const getErrorLiteral = (error: any) => {
    const controlledErrors = Object.values(UpdateDeviceErrorCodeType);
    const isControlledError = controlledErrors.includes(error.code);
    const description = isControlledError
      ? controlledErrorDescriptionManager[error.code as UpdateDeviceErrorCodeType](error.message.detail)
      : t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');

    setMessageError({ description });
  };

  const updateDevice = async () => {
    try {
      const values = getFormValues();
      const completedDeviceData = formValuesToPayload({ assetId, formType, formValues, values, data: device });
      await UpdateDeviceDetailWithoutGateway({
        host,
        token,
        device: completedDeviceData as DeviceWithoutGatewayUpdateModel,
        deviceId: device?.deviceUuid || '',
      });
      setMessageSuccess({ description: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      setSuccessUpdatingDevice(true);
      onCloseUpdateDeviceDrawer();
    } catch (error) {
      getErrorLiteral(error);
    } finally {
      setUpdating(false);
      setSuccessUpdatingDevice(false);
    }
  };

  const updateDeviceOptions: UpdateDeviceOptionsModel = {
    literals,
    formType,
    formOptions: {
      form,
      formValues,
      formLiterals,
      deviceSectionNumber: DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_GENERAL_GROUP_VALUE,
      sensorSectionNumber: DEVICE_WITHOUT_GATEWAY_UPDATE_FORM_SENSOR_GROUP_VALUE,
      onFinish,
      onFinishFailed,
    },
    onOpenUpdateDeviceDrawer,
    onCloseUpdateDeviceDrawer,
    onUpdateDevice,
    updateDeviceDrawerVisible: drawerVisible,
    loading,
    updating,
  };

  return { updateDeviceOptions, successUpdatingDevice };
};
