import { SendNotificationModel, PayloadSendNotificationModel } from '../model';

export const notificationToPayloadSendNotification = ({
  title,
  content,
  userList,
}: SendNotificationModel): PayloadSendNotificationModel => {
  return {
    title,
    content,
    user_list: userList,
  };
};
