import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GetGatewayDetail } from 'core/domain/gateways/repositories/getGatewayDetail';
import { GatewaySensorEntityModel } from 'core/domain/gateways/model/gatewaysSensors/gatewaySensorEntityModel';
import { ProjectSharedGatewayModel } from 'core/domain/project/model/projectModel';
import {
  getSharedGatewayPathByGatewayId,
  getSharedGatewayParamsPath,
  SharedGatewayTabType,
} from 'components/pages/App/routes/projects/config';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { DeviceSorterValueType } from 'core/domain/devices/models';
import { SorterOrderType } from 'constants/sort';
import { useSharedGateways } from './useSharedGateways';

export const useSharedGatewaysDetail = () => {
  const { projectId, gatewayId, tab } = useParams<{ projectId: string; gatewayId: string; tab: string }>();
  const history = useHistory();
  const [sharedGatewayDetailData, setSharedGatewayDetailData] = useState({} as ProjectSharedGatewayModel);
  const [sharedSensors, setSharedSensors] = useState<GatewaySensorEntityModel[]>([]);
  const { data: sharedGateways, onGetData: onGetSharedGateways, loading } = useSharedGateways();

  const onGetData = () => {
    onGetSharedGateways(projectId);
  };

  const getGatewayById = (gatewayId: string) => {
    GetGatewayDetail(gatewayId).then(({ sensors }) => {
      sensors && setSharedSensors(sensors);
    });
  };

  const onClickTab = (activeTab: string) => {
    const route =
      activeTab === SharedGatewayTabType.DEVICES
        ? getSharedGatewayParamsPath({
            projectId,
            gatewayId,
            tab: activeTab,
            page: TABLE_PAGINATION_PAGE_DEFAULT,
            size: TABLE_PAGINATION_SIZE_DEFAULT,
            sort: `${DeviceSorterValueType.DEVICE_ID}.${SorterOrderType.ASC}`,
          })
        : getSharedGatewayPathByGatewayId({ projectId, gatewayId, tab: activeTab });
    history.push(route);
  };

  useEffect(() => {
    gatewayId && getGatewayById(gatewayId);
  }, [gatewayId]);

  useEffect(() => {
    if (!sharedGateways.length) {
      onGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedGateways]);

  useEffect(() => {
    const dataFinded = sharedGateways.find((item) => gatewayId === item.id);
    if (dataFinded) {
      setSharedGatewayDetailData(dataFinded);
    }
  }, [sharedGateways, gatewayId]);

  return {
    data: sharedGatewayDetailData,
    sharedSensors,
    tab,
    gatewayId,
    onClickTab,
    loading,
  };
};
