import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel, DeviceSensorModel, DeviceSorterValueType } from 'core/domain/devices/models';
import {
  PeripheralDevicesExpandedColumnsWithActions,
  PeripheralDevicesExpandedColumnsWithActionsTranslations,
} from 'components/organisms/PeripheralDevices/ExpandedColumnsWithActionsPeripheralDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import { DATE_TIME_FORMAT_REVERSE_SLASH } from 'constants/date';
import { SorterOrderType } from 'constants/sort';
import { CustomDeviceModel } from 'utils/globalModels';
import TagError from 'components/atoms/tags/TagError';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagDefault from 'components/atoms/tags/TagDefault';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getSharedGatewayParamsPath } from 'components/pages/App/routes/projects/config';
import { getDevicesListSorterOptions } from 'utils/devices';
import { SelectorSorterModel } from 'components/molecules/TableSorter';
import { checkFilterOptionError, parseFiltersConditions, parseFiltersToBeSent } from 'utils/filter';
import { connectivityLiteralsManager } from 'utils/devices';
import { TableFilterModel } from 'components/organisms/TableFilter/models';
import { DeviceConnectivityType } from 'constants/devices';
import { useShareSensor } from '../../../../../../hooks/useShareSensor';
import { ColumnsPeripheralDevices } from '../ColumnsPeripheralDevices';
import { getFilterOptions, getInitialFilters } from './utils';

const getInitialSorterValue = (): SelectorSorterModel => {
  return {
    value: `${DeviceSorterValueType.DEVICE_ID}.${SorterOrderType.ASC}`,
    label: i18next.t('_DEVICES_LIST_SORTER_LABEL_DEVICE_ID_ASC'),
  };
};

export const useTabDevices = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, gatewayId, tab } = useParams<{ projectId: string; gatewayId: string; tab: string }>();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const sort = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SORT);
  const filters = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.FILTERS);
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<CustomDeviceModel[]>([]);
  const [currentDevice, setCurrentDevice] = useState<CustomDeviceModel | null>(null);
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentSize, setCurrentSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [currentSortValue, setCurrentSortValue] = useState<SelectorSorterModel>(getInitialSorterValue());
  const [currentFilterValue, setCurrentFilterValue] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [initialFilters, setInitialFilters] = useState<TableFilterModel[]>(
    getInitialFilters({ currentFilters: '', connectivityLiteralsManager })
  );
  const searchInput = useRef<any>(null);
  const { shareSensorOptions } = useShareSensor();

  const sorterValueOptions = getDevicesListSorterOptions().filter(
    (option) => option.value.split('.')[0] !== DeviceSorterValueType.GATEWAY_ID
  );

  const peripheralsTitle: string = t('_PROJECT_PERIPHERAL_SHARED_DEVICES_TITLE');

  const sorterLabel: string = t('_SORTER_LABEL');

  const peripheralExpandedColumnsTranslations: PeripheralDevicesExpandedColumnsWithActionsTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    actionsTitle: t('actions'),
    shareSensorText: t('_SHARE_SENSOR_TEXT'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const connectivityTagsManager = {
    [DeviceConnectivityType.OFFLINE]: TagError({ text: t('offline') }),
    [DeviceConnectivityType.ONLINE]: TagSuccess({ text: t('online') }),
    [DeviceConnectivityType.UNKNOWN]: TagDefault({ text: t('unknown') }),
  };

  const transformDevices = (devices: DeviceModel[]): CustomDeviceModel[] => {
    return devices.map((device: DeviceModel) => {
      const connectivity = !!device.connectivity ? device.connectivity : DeviceConnectivityType.UNKNOWN;
      const updatedAtLiteral = !!device.updatedAt
        ? moment.unix(device.updatedAt).format(DATE_TIME_FORMAT_REVERSE_SLASH)
        : t('not_reported');

      return { ...device, connectivity, updatedAtLiteral };
    });
  };

  const columns = ColumnsPeripheralDevices({
    gatewayId,
    columnSearchTranslations,
    connectivityTagsManager,
    searchInput,
    onSearch,
    onReset,
  });

  const onOpenShareSensorModal = (sensorUuid: string) => {
    !!currentDevice &&
      !!currentDevice.gatewayUuid &&
      shareSensorOptions.onOpenShareSensorModal({ sensorUuid, gatewayId: currentDevice.gatewayUuid });
  };

  const expandedColumns: TableColumnsType<DeviceSensorModel> = PeripheralDevicesExpandedColumnsWithActions({
    translations: peripheralExpandedColumnsTranslations,
    onOpenShareSensorModal,
  });

  const onExpandDevice = (expanded: boolean, device: CustomDeviceModel) => {
    setCurrentDevice(expanded ? device : null);
  };

  const parseSortValues = (currentSort: string) => {
    const [field, order] = currentSort.split('.') as [DeviceSorterValueType, SorterOrderType];
    return [{ field, order }];
  };

  const getDevices = async ({
    currentPageNumber,
    currentPageSize,
    currentSort,
    currentFilter,
  }: {
    currentPageNumber: number;
    currentPageSize: number;
    currentSort: string;
    currentFilter: string;
  }) => {
    setIsLoading(true);
    try {
      const sort = parseSortValues(currentSort);
      const filters = parseFiltersToBeSent(currentFilter);
      const response = await GetDevicesLists({ host, token, gatewayId, page: currentPageNumber, size: currentPageSize, sort, filters });
      const transformedDevices = transformDevices(response.data);
      setDevices(transformedDevices);
      setTotalPages(response.meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (pageNumber: number, pageSize?: number) => {
    history.push(
      getSharedGatewayParamsPath({
        projectId,
        gatewayId,
        tab,
        page: String(pageNumber),
        size: String(pageSize!),
        sort: currentSortValue.value,
        filters: currentFilterValue,
      })
    );
  };

  const onChangeSorter = (value: string) => {
    history.push(
      getSharedGatewayParamsPath({
        projectId,
        gatewayId,
        tab,
        page: String(currentPage),
        size: String(currentSize),
        sort: value,
        filters: currentFilterValue,
      })
    );
  };

  const sorterOptions = {
    label: sorterLabel,
    value: currentSortValue,
    valueOptions: sorterValueOptions,
    onChangeSorter,
    disabled: isLoading,
    loading: isLoading,
  };

  const getSortValues = (sortValue: string | null): SelectorSorterModel | null => {
    return sorterValueOptions.find((sort) => sort.value === sortValue) || null;
  };

  const getFilterValues = (filterValue: string | null): string => {
    if (!filterValue) return '';

    const filterValueOptions = filterValue.split(',');
    const defaultFilterOptions = getFilterOptions();
    const filteredFilterValueOptions = filterValueOptions.filter((item, index) => filterValueOptions.indexOf(item) === index);

    const filters = filteredFilterValueOptions
      .filter((option) => {
        const isFilterOptionError = checkFilterOptionError(option, initialFilters);

        if (isFilterOptionError) return undefined;

        const [{ field }] = parseFiltersConditions([option]);

        const selectedDefaultFilterValue = defaultFilterOptions.find(({ key, operator, value, exactValue }) => {
          const parsedDefaultOption = `${key}${operator}${exactValue ? value : ''}`;
          const parsedOption = field === key && exactValue ? option : `${field}${operator}`;

          return parsedDefaultOption === parsedOption;
        });

        return !!selectedDefaultFilterValue ? option : undefined;
      })
      .join(',');

    return filters;
  };

  const navigateToDefaultValues = (newPage: string, newSize: string, newSort: SelectorSorterModel, newFilters: string) => {
    const defaultRoute = getSharedGatewayParamsPath({
      projectId,
      gatewayId,
      tab,
      page: newPage,
      size: newSize,
      sort: newSort.value,
      filters: newFilters,
    });
    history.replace(defaultRoute);
  };

  const updateParamsValues = (newPage: string, newSize: string, newSort: SelectorSorterModel, newFilters: string) => {
    setCurrentPage(Number(newPage));
    setCurrentSize(Number(newSize));
    setCurrentSortValue(newSort);
    setCurrentFilterValue(newFilters);
  };

  const onApplyFilters = (url: string) => {
    history.push(
      getSharedGatewayParamsPath({
        projectId,
        gatewayId,
        tab,
        page: String(currentPage),
        size: String(currentSize),
        sort: currentSortValue.value,
        filters: url,
      })
    );
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    const newSort = getSortValues(sort);
    const parsedSort = newSort || getInitialSorterValue();
    const newFilters = getFilterValues(filters);

    (!page || !size || !newSort) && navigateToDefaultValues(newPage, newSize, parsedSort, newFilters);

    if (!!page && !!size && !!newSort) {
      getDevices({
        currentPageNumber: Number(page),
        currentPageSize: Number(size),
        currentSort: parsedSort.value,
        currentFilter: newFilters,
      });
      updateParamsValues(newPage, newSize, parsedSort, newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, sort, filters]);

  useEffect(() => {
    if (!!currentFilterValue) {
      const filters = getInitialFilters({ currentFilters: currentFilterValue, connectivityLiteralsManager: connectivityLiteralsManager });
      setInitialFilters(filters);
    }
  }, [currentFilterValue]);

  return {
    devices,
    currentPage,
    currentSize,
    totalPages,
    peripheralsTitle,
    gatewayId,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    searchedColumn,
    shareSensorOptions,
    columns,
    expandedColumns,
    sorterOptions,
    initialFilters,
    onChangePage,
    onExpandDevice,
    onApplyFilters,
    loading: isLoading,
  };
};
