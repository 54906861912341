import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectModel } from 'core/domain/project/model/projectModel';
import { getProjects } from 'services/api/projects';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getProjectsWithPaginationPath, getProjectPath } from 'components/pages/App/routes/projects/config';
import { hasPermission } from 'services/permissions';
import { ProjectTab } from 'models/project.model';

export const useProjects = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isNotificationModalVisible, setIsNotificationModalVisible] = useState<boolean>(false);

  const hasNotificationPermission = hasPermission('send_community_notifications');

  const translations = {
    projects: t('projects'),
    notify: t('notify_a_community'),
  };

  const getProjectsWithPagination = async ({ page, size, search }: { page: number; size: number; search?: string }): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await getProjects({ search, page: { current: page, size } });
      response && setProjects(response.data);
      response && setTotalPages(response.meta.total_pages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getProjectsWithPaginationPath({ page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onSelectProject = (project: ProjectModel) => {
    history.push(
      getProjectPath({
        projectId: project.id,
        tab: ProjectTab.ASSETS,
        page: TABLE_PAGINATION_PAGE_DEFAULT,
        size: TABLE_PAGINATION_SIZE_DEFAULT,
      })
    );
  };

  const onOpenNotification = () => {
    setIsNotificationModalVisible(true);
  };

  const onCloseNotification = () => {
    setIsNotificationModalVisible(false);
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getProjectsWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
  }, [page, size, searchQuery]);

  return {
    projects,
    totalPages,
    currentPage,
    pageSize,
    translations,
    onChangePage,
    onSearch,
    onSelectProject,
    onOpenNotification,
    onCloseNotification,
    loadingProjects: isLoading,
    hasNotificationPermission,
    notificationModalVisible: isNotificationModalVisible,
  };
};
