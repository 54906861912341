import {
  DEFAULT_FILTER_INPUT_NUMBER_MAX_VALUE,
  DEFAULT_FILTER_INPUT_NUMBER_MIN_VALUE,
  FilterLogicsType,
  FilterOperatorsType,
} from 'constants/filters';
import {
  numericFilterTypes,
  TableFilterModel,
  TableFilterOptionModel,
  TableFilterSubtypeType,
  TableFilterType,
} from 'components/organisms/TableFilter/models';
import { FilterConditionModel, FilterModel } from './globalModels';

export const parseFiltersConditions = (filters: string[]): FilterConditionModel[] => {
  const operators = Object.values(FilterOperatorsType);

  return filters
    .map((filterString) => {
      const regexField = '([^<>=!]+)';
      const regexOperator = `(${operators.map((op) => op.replace(/([=!<>*])/g, '\\$1')).join('|')})`;
      const regexValue = '(.*)';
      const regex = new RegExp(`^${regexField}${regexOperator}${regexValue}$`);
      const match = filterString.match(regex);

      if (!match) return undefined;

      const [, field, operator, value] = match;

      return {
        field: field.trim(),
        operator: operator.trim() as FilterOperatorsType,
        value: value.trim(),
      };
    })
    .filter((condition) => !!condition);
};

export const parseFiltersToBeSent = (filterValue: string, defaultFilters?: FilterConditionModel[]): FilterModel | undefined => {
  const filters = filterValue.split(',');
  const parsedConditions: FilterConditionModel[] = parseFiltersConditions(filters);
  const conditions = defaultFilters ? [...parsedConditions, ...defaultFilters] : parsedConditions;

  return { logic: FilterLogicsType.AND, conditions };
};

export const filterSubtypeManager = {
  [TableFilterSubtypeType.EQUAL]: FilterOperatorsType.EQUAL,
  [TableFilterSubtypeType.MAX]: FilterOperatorsType.LESS_THAN,
  [TableFilterSubtypeType.MAX_OR_EQUAL]: FilterOperatorsType.LESS_OR_EQUAL_THAN,
  [TableFilterSubtypeType.MIN]: FilterOperatorsType.GREATER_THAN,
  [TableFilterSubtypeType.MIN_OR_EQUAL]: FilterOperatorsType.GREATER_OR_EQUAL_THAN,
};

export const getSelectedFilterObjectOptions = ({
  options,
  key,
  subKey,
  value,
  exactValue,
}: {
  options: FilterConditionModel[];
  key: string;
  subKey: string;
  value: string;
  exactValue: boolean;
}): FilterConditionModel | undefined => {
  const selectedOption = options.find(({ field, operator, value: optionValue }) => {
    const isSameValue = value === optionValue;
    const isSameOperator = subKey === operator;
    const isDataChecked = exactValue ? isSameValue : isSameOperator;
    return field === key && isDataChecked;
  });

  return selectedOption;
};

const parseFilterValuesToBeChecked = ({ subKey, currentFilters }: { subKey: string; currentFilters: string }) => {
  const currentFilterOptions = currentFilters.split(',');
  const currentFilterObjectsOptions = parseFiltersConditions(currentFilterOptions);
  const parsedSubKey = filterSubtypeManager[subKey as TableFilterSubtypeType];

  return { options: currentFilterObjectsOptions, parsedSubKey };
};

export const checkIfOptionIsAlreadySelected = ({
  key,
  subKey,
  value,
  currentFilters,
  exactValue,
}: {
  key: string;
  subKey: string;
  value: string;
  currentFilters: string;
  exactValue: boolean;
}) => {
  const { options, parsedSubKey } = parseFilterValuesToBeChecked({ subKey, currentFilters });
  const selectedOption = getSelectedFilterObjectOptions({ options, key, subKey: parsedSubKey, value, exactValue });

  return !!selectedOption;
};

export const getInitialFilter = (field: string, initialFilters: TableFilterModel[]): TableFilterModel | undefined => {
  return initialFilters.find((initialFilter) => initialFilter.key === field);
};

export const getSelectedOption = (operator: FilterOperatorsType, options: TableFilterOptionModel[]): TableFilterOptionModel | undefined => {
  return options.find((option) => option.operator === operator);
};

export const getSelectedOptionValueFromStringFilters = ({
  key,
  subKey,
  currentFilters,
}: {
  key: string;
  subKey: string;
  currentFilters: string;
}) => {
  const { options, parsedSubKey } = parseFilterValuesToBeChecked({ subKey, currentFilters });
  const selectedOption = getSelectedFilterObjectOptions({ options, key, subKey: parsedSubKey, value: '', exactValue: false });

  return !!selectedOption ? String(selectedOption.value) : '';
};

export const checkValueErrorFormat = (option: string, filters: TableFilterModel[]): boolean => {
  const [{ field, value }] = parseFiltersConditions([option]);
  const initialFilter = getInitialFilter(field, filters);
  const isNumericType = !!initialFilter && numericFilterTypes.includes(initialFilter.type);

  return isNumericType && isNaN(Number(value));
};

export const checkValueOutsideRange = (option: string, filters: TableFilterModel[]) => {
  const [{ field, operator, value }] = parseFiltersConditions([option]);
  const initialFilter = getInitialFilter(field, filters);
  const selectedOption = !!initialFilter ? getSelectedOption(operator, initialFilter.options) : undefined;

  if (!!selectedOption) {
    const isDateRange = selectedOption.type === TableFilterType.DATE_RANGE;
    const defaultMaxValue = isDateRange ? Date.now() : DEFAULT_FILTER_INPUT_NUMBER_MAX_VALUE;
    const min = selectedOption.min || DEFAULT_FILTER_INPUT_NUMBER_MIN_VALUE;
    const max = selectedOption.max || defaultMaxValue;
    const isUnderMinValue = Number(value) < min;
    const isOverMaxValue = Number(value) > max;

    return isUnderMinValue || isOverMaxValue;
  }

  return true;
};

export const checkFilterOptionError = (option: string, filters: TableFilterModel[]): boolean => {
  const isFormatErrorValue = checkValueErrorFormat(option, filters);
  const isOutsideRangeValues = checkValueOutsideRange(option, filters);

  return isFormatErrorValue || isOutsideRangeValues;
};
